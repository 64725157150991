/* eslint-disable react-native/no-inline-styles */
import React, { FC, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  FlatList,
  ImageStyle,
  TextInput,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { AppStackScreenProps } from "app/navigators";
import { Icon, Screen, Text } from "app/components";
import { colors, spacing, fontSize, typography } from "app/theme";
import { useStores } from "../models";
import { api } from "../services/api";
import Switch from "app/components/Switch";

export interface BlockListProps {
  navigation: any;
}

interface BlockListScreenProps
  extends NativeStackScreenProps<AppStackScreenProps<"BlockListScreen">> {}

export const BlockListScreen: FC<BlockListScreenProps> = observer(function BlockListScreen(_props) {
  const { navigation } = _props;
  const { authenticationStore } = useStores();
  const { authToken, id, authRefreshToken, logout} = authenticationStore;
  const [userData, setUserData] = useState([]);
  const [textInputValue, setTextInputValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const textinputref = useRef(null);
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);
  const [toggleStates, setToggleStates] = useState({});

  const getColorForStatus = (status) => {
    return status === 1 ? 'red' : '#EBECED';
  };

  const handleToggle = async (userId, currentStatus) => {
    try {
      const newStatus = currentStatus === 0 ? 'block' : 'unblock';
      const response = await api.postBlockUnblockUser(authToken, userId, newStatus);
      if (response.kind === 'ok') {
        const updatedUserData = userData.map(user => {
          if (user.id === userId) {
            return { ...user, status: currentStatus === 0 ? 1 : 0 };
          }
          return user;
        });
  
        const sortedUsers = updatedUserData.sort((a, b) => a.name.localeCompare(b.name));
  
        const updatedToggleStates = sortedUsers.reduce((acc, user) => {
          acc[user.id] = user.status === 1;
          return acc;
        }, {});
  
        setToggleStates(updatedToggleStates);
        setUserData(sortedUsers);
        setFilteredData(sortedUsers);
      } else {
        console.error('Failed to block/unblock user');
      }
    } catch (error) {
      console.error('Error blocking/unblocking user:', error);
    }
  };
  
  const renderItem = ({ item }) => (
    <View style={$listContainer}>
      <View style={$listTextContainer}>
        <Text style={$usernameStyle}>{item.name}</Text>
        {/* <Text style={$firmNameStyle}>{item.firmName}</Text> */}
      </View>
      <View style={$toggleButton}>
        <Switch
          isOn={toggleStates[item.id] || false}
          handleToggle={() => handleToggle(item.id, item.status)}
          colorOne={getColorForStatus(item.status)}
          colorTwo="#EBECED"
        />
      </View>
    </View>
  );

  const handleIconPress = () => {
    if (textinputref.current) {
      textinputref.current.focus();
      handleInputChange(textInputValue);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await api.getBuyerData(authToken, id);
        if (response.kind === "ok") {
          const users = response.data.users;
          // Sort the users by name in alphabetical order
          const sortedUsers = users.sort((a, b) => a.name.localeCompare(b.name));
          setUserData(sortedUsers);
          setFilteredData(sortedUsers);
  
          const initialToggleStates = sortedUsers.reduce((acc, user) => {
            acc[user.id] = user.status === 1;
            return acc;
          }, {});
          setToggleStates(initialToggleStates);
        } else if (response.kind === "Unauthorized") {
          navigation.navigate("Account");
          await api.postLogout(authRefreshToken);
          logout();
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
  
    fetchUserData();
  }, [authToken, id]);
  


  const handleInputChange = (name) => {
    setTextInputValue(name);
    const filtered = userData.filter((item) =>
      item.name.toLowerCase().includes(name.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const onFocus = () => {
    setIsPlaceholderVisible(false);
  };

  const onBlur = () => {
    if (!textInputValue.trim()) {
      setIsPlaceholderVisible(true);
    }
  };

  return (
    <Screen contentContainerStyle={$container} preset="fixed" safeAreaEdges={["top"]}>
      <View style={{ zIndex: 1 }}>
        <View style={{ display: "flex", flexDirection: "row", paddingHorizontal: spacing.lg }}>
          <TouchableOpacity>
            <Icon
              onPress={() => navigation.goBack()}
              icon="back"
              color={colors.palette.neutral900}
              size={fontSize.h2}
            />
          </TouchableOpacity>
          <Text style={$TitleStyle} tx="BlockListScreen.title" />
        </View>
        <View style={$searchContainer}>
          <Icon
            style={$iconContainer}
            icon={"search"}
            color={colors.palette.gray200}
            size={fontSize.p2}
            onPress={handleIconPress}
          />
          <TextInput
            style={$searchText}
            placeholder={isPlaceholderVisible ? "Search" : ""}
            value={textInputValue}
            onChangeText={handleInputChange}
            underlineColorAndroid="transparent"
            selectionColor="#595959"
            ref={textinputref}
            onFocus={onFocus}
            onBlur={onBlur}
            maxLength={25}
          />
        </View>
      </View>
      <View style={$spaceContainer} />
      <View style={$bottomContainer}>
        <View style={{ flex: 1 }}>
          <FlatList
            data={filteredData}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
            ItemSeparatorComponent={() => <View style={$line} />}
            style={{ flex: 1 }}
          />
        </View>
      </View>
    </Screen>
  );
});

const $line: ViewStyle = {
  height: 1,
  width: "100%",
  backgroundColor: colors.palette.gray100,
  position: "relative",
  bottom: spacing.xxxs + spacing.xxs,
}
const $container: ViewStyle = {
  paddingTop: spacing.xxl,
  backgroundColor: colors.palette.gray100,
  flex: 1,
  paddingBottom: 98,
}

const $TitleStyle: TextStyle = {
  fontSize: fontSize.h2,
  marginLeft: spacing.md,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.bold,
}

const $spaceContainer: ViewStyle = {
  width: "100%",
  height: spacing.llg,
  backgroundColor: colors.palette.neutral100,
  zIndex: -1,
}
const $bottomContainer: ViewStyle = {
  backgroundColor: colors.palette.neutral100,
  height: "100%",
}
const $listContainer: ViewStyle = {
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingLeft: spacing.md,
  paddingRight: spacing.md,
  paddingVertical: spacing.sm, 
  width: "100%",
  marginLeft: spacing.md,
};

const $listTextContainer: ViewStyle = {
  display: "flex",
  flexDirection: "column",
  marginRight: spacing.lg, 
};

const $usernameStyle: TextStyle = {
  fontSize: fontSize.p3,
  fontFamily: typography.fonts.inter.medium,
  paddingBottom: spacing.xxs, 
};

// const $firmNameStyle: TextStyle = {
//   fontSize: fontSize.p3,
//   fontFamily: typography.fonts.inter.normal,
//   color: colors.palette.gray200,
// };

const $toggleButton: ViewStyle = {
  paddingRight: spacing.md, 
};

const $searchContainer: TextStyle = {
  flexDirection: "row",
  alignItems: "center",
  position: "relative",
  zIndex: -1,
  backgroundColor: colors.palette.neutral100,
  height: 46,
  elevation: 10,
  shadowColor: colors.palette.neutral900,
  shadowOffset: { width: 0, height: 0 },
  shadowOpacity: 0.1,
  shadowRadius: 4,
  fontSize: fontSize.p2,
  marginLeft: spacing.md,
  marginRight: spacing.md,
  top: spacing.mmd,
}

const $iconContainer: ImageStyle = {
  margin: spacing.md,
}
const $searchText: TextStyle | ViewStyle = {
  color: colors.palette.gray200,
  height: 44,
  width: "100%",
}
