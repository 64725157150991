/**
  Use these spacings for margins/paddings and other whitespace throughout your app.
 */
export const spacing = {
  mxs: -8,
  mxxs: -4,
  mxxxs: -2,
  xxxxs: 0,
  xss:1,
  xxxs: 2,
  xxss:3,
  xxs: 4,
  s:6,
  xs: 8,
  ssm: 10,
  sm: 12,
  smm: 14,
  md: 16,
  mdd: 18,
  mmd: 20,
  lg: 24,
  llg: 28,
  xl: 32,
  xll: 38,
  xlll: 42,
  xxl: 48,
  xxxl: 64,
  xxxxl: 88,
} as const

export type Spacing = keyof typeof spacing
