import React from "react"
import { FlatList, StyleProp, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, fontSize, spacing, typography } from "app/theme"
import { Text } from "app/components/Text"
import { Icon } from "../Icon"
import { setTime, setDate } from "../../services/constants/constant.js"

export interface AlertItem {
  id: string
  type: string
  created_at: string
  message: string
}

export interface AlertsProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  alertData: AlertItem[] // Add alertData prop
}

/**
 * Describe your component here
 */
export const Alerts = observer(function Alerts(props: AlertsProps) {
  const { style, alertData } = props

  const renderItem = ({ item }: { item: AlertItem }) => (
    <View key={item.id}>
      <View style={$rowContainerData}>
        <View style={$iconContainer}>
          <Icon icon={item.type} size={47} />
        </View>
        <View style={$dataContainer}>
          <Text style={$dayText}>
            {setTime(item.created_at)} {setDate(item.created_at)}
          </Text>
          <Text style={$dataText}>{item.message}</Text>
        </View>
      </View>
      <View style={$border}></View>
    </View>
  )

  return (
    <FlatList
      style={[$container, style]}
      data={alertData}
      renderItem={renderItem}
      keyExtractor={(item) => item.id}
    />
  )
})

const $container: ViewStyle = {}
const $rowContainerData: ViewStyle = {
  flexDirection: "row",
  marginVertical: spacing.ssm,
  marginHorizontal: spacing.md,
}
const $dataContainer: ViewStyle = {
  marginLeft: spacing.md,
  width: "85%",
}
const $dayText: TextStyle = {
  fontSize: fontSize.p5,
  color: colors.palette.gray200,
  fontFamily: typography.fonts.inter.normal,
}
const $dataText: TextStyle = {
  fontSize: fontSize.p5,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.normal,
  lineHeight: 15,
  marginRight: spacing.mdd,
}

const $border: ViewStyle = {
  borderBottomWidth: 1,
  borderColor: colors.palette.gray100,
}
const $iconContainer: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
}
