import React, { useState } from "react"
import {
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  TextInput,
  Alert,
  ActivityIndicator,
  ImageStyle,
} from "react-native"
import { observer } from "mobx-react-lite"
import { colors, fontSize, spacing, typography } from "app/theme"
import { Text } from "app/components/Text"
import { Dropdown } from "react-native-element-dropdown"
import { SwipeButtonSell } from "../SwipeButtonSell"
import { creditPeriodData, validityData } from "../../services/constants/constant"
import { api } from "app/services/api"
import { useRoute } from "@react-navigation/native"
import { useStores } from "app/models"
import { Icon } from "../Icon"
export interface SwipeToButtonSellProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  navigation: any
}
interface Data {
  buyButton: object
  item?: {
    OOQ?: number
    OOP?: number
    creditDays?: number
  }
}

/**
 * Describe your component here
 */
export const SwipeToButtonSell = observer(function SwipeToButtonSell(
  props: SwipeToButtonSellProps,
) {
  const { style, navigation } = props
  const $styles = [$container, style]
  const {
    authenticationStore: { authToken },
  } = useStores()

  const route = useRoute()
  const data: Data = route?.params
  const item = data?.item
  const [setOriginalListData] = useState()
  const [quantity, setQuantity] = useState(item?.OOQ)
  const [rate, setRate] = useState(item?.OOP)
  const [validity, setValidity] = useState()
  const [loading, setLoading] = useState(false)
  const [creditPeriod, setCreditPeriod] = useState(30 || item?.creditDays)
  const creditPeriodLabel = creditPeriodData.find(item => item.value === creditPeriod)?.label || ''
  

  const apiToken = authToken
  async function fetchSwipeToSell() {
    try {
      setLoading(true)
      const response = await api.getSwipeToSell(
        parseInt(quantity),
        parseFloat(rate),
        parseInt(creditPeriod),
        parseInt(validity),
        parseInt(data?.item?.yarnId),
        apiToken,
      )

      const listData: any = response.data
      const listDataMessage = JSON.parse(listData)
      const errorMessage = listDataMessage.message
      if (response.kind === "ok") {
        if (listData.code === 201) {
          setOriginalListData(JSON.parse(JSON.stringify(listData)))
          testAlert()
          setLoading(false)
          handleBack()
        }
      } else if (response.kind === "bad-data") {
        alert(errorMessage)
        setLoading(false)
        handleBack()
      }
    } catch (error) {
      setLoading(false)
      handleBack()
    }
  }
  async function fetchSwipeToSellModify() {
    try {
      setLoading(true)
      const response = await api.getSwipeToSellModify(
        parseInt(quantity),
        parseFloat(rate),
        parseInt(creditPeriod),
        parseInt(validity),
        apiToken,
        parseInt(data?.item?.OOI),
      )
      const listData: any = response.data
      const listDataMessage = JSON.parse(listData)
      const errorMessage = listDataMessage.message
      if (response.kind === "ok") {
       
        if (listData.code === 200) {
          setOriginalListData(JSON.parse(JSON.stringify(listData)))
          testAlertModify()
          setLoading(false)
          handleBack()
        } else if (listData.code === 403) {
          alert("Your approval status is not approved yet. Contact admin")
          setLoading(false)
          handleBack()
        } else {
          alert("Order Not Created. Please try again")
          setLoading(false)
          handleBack()
        }
      } else if (response.kind === "bad-data") {
        alert(errorMessage)
        setLoading(false)
        handleBack()
      } else {
        alert(response.data)
        setLoading(false)
        handleBack()
      }
    } catch (error) {
      setLoading(false)
      handleBack()
    }
  }
  const testAlert = () =>
    Alert.alert(
      "Sell Successfully",
      "Swiping to sell has been Successfully",
      [{ text: "OK", onPress: () => handleBack() }],
      { cancelable: false },
    )
  const testAlertModify = () =>
    Alert.alert(
      "Modify Successfully",
      "Swiping to Modify has been Successfully",
      [{ text: "OK", onPress: () => handleBack() }],
      { cancelable: false },
    )
  const isFieldEmpty = (value) => {
    return (
      value === undefined || value === null || (typeof value === "string" && value.trim() === "")
    )
  }
  const isRateValid = (rate) => {
    const parsedRate = parseFloat(rate);
    return !isNaN(parsedRate) && parsedRate > 0;
  };

  const handleButtonActivate = () => {
    if (
      isFieldEmpty(quantity) ||
      isFieldEmpty(rate) ||
      !isRateValid(rate) ||
      isFieldEmpty(creditPeriod) ||
      isFieldEmpty(validity)
    ) {
      Alert.alert("Validation Error", "Please fill in all required fields with valid values.");
      return;
    }
    fetchSwipeToSell();
    testAlert();
  };

  const handleModifyButtonActivate = () => {
    if (
      isFieldEmpty(quantity) ||
      isFieldEmpty(rate) ||
      !isRateValid(rate) ||
      isFieldEmpty(creditPeriod) ||
      isFieldEmpty(validity)
    ) {
      Alert.alert("Validation Error", "Please fill in all required fields with valid values.");
      return;
    }

    fetchSwipeToSellModify();
    testAlertModify();
  };
  
  const handleBack = () => {
    navigation.navigate("WatchList")
  }
  return (
    <View style={$styles}>
      <View style={loading ? $blurredContainer : null}>
        <View style={$topcontainer}>
          <View style={$row}>
            <Icon style={$backIcon} icon="back" size={24} color={colors.text} onPress={()=>navigation.goBack()}/>
            <Text style={$swipeTpBuyText}>{data?.item?.name}</Text>
          </View>
          <View style={$middleContainer}>
            <View style={$textInputContainer}>
              <View style={$rowContainer}>
                <Text style={$label} tx="swipeToSell.quantity" />
                <Text style={$boxText} tx="swipeToSell.boxes" />
                <Text style={$labelRate} tx="swipeToSell.rate" />
              </View>

              <View style={$rowContainer}>
                <TextInput
                  style={$TextInput}
                  keyboardType="numeric"
                  value={quantity === null ? " " : `${quantity}`}
                  onChangeText={(value) => setQuantity(value)}
                />
                <TextInput
                  style={$TextInput}
                  keyboardType="decimal-pad"
                  value={rate === null ? " " : `${rate}`}
                  onChangeText={(value) => setRate(value)}
                />
              </View>
            </View>
          </View>
        </View>
        <View style={$bottomContainer}>
          <View style={$rowContainer}>
            <Text style={$labelCredit} tx="swipeToSell.creditPeriod" />
            <Text style={$labelValidity} tx="swipeToSell.validity" />
          </View>
          <View style={$rowContainer}>
          <Text style={[$dropdown, $dropdownLabel]}>{creditPeriodLabel}</Text>
          {/* <Dropdown
              style={$dropdown}
              data={creditPeriodData}
              labelField="label"
              valueField="value"
              placeholderStyle={$brokerName}
              selectedTextStyle={$containerStyle}
              value={creditPeriod}
              onChange={(item) => {
                setCreditPeriod(item.value)
              }}
            /> */}
            <Dropdown
              style={$dropdown}
              data={validityData}
              labelField="day"
              valueField="value"
              placeholderStyle={$brokerName}
              value={validity}
              onChange={(item) => {
                setValidity(item.value)
              }}
            />
          </View>
          <View style={$discontMsg}>
            <Text style={$discontText} tx="swipeToSell.discount" />
          </View>
        </View>
        {loading ? (
          <ActivityIndicator size="large" />
        ) : (
          <View style={$swipeButton}>
            {item.OOP === null || item.OOQ === null ? (
              <SwipeButtonSell
                onButtonActivate={handleButtonActivate}
                disabled={
                  isFieldEmpty(quantity) ||
                  isFieldEmpty(rate) ||
                  isFieldEmpty(creditPeriod) ||
                  isFieldEmpty(validity)
                }
                nameOfSwipeButton={
                  <Text style={$swipeToSellText} tx="swipeToSell.swipeSellButton" />
                }
              />
            ) : (
              <SwipeButtonSell
                onButtonActivate={handleModifyButtonActivate}
                disabled={
                  isFieldEmpty(quantity) ||
                  isFieldEmpty(rate) ||
                  isFieldEmpty(creditPeriod) ||
                  isFieldEmpty(validity)
                }
                nameOfSwipeButton={
                  <Text style={$swipeToSellText} tx="swipeToSell.swipeBuyButton" />
                }
              />
            )}
          </View>
        )}
      </View>
    </View>
  )
})
const $discontMsg: ViewStyle = {
  position: "relative",
  top: spacing.lg + spacing.lg + spacing.lg,
  paddingHorizontal: spacing.lg + spacing.lg,
  opacity: 0.5,
  justifyContent: "center",
  alignItems: "center",
  height: 100,
}
const $backIcon: ImageStyle = {
  position: "relative",
  marginRight: spacing.sm,
  top: spacing.lg + spacing.mmd,
}
const $discontText: TextStyle = {
  fontSize: fontSize.p5,
  lineHeight: 18,
  fontFamily: typography.fonts.inter.medium,
  color: colors.palette.neutral700,
}
const $containerStyle: TextStyle = {
  fontSize: spacing.smm,
}
const $container: ViewStyle = {
  justifyContent: "center",
}
const $blurredContainer: ViewStyle = {
  backgroundColor: "transparent",
  opacity: 0.5,
}
const $topcontainer: ViewStyle = {
  backgroundColor: colors.palette.gray100,
  height: 143,
  paddingHorizontal: spacing.md,
}
const $row: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
}
const $rowContainer: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "center",
}
const $swipeToSellText: TextStyle = {
  fontFamily: typography.fonts.inter.normal,
  fontSize: fontSize.p4,
  color: colors.palette.neutral100,
}
const $swipeTpBuyText: TextStyle = {
  fontSize: fontSize.p3,
  fontFamily: typography.fonts.inter.medium,
  color: colors.palette.neutral700,
  position: "relative",
  top: spacing.lg + spacing.mmd,
}
const $middleContainer: ViewStyle = {
  height: 91,
  elevation: spacing.ssm,
  shadowColor: colors.palette.neutral900,
  shadowOffset: { width: 0, height: 0 },
  shadowOpacity: 0.1,
  shadowRadius: spacing.xxs,
  backgroundColor: colors.palette.neutral100,
  position: "relative",
  top: 70,
}
const $bottomContainer: ViewStyle = {
  position: "relative",
  top: 50,
  height: 95,
}

const $TextInput: ViewStyle = {
  borderWidth: 1,
  borderColor: colors.palette.gray400,
  height: 38,
  width: 150,
  padding: spacing.ssm,
}
const $textInputContainer: ViewStyle = {
  position: "relative",
  top: spacing.xxs,
}
const $label: TextStyle = {
  fontFamily: typography.fonts.inter.medium,
  fontSize: fontSize.p4,
  color: colors.palette.neutral700,
  position: "relative",
  right: spacing.lg + spacing.mmd,
}
const $boxText: TextStyle = {
  fontFamily: typography.fonts.inter.medium,
  fontSize: fontSize.p5,
  color: colors.palette.gray200,
  position: "relative",
  right: spacing.lg + spacing.mmd,
}
const $labelRate: TextStyle = {
  fontFamily: typography.fonts.inter.medium,
  fontSize: fontSize.p4,
  color: colors.palette.neutral700,
  position: "relative",
  right: spacing.lg + spacing.lg + spacing.lg + spacing.xxs,
}
const $labelCredit: TextStyle = {
  fontFamily: typography.fonts.inter.medium,
  fontSize: fontSize.p4,
  color: colors.palette.neutral700,
  position: "relative",
  right: spacing.lg + spacing.lg + spacing.ssm,
}
const $labelValidity: TextStyle = {
  fontFamily: typography.fonts.inter.medium,
  fontSize: fontSize.p4,
  color: colors.palette.neutral700,
  position: "relative",
  right: spacing.lg + spacing.mmd,
}

const $swipeButton: ViewStyle = {
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  height: 400,
  top: 100,
}
const $dropdown: ViewStyle = {
  height: 38,
  width: 150,
  padding: spacing.ssm,
  backgroundColor: "white",
  borderWidth: 1,
  borderColor: colors.palette.gray400,
}
const $dropdownLabel: TextStyle = {
  fontSize: fontSize.p4, 
  fontFamily: typography.fonts.inter.medium,
  color: colors.palette.neutral700,
}
const $brokerName: TextStyle = {
  fontSize: fontSize.p4,
  fontFamily: typography.fonts.inter.normal,
  color: colors.palette.neutral700,
}
