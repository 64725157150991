import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { ScrollView, TextStyle, View, ViewStyle } from "react-native"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { AppStackScreenProps } from "app/navigators"
import { Screen, Text } from "app/components"
import { colors, fontSize, spacing, typography } from "app/theme"
import { AddFirmDetail } from "app/components/yarnx"
// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "app/models"

interface AddFirmDetailsScreenProps
  extends NativeStackScreenProps<AppStackScreenProps<"AddFirmDetails">> {}

export const AddFirmDetailsScreen: FC<AddFirmDetailsScreenProps> = observer(
  function AddFirmDetailsScreen(_props) {
    // Pull in one of our MST stores
    // const { someStore, anotherStore } = useStores()

    // Pull in navigation via hook
    // const navigation = useNavigation()
    const { navigation } = _props
    return (
      <Screen preset="scroll" contentContainerStyle={$container} safeAreaEdges={["top"]}>
        <View style={$ProfileContainer}>
          <View style={$rowcontainer}>
            <Text style={$Profiletext} tx="AddFirmDetails.firmDetails"></Text>
          </View>
        </View>
        <ScrollView>
          <AddFirmDetail navigation={navigation}></AddFirmDetail>
        </ScrollView>
      </Screen>
    )
  },
)

const $container: ViewStyle = {
  flex: 1,
  paddingBottom: 50,
}
const $ProfileContainer: ViewStyle = {
  flexDirection: "row",
  marginTop: spacing.xll,
}
const $rowcontainer: ViewStyle = {
  flexDirection: "row",
}
const $Profiletext: TextStyle = {
  fontSize: fontSize.h2,
  marginLeft: spacing.md,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.bold,
}
