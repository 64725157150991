import { WatchListItem } from "./models/WatchListModels"

export function getWatchListData(data) {
  return data.map((item) => {
    return new WatchListItem({
      yarnId: item.yarn_id,
      name: item.quality_name,
      count: item.count,
      mill: item.mill,
      variety: item.variety,
      weightPerBag: item.weight_per_bag,
      conePerBag: item.cone_per_bag,
      YEP: item.YEP,
      YEQ: item.YEQ,
      YET: item.YET,
      TEP: item.TEP,
      TEQ: item.TEQ,
      TET: item.TET,
      OOQ: item.OOQ,
      OOP: item.OOP,
      OOT: item.OOT,
      OOI: item.OOI,
      creditDays: item.OpenOrderCreditDays,
      brokerId: item.brokerId,
      refValue: item.ref_value,
      groupNo: item.groupNo
    })
  })
}
