export const creditPeriodData = [
  // {
  //   value: 1,
  //   label: "Super second day",
  // },
  // {
  //   value: 7,
  //   label: "Second day",
  // },
  {
    value: 30,
    label: "Regular (30 days)",
  },
]
export const validityData = [
  // {
  //   value: "2",
  //   day: "2 minute",
  // },
  // {
  //   value: "10",
  //   day: "10 minutes",
  // },
  {
    value: "60",
    day: "1 hour",
  },
  {
    value: "120",
    day: "2 hour",
  },
  {
    value: "360",
    day: "6 hour",
  },
  {
    value: "1440",
    day: "1 day",
  },
]
export const roleData = [
  {
    id: "buyer",
    name: "Buyer",
  },
  {
    id: "seller",
    name: "Seller",
  },
]

export const allBrokers = [
  {
    id: 101,
    phoneNumber: "1234567890",
    whatsAppNumber: "9876543210",
    name: "fake name",
    email: "fake@example.com",
  },

  {
    id: 102,
    phoneNumber: "1234567890",
    whatsAppNumber: "9876543210",
    name: "pallavi",
    email: "pallavi@example.com",
  },

  {
    id: 103,
    phoneNumber: "1234567890",
    whatsAppNumber: "9876543210",
    name: "neha",
    email: "neha@example.com",
  },

  {
    id: 104,
    phoneNumber: "1234567890",
    whatsAppNumber: "9876543210",
    name: "name",
    email: "fake7@example.com",
  },
]
export function convertUTCToIST(utcDate) {
  const utcTime = new Date(utcDate)
  const istTime = new Date(utcTime.getTime() + 60 * 60)
  return istTime
}

export const setTime = (createdAt) => {
  const indianTime = convertUTCToIST(createdAt)
  const formattedTime = indianTime.toLocaleString("en-IN", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  })

  return formattedTime
}
export const setDate = (createdAt) => {
  const indianTime = convertUTCToIST(createdAt)
  const formattedDate = indianTime.toLocaleString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  })

  return formattedDate
}

export default {
  API: {
    Url: process.env.REACT_APP_BASE_URL,
  },
}

export const contacts = {
  contact1: "8265045314",
  contact2: "8265045314",
  whatsappNo: "8265045314",
}

export const currectTime = () => {
  const currentDate = new Date()
  return currentDate
}
export const expireTime = (time) => {
  const endTime = new Date(time)
  return endTime
}
