import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"
import { useFocusEffect } from "@react-navigation/native"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { TextStyle, View, ViewStyle, ActivityIndicator, StatusBar } from "react-native"
import { AppStackScreenProps } from "app/navigators"
import { Screen, Text } from "app/components"
import { Alerts } from "../components/yarnx"
import { colors, fontSize, spacing, typography } from "../theme"
import SSE from "react-native-sse"
import { useStores } from "app/models"
import { API_BASE_URL } from "../../url.js"
import { api } from "app/services/api"
interface AlertsScreenProps extends NativeStackScreenProps<AppStackScreenProps<"Alerts">> {}

export const AlertsScreen: FC<AlertsScreenProps> = observer(function AlertsScreen(props) {
  const { style } = props
  const {
    authenticationStore: { authToken, logout, authRefreshToken },
  } = useStores()
  const apiURL = `${API_BASE_URL}/orders/alert`
  const apiToken = authToken
  const [alertData, setAlertData] = useState([])
  const [loading, setLoading] = useState(true)

  useFocusEffect(
    React.useCallback(() => {
      const sse = new SSE(apiURL, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      })

      sse.addEventListener("message", (event) => {
        try {
          const eventData = JSON.parse(event.data)
          setAlertData(eventData.reverse())
          setLoading(false)
        } catch (error) {
          setLoading(false)
        } finally {
          setLoading(false)
        }
      })
      sse.addEventListener("error", async (event) => {
        if (event && event.message) {
          try {
            const errorData = JSON.parse(event.message);
            if (errorData.code === 401) {
              try {
                await api.postLogout(authRefreshToken);
              } catch (logoutError) {
                console.error("Error during logout:", logoutError);
              }
              logout();
              sse.close();
            }
          } catch (parseError) {
            console.error("Error parsing error message:", parseError);
          }
        } else {
          console.error("Unknown SSE error:", event);
        }
      });
    
      return () => {
        sse.close()
      }
    }, []),
  )

  return (
    <Screen preset="fixed" contentContainerStyle={$container} safeAreaEdges={["top"]}>
      <StatusBar backgroundColor={colors.palette.gray100} />
      <View style={$AlertContainer}>
        <View style={$rowContainer}>
          <Text style={$alertText} tx="AlertsScreen.Alert"></Text>
        </View>
      </View>
      {loading ? (
        <ActivityIndicator size="large" />
      ) : alertData.length > 0 ? (
        <Alerts alertData={alertData} />
      ) : (
        <View style={$noDataContainer}>
          <Text style={$noDataText} tx="AlertsScreen.noData" />
        </View>
      )}
    </Screen>
  )
})
const $container: ViewStyle = {
  flex: 1,
}
const $AlertContainer: ViewStyle = {
  flexDirection: "row",
  backgroundColor: colors.palette.gray100,
  height: 106,
}
const $rowContainer: ViewStyle = {
  flexDirection: "row",
  marginHorizontal: spacing.md,
  marginTop: spacing.xxl,
}
const $alertText: TextStyle = {
  fontSize: fontSize.h2,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.bold,
}
const $noDataContainer: ViewStyle = {
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
}

const $noDataText: TextStyle = {
  fontSize: fontSize.h4,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.bold,
  opacity: 0.2,
}
