import { BlockListScreen } from "app/screens"


const en: any = {
  common: {
    ok: "OK!",
    cancel: "Cancel",
    back: "Back",
    logOut: "Log Out", // @demo remove-current-line
  },
  welcomeScreen: {
    hello: "Hello, ",
    welcomeText: "Welcome to Yarn",
    redX: "X",
    login: "Login",
    opennewacc: "Open a new account",
  },
  errorScreen: {
    title: "Something went wrong!",
    friendlySubtitle:
      "This is the screen that your users will see in production when an error is thrown. You'll want to customize this message (located in `app/i18n/en.ts`) and probably the layout as well (`app/screens/ErrorScreen`). If you want to remove this entirely, check `app/app.tsx` for the <ErrorBoundary> component.",
    reset: "RESET APP",
    traceTitle: "Error from %{name} stack", // @demo remove-current-line
  },
  emptyStateComponent: {
    generic: {
      heading: "So empty... so sad",
      content: "No data found yet. Try clicking the button to refresh or reload the app.",
      button: "Let's try this again",
    },
  },
  // @demo remove-block-start
  errors: {
    invalidEmail: "Invalid email address.",
  },
  registerScreen: {
    register: "Registration",
    btnName: "Register",
    nameLabel: "Name",
    emailLabel: "Email",
    passwordLabel: "Password",
    phoneNumber: "PhoneNumber",
    whatsappNumber: "WhatsappNumber",
  },
  loginScreen: {
    login: "Login",
    usernameLabel: "Username",
    passwordLabel: "Password",
    loginCaps: "LOGIN",
    forgotUsername: "Forgot username or password?",
  },
  navigator: {
    watchlist: "Watchlist",
    orders: "Orders",
    alerts: "Alerts",
    account: "Account",
  },
  ordersScreen: {
    title: "Orders",
    orderNo: "Order no.",
    brokerName: "Broker Name:",
    buyerName: "Buyer Name:",
    sellerName: "Seller Name:",
    noData: "No data available",
    expired: "Expired",
  },
  swipeToBuy: {
    swipeToBuyText: "61 CCW Sanathan",
    quantity: "Quantity",
    rate: "Rate",
    boxes: "Boxes",
    validity: "Validity",
    creditPeriod: "Credit Period",
    broker: "Broker",
  },
  swipeButton: {
    swipeToBuy: "SWIPE TO BUY",
    swipeToSell: "SWIPE TO SELL",
    swipeToModify: "SWIPE TO MODIFY",
    swipedToSuccess: "SWIPE SUCCEED",
  },
  watchListScreen: {
    title: "WatchList",
    box: "boxes at ₹",
  },
  swipeToSell: {
    swipeToBuyText: "61 CCW Sanathan",
    quantity: "Quantity",
    rate: "Rate",
    boxes: "Boxes",
    validity: "Validity",
    creditPeriod: "Credit Period",
    broker: "Broker",
    swipeSellButton: "Swipe to Sell",
    swipeBuyButton: "Swipe to Modify",
    successMessage: "Sell Successfully",
    swipeToSellMessage: "Swiping to sell has been Successfully",
    discount:
      "⚠️Please quote Regular (30 day) rate (irrespective of selected credit period).Discount for super second and second day would be applied while final billing.",
  },
  accountScreen: {
    title: "Account",
    support: "Support",
    others: "Others",
    surName: "Name Surname",
    userName: "User Name",
    email: "emailaddress.com",
    version:"Version 1.1.0"
  },
  searchBar: {
    placeholder: "Search",
  },
  filterList: {
    filter: "Filter",
    clear: "Clear",
    type: "Type",
    counts: "Counts",
    sort: "Sort",
    sortAlphabetically: "Sort Alphabetically",
    sortbycounts: "Sort By Counts",
  },
  demoPodcastListScreen: {
    title: "React Native Radio episodes",
    onlyFavorites: "Only Show Favorites",
    favoriteButton: "Favorite",
    unfavoriteButton: "Unfavorite",
    accessibility: {
      cardHint:
        "Double tap to listen to the episode. Double tap and hold to {{action}} this episode.",
      switch: "Switch on to only show favorites",
      favoriteAction: "Toggle Favorite",
      favoriteIcon: "Episode not favorited",
      unfavoriteIcon: "Episode favorited",
      publishLabel: "Published {{date}}",
      durationLabel: "Duration: {{hours}} hours {{minutes}} minutes {{seconds}} seconds",
    },
    noFavoritesEmptyState: {
      heading: "This looks a bit empty",
      content:
        "No favorites have been added yet. Tap the heart on an episode to add it to your favorites!",
    },
  },
  bottomSheet: {
    buttonText: "MODIFY",
    buyButtonText: "BUY",
    sellButtonText: "SELL",
    deleteButtonName: "Delete",
    marketDepthText: "Market Depth",
    pending: "Your profile status is in a 'Pending' state.",
    tableHeader: {
      sell: "Sell",
      buy: "Buy",
      Orders: "Orders",
      Qty: "Qty",
    },
    expandTable: "Show More",
    expandLessTable: "Show Less",
    lastTradedQuantity: "Last traded quantity",
    lastTreadRate: "59",
    lastTrad: "Last traded at",
    lastTradTime: "05-12-2023 10:34 am",
    prevClose: "Prev. close",
    lastTradeRate: "43.54",
  },
  AlertsScreen: {
    Alert: "Alerts",
    noData: "No data available",
  },
  Profile: {
    profile: "Profile",
    name: "Name Surname",
    username: "Username",
    nickname: "NS",
    password: "Password & security",
    managepassword: "Manage",
    manageAccount: "Manage Account",
    emailLabel: "E-mail",
    phoneLabel: "Phone no.",
    whatsappLabel: "WhatsApp Phone no.",
    statusLabel: "Approval status",
    tagLabel: "Tag",
    submit: "Submit",
    cancel: "Cancel",
  },
  AccountScecrity: {
    accountsecurity: "Account Security",
    changepassword: "Change password",
    currentpasswordLabel: "Current password",
    newpasswordLabel: "New password",
    confirmpasswordLabel: "Confirm new password",
    submit: "Submit",
    cancel: "Cancel",
  },
  FirmDetails: {
    firmDetails: "Firm Details",
    detailsText: "Details",
    firmName: "Firm Name",
    ownerName: "Owner name",
    address: "Address",
    gstno: "GST no.",
    panno: "PAN no.",
    manageDetails: "Manage Details",
    bankDetails: "Bank Details",
    bankName: "Bank name",
    bankAccountNo: "Bank account no.",
    ifscCode: "IFSC code",
    manageBankDetils: "Manage Details",
  },
  AddFirmDetails: {
    firmDetails: "Add Firm Details",
    detailsText: "Details",
    firmName: "Firm Name*",
    ownerName: "Owner name*",
    address: "Address*",
    gstno: "GST no.*",
    panno: "PAN no.*",
    manageDetails: "Manage Details*",
    bankDetails: "Bank Details",
    bankName: "Bank name*",
    bankAccountNo: "Bank account no.*",
    ifscCode: "IFSC code*",
    submit: "Submit",
    cancel: "Cancel",
  },
  Contact: {
    contactText: "Contact",
    address: "Address",
    addressInfo: "5/400/1, Date Mala, Ichalkaranji, Kolhapur, Maharashtra, 416115, India",
    contact: "Contact Numbers",
    contact1: "8265045314",
    contact2: "8265045314",
    whatsApp: "WhatsApp Number",
    whatsappNo: "8265045314",
  },
  Invite: {
    invite: "Invite friends",
    invitemsg: "Refer your friends and family and get referral rewards.",
    share: "Share",
  },
  Licenses: {
    title: "TERMS OF USE",
    
  },
  BlockListScreen:{
    title:"Block List"
  }
}

export default en
export type Translations = typeof en
