import React, { useState } from "react"
import {
  ImageStyle,
  StyleProp,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
  ActivityIndicator,
} from "react-native"
import { observer } from "mobx-react-lite"
import { colors, fontSize, spacing, typography } from "app/theme"
import { Text } from "app/components/Text"
import { TextInput, IconButton } from "@react-native-material/core"
import { Icon } from "../Icon"
import I18n from "i18n-js"
import { api } from "app/services/api"
import { useStores } from "app/models"

export interface ManagePasswordProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  navigation: any
}

/**
 * Describe your component here
 */
export const ManagePassword = observer(function ManagePassword(props: ManagePasswordProps) {
  const { style, navigation } = props
  const $styles = [$container, style]
  const {
    authenticationStore: { authRefreshToken },
  } = useStores()
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  function goPrevious() {
    setCurrentPassword(null)
    setNewPassword(null)
    setConfirmPassword(null)
    setShowCurrentPassword(false)
    setShowNewPassword(false)
    setShowConfirmPassword(false)
    navigation.navigate("ProfileScreen")
  }

  async function submitPasswordDetails() {
    if (currentPassword === "" || newPassword === "" || confirmPassword === "") {
      setError("Passwords cannot be empty.")
    } else if (newPassword !== confirmPassword) {
      setError("New password and confirm password not matched.")
    } else {
      setLoading(true)
      try {
        const response = await api.getResetPassword(
          authRefreshToken,
          currentPassword,
          newPassword,
          confirmPassword,
        )
        if (response.kind === "ok") {
          const Data: any = response.responseData
          if (Data.code === 200) {
            alert(Data.message)
            navigation.navigate("ProfileScreen")
          } else {
            alert("Password Not update SuccessDully")
          }
        } else if (response.kind === "bad-data") {
          alert("Something went wrong, Please try again")
          navigation.navigate("Account")
        }
      } catch (error) {
        setLoading(false)
      } finally {
        setLoading(false)
      }
    }
  }

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword)
  }
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword)
  }
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  return (
    <View style={$styles}>
      <View style={$ProfileContainer}>
        <View style={$rowcontainer}>
          <Text style={$Profiletext} tx="AccountScecrity.accountsecurity"></Text>
        </View>
      </View>
      <Text style={$changepasswordText} tx="AccountScecrity.changepassword"></Text>
      <TextInput
        style={$input}
        label={I18n.translate("AccountScecrity.currentpasswordLabel")}
        color={colors.palette.gray200}
        variant="outlined"
        value={currentPassword}
        onChangeText={(value) => setCurrentPassword(value)}
        secureTextEntry={!showCurrentPassword}
        trailing={(props) => (
          <IconButton
            icon={(props) => <Icon icon={showCurrentPassword ? "view" : "hide"} {...props} />}
            {...props}
            onPress={toggleCurrentPasswordVisibility}
          />
        )}
      />
      <TextInput
        style={$input}
        label={I18n.translate("AccountScecrity.newpasswordLabel")}
        color={colors.palette.gray200}
        variant="outlined"
        value={newPassword}
        onChangeText={(value) => setNewPassword(value)}
        secureTextEntry={!showNewPassword}
        trailing={(props) => (
          <IconButton
            icon={(props) => <Icon icon={showNewPassword ? "view" : "hide"} {...props} />}
            {...props}
            onPress={toggleNewPasswordVisibility}
          />
        )}
      />
      <TextInput
        style={$input}
        label={I18n.translate("AccountScecrity.confirmpasswordLabel")}
        color={colors.palette.gray200}
        variant="outlined"
        value={confirmPassword}
        onChangeText={(value) => setConfirmPassword(value)}
        secureTextEntry={!showConfirmPassword}
        trailing={(props) => (
          <IconButton
            icon={(props) => <Icon icon={showConfirmPassword ? "view" : "hide"} {...props} />}
            {...props}
            onPress={toggleConfirmPasswordVisibility}
          />
        )}
      />
      <Text style={$error}>{error}</Text>
      <View style={$buttoncontainer}>
        <View>
          {loading ? (
            <ActivityIndicator size="large" />
          ) : (
            <TouchableOpacity
              activeOpacity={0.8}
              style={$submitbutton}
              onPress={submitPasswordDetails}
            >
              <Text style={$submitbuttonText} tx="AccountScecrity.submit"></Text>
            </TouchableOpacity>
          )}
        </View>
        <View style={$canclebuttonContainer}>
          <TouchableOpacity activeOpacity={0.8} style={$cancelbutton} onPress={goPrevious}>
            <Text style={$cancelbuttonText} tx="AccountScecrity.cancel"></Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
})

const $container: ViewStyle = {
  marginHorizontal: spacing.md,
}

const $ProfileContainer: ViewStyle = {
  flexDirection: "row",
  marginTop: spacing.xll,
}
const $rowcontainer: ViewStyle = {
  flexDirection: "row",
}
const $Profiletext: TextStyle = {
  fontSize: fontSize.h2,
  marginLeft: spacing.md,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.bold,
}
const $changepasswordText: TextStyle = {
  fontSize: fontSize.p1,
  marginTop: spacing.xll,
  marginBottom: spacing.ssm,
}

const $input: ViewStyle | ImageStyle | TextStyle = {
  marginTop: spacing.llg,
}

const $buttoncontainer: ViewStyle = {
  flexDirection: "row",
  marginTop: spacing.ssm,
}
const $submitbutton: ViewStyle = {
  width: spacing.xxxxl,
  backgroundColor: colors.palette.highlight100,
  borderRadius: spacing.xxs,
}
const $submitbuttonText: TextStyle = {
  color: colors.palette.neutral100,
  fontSize: fontSize.p3,
  padding: spacing.ssm,
  textAlign: "center",
}
const $canclebuttonContainer: ViewStyle = {
  marginLeft: spacing.md,
}
const $cancelbutton: ViewStyle = {
  width: spacing.xxxxl,
  borderWidth: 1,
  borderColor: colors.palette.gray200,
  backgroundColor: colors.palette.neutral100,
  borderRadius: spacing.xxs,
}
const $cancelbuttonText: TextStyle = {
  color: colors.palette.gray300,
  fontSize: fontSize.p3,
  padding: spacing.ssm,
  textAlign: "center",
}

const $error: TextStyle = {
  color: colors.palette.failure100,
  fontSize: fontSize.p5,
}
