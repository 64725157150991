export enum ActionType {
  BottomSheetClose = "BottomSheetClose",
  Buy = "Buy",
  Sell = "Sell",
  Modify = "Modify",
  Delete = "Delete",
  UpdateYarnId = "UpdateYarnId",
}

export interface CallbackResponse {
  actionType: ActionType
  data?: any
}
export enum ActionAccountType {
  One = "1",
  Two = "2",
  Three = "3",
  Four = "4",
}
export enum NavigationType {
  ProfileScreen = "ProfileScreen",
  FirmDetailsScreen = "FirmDetailsScreen",
  Contact = "Contact",
  InviteFriends = "InviteFriends",
  Licenses = "Licenses",
  BlockListScreen="BlockListScreen"
}
