import React, { FC, useEffect, useState } from "react"
import { TextStyle, ViewStyle, StatusBar } from "react-native"
import { Screen, Text } from "../components"
import { TabScreenProps } from "../navigators/AppCoreNavigator"
import { colors, fontSize, spacing, typography } from "../theme"
import { OpenOrderList } from "../components/yarnx"
import { useStores } from "app/models"
import { getOpenOrdersData } from "app/presenter/OpenOrderSPresenter"
import SSE from "react-native-sse"
import { API_BASE_URL } from "../../url.js"
import { api } from "app/services/api"
export const OrdersScreen: FC<TabScreenProps<"Orders">> = function OrdersScreen(_props) {
  const [openOrder, setOpenOrder] = useState([])
  const [openOrderData, setOpenOrderData] = useState([])
  const [deletedOrderData, setdeletedOrderData] = useState([])
  const [excutedOrderData, setExcutedOrderData] = useState([])
  const { navigation } = _props
  const {
    authenticationStore: { authToken, logout, authRefreshToken },
  } = useStores()
  const apiURL = `${API_BASE_URL}/orders/open`
  const apiURLExecuted = `${API_BASE_URL}/orders/executed`
  const apiURLDeleted = `${API_BASE_URL}/orders/deleted`
  const apiToken = authToken

  const handleError = async (event) => {
    if (event && event.message) {
      try {
        const errorData = JSON.parse(event.message);
        if (errorData.code === 401) {
          try {
            await api.postLogout(authRefreshToken);
          } catch (logoutError) {
            console.error("Error during logout:", logoutError);
          }
          logout();
        }
      } catch (parseError) {
        console.error("Error parsing error message:", parseError);
      }
    } else {
      console.error("Unknown SSE error:", event);
    }
  };
  
  const getOpenOrder = () => {
    const sse = new SSE(apiURL, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    })
    sse.addEventListener("message", (event) => {
      const eventData = JSON.parse(event.data)
      const OpenOrderData = getOpenOrdersData(eventData)
      setOpenOrder((prevData) => {
        if (prevData.length === 0) {
          // If prevData is empty, simply reverse and return OpenOrderData
          return OpenOrderData.reverse()
        } else {
          const updatedData = [...prevData]
          OpenOrderData.forEach((item) => {
            let foundMatch = false

            prevData.forEach((prevItem, index) => {
              // If order was modified or partially executed, update the previous order with new order
              if (prevItem.yarnId === item.yarnId) {
                updatedData[index] = item
                foundMatch = true
                return
              }
              // If order was deleted or expired or fully executed, delete the order
              if (prevItem.OOI === item.deleteId) {
                updatedData.splice(index, 1)
                foundMatch = true
              }
            })
            if (!foundMatch) {
              // Add the new OpenOrderData to the beginning of the array only once
              updatedData.unshift(item)
            }
          })
          return updatedData
        }
      })
    })
    sse.addEventListener("error", handleError); 
    return () => {
      sse.close()
    }
  }
  const getExeuctedOrder = () => {
    const sse = new SSE(apiURLExecuted, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    })
    sse.addEventListener("message", (event) => {
      const eventData = JSON.parse(event.data)
      setExcutedOrderData((prevData) => {
        if (prevData.length === 0) {
          return eventData.reverse()
        }
        return [...eventData, ...prevData]
      })
    })
    sse.addEventListener("error", handleError);
    return () => {
      sse.close()
    }
  }
  const getDeleteOrder = () => {
    const sse = new SSE(apiURLDeleted, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    })
    sse.addEventListener("message", (event) => {
      const eventData = JSON.parse(event.data)
      setdeletedOrderData((prevData) => {
        if (prevData.length === 0) {
          return eventData.reverse()
        }
        return [...eventData, ...prevData]
      })
    })
    sse.addEventListener("error", handleError);
    return () => {
      sse.close()
    }
  }
  useEffect(() => {
    getOpenOrder()
    getExeuctedOrder()
    getDeleteOrder()
  }, [])

  return (
    <Screen preset="fixed" contentContainerStyle={$container} safeAreaEdges={["top"]}>
      <StatusBar backgroundColor={colors.palette.gray100} />
      <Text preset="heading" tx="ordersScreen.title" style={$title} />
      <OpenOrderList
        data={openOrder}
        openOrderData={openOrderData}
        excutedOrderData={excutedOrderData}
        deletedOrderData={deletedOrderData}
        authToken={authToken}
        navigation={navigation}
        getOpenOrder={getOpenOrder}
        getExeuctedOrder={getExeuctedOrder}
        getDeleteOrder={getDeleteOrder}
      />
    </Screen>
  )
}

const $container: ViewStyle = {
  paddingTop: spacing.lg,
  backgroundColor: colors.palette.gray100,
  flex: 1,
}

const $title: TextStyle = {
  marginBottom: spacing.ssm,
  fontSize: fontSize.h2,
  fontFamily: typography.fonts.inter.bold,
  marginLeft: spacing.md,
}
