import React, { FC, useState } from "react"
import { View, ActivityIndicator, ScrollView, RefreshControl, ViewStyle } from "react-native"
import { SearchBar, Watchlist } from "../../components/yarnx"
import { FilterResponseParams } from "app/types"
import { colors, spacing } from "../../theme"

interface WatchListTabProps {
  data: any[]
  loading: boolean
  refreshing: boolean
  onRefresh: () => void
  navigation: any
  yarnId: string | null
  searchBarCallback: (resp: Array<FilterResponseParams>) => void
  handleSearch: (query: string) => void
  originalWatchListData: any[]
  setUpdatedYarnId: (yarnId: string | null) => void
}

const WatchListTab: FC<WatchListTabProps> = ({
  data,
  loading,
  refreshing,
  onRefresh,
  navigation,
  yarnId,
  searchBarCallback,
  handleSearch,
  originalWatchListData,
  setUpdatedYarnId,
}) => {
  return (
    <View style={$container}>
      <View style={$header}>
        <View style={$tabs}></View>
        <View style={$searchBarWrapper}>
          <SearchBar
            callback={searchBarCallback}
            searchCallback={handleSearch}
            data={originalWatchListData}
            style={$searchBar}
          />
        </View>
      </View>
      <View style={$spaceContainer} />
      <ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
        {loading ? (
          <ActivityIndicator size="large" />
        ) : (
          <Watchlist
            data={data}
            navigation={navigation}
            yarnId={yarnId}
            onYarnIdChange={setUpdatedYarnId}
          />
        )}
      </ScrollView>
    </View>
  )
}

const $container: ViewStyle = {
  flex: 1,
  backgroundColor: colors.palette.neutral100,
}

const $header: ViewStyle = {
  backgroundColor: colors.palette.gray100,
  paddingBottom: spacing.sm,
}

const $tabs: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-around",
  padding: spacing.sm,
  backgroundColor: colors.palette.gray100,
}

const $searchBarWrapper: ViewStyle = {
  position: "absolute",
  top: "30%",
  left: 0,
  right: 0,
  zIndex: 1,
}

const $searchBar: ViewStyle = {
  elevation: 2,
}

const $spaceContainer: ViewStyle = {
  width: "100%",
  backgroundColor: colors.palette.neutral100,
  zIndex: -1,
  height: 25,
}

export default WatchListTab
