import { ApisauceInstance, create } from "apisauce"
import Config from "../../config"
import { GeneralApiProblem, getGeneralApiProblem } from "./apiProblem"
import type { ApiConfig } from "./api.types"
import { accoutData, supportData, othersData } from "../../services/mocks"
import SSE from "react-native-sse"
import { API_BASE_URL } from "../../../url.js"
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: Config.API_URL,
  timeout: 10000,
}

export class Api {
  apisauce: ApisauceInstance
  config: ApiConfig
  headers: Headers
  authToken: string | null

  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config
    this.headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
    })
    this.authToken = null
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: this.headers,
    })
  }

  getToken(token) {
    this.authToken = token
    if (token) {
      this.headers.set("Authorization", `Bearer ${token}`)
    }
  }

  async getBrokerData(token) {
    try {
      const Url = `${API_BASE_URL}/brokers`
      this.headers.set("Authorization", `Bearer ${token}`)
      const header = this.headers
      const response = await fetch(Url, {
        method: "GET",
        headers: header,
      })

      if (!response.ok) {
        return { kind: "bad-data" }
      }

      const rawData = await response.json()
      return { kind: "ok", data: rawData }
    } catch (error) {
      if (__DEV__) {
        console.tron.error(`Error: ${error.message}`)
      }
      return { kind: "bad-data" }
    }
  }

  async getProfileData(token, id) {
    try {
      const Url = `${API_BASE_URL}/users/${id}`
      this.headers.set("Authorization", `Bearer ${token}`)
      const header = this.headers
      const response = await fetch(Url, {
        method: "GET",
        headers: header,
      })

      if (!response.ok) {
        if(response.status === 401){
          return { kind : "Unauthorized"}
        }
        console.error(`Request failed with status: ${response.status}`)
        return { kind: "bad-data" }
      }

      const rawData = await response.json()
      return { kind: "ok", data: rawData }
    } catch (error) {
      console.error(`Error: ${error.message}`)
      return { kind: "bad-data" }
    }
  }

  

  async getFirmDetails(token, id) {
    try {
      const Url = `${API_BASE_URL}/users/${id}`
      this.headers.set("Authorization", `Bearer ${token}`)
      const response = await fetch(Url, {
        method: "GET",
        headers: this.headers,
      })
      if (!response.ok) {
        if(response.status === 401){
          return { kind : "Unauthorized"}
        }
        return { kind: "bad-data" }
      }

      const rawData = await response.json()
      return { kind: "ok", data: rawData }
    } catch (error) {
      if (__DEV__) {
        console.tron.error(`Error: ${error.message}`)
      }
      return { kind: "bad-data" }
    }
  }

  async getGroupData(token, id) {
    try {
      const Url = `${API_BASE_URL}/watchlist/${id}`
      this.headers.set("Authorization", `Bearer ${token}`)
      const header = this.headers
      const response = await fetch(Url, {
        method: "GET",
        headers: header,
      })

      if (!response.ok) {
        if(response.status === 401){
          return { kind : "Unauthorized"}
        }
        console.error(`Request failed with status: ${response.status}`)
        return { kind: "bad-data" }
      }

      const rawData = await response.json()
      return { kind: "ok", data: rawData }
    } catch (error) {
      console.error(`Error: ${error.message}`)
      return { kind: "bad-data" }
    }
  }
 

  async getAlertData(
    apiUrl: string,
    apiToken: string,
    onDataOpenReceived: (data: object) => void,
  ): Promise<void> {
    let source: SSE | null = null

    try {
      source = new SSE(apiUrl, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      })

      source.addEventListener("message", (e) => {
        try {
          const data: any = JSON.parse(e.data)
          onDataOpenReceived(data)
        } catch (error) {
          console.error("Error parsing SSE data: ", error)
        }
      })

      source.addEventListener("error", (e) => {
        console.error("Error: ", e)
      })
    } catch (error) {
      console.error("Error establishing SSE connection: ", error)
    }
  }

  async deleteMarketDepthData(
    apiUrl: string,
    token: string,
    id: number,
  ): Promise<{ kind: "ok" } | GeneralApiProblem> {
    const fullApiUrl = `${apiUrl}/${id}`
    const api = create({
      baseURL: apiUrl,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    try {
      const response = await api.delete(fullApiUrl)

      if (!response.ok) {
        return { kind: "bad-data" }
      }

      return { kind: "ok" }
    } catch (e) {
      if (__DEV__) {
        console.tron.error(`Bad data: ${e.message}`, e.stack)
      }
      return { kind: "bad-data" }
    }
  }

  async getResetPassword(refreshToken, currentPassword, newPassword, confirmPassword) {
    const fullApiUrl = `${API_BASE_URL}/auth/reset-password?token=${refreshToken}`
    const requestData = {
      currentPassword,
      newPassword,
      confirmPassword,
    }

    const headers = {
      "Content-Type": "application/json",
    }

    try {
      const response = await fetch(fullApiUrl, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      })

      if (!response.ok) {
        const errorResponse = await response.json()
        console.error(`API Error: ${response.status} - ${errorResponse.message}`)
        return { kind: "bad-data", error: errorResponse }
      }

      const responseData = await response.json()
      return { kind: "ok", responseData }
    } catch (error) {
      console.error(`Network Error: ${error.message}`)
      return { kind: "bad-data", error: error.message }
    }
  }

  getWatchListData = async (
    apiUrl: string,
    apiToken: string,
    onDataReceived: (data: object) => void,
  ): Promise<void> => {
    let source: SSE | null = null

    try {
      source = new SSE(apiUrl, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      })

      source.addEventListener("message", (e) => {
        try {
          const data: any = JSON.parse(e.data)
          onDataReceived(data)
        } catch (error) {
          console.error("Error parsing SSE data: ", error)
        }
      })

      source.addEventListener("error", (e) => {
        console.error("Error: ", e)
      })

      await new Promise<void>((resolve, reject) => {
        source.addEventListener("close", () => {
          resolve()
        })

        source.addEventListener("error", (errorEvent) => {
          console.error("SSE error: ", errorEvent)
          reject(errorEvent)
        })
      })
    } catch (error) {
      console.error("Error establishing SSE connection: ", error)
    } finally {
      if (source) {
        source.close()
      }
    }
  }

  async getTableData(
    apiUrl: string,
    apiToken: string,
    qualityId: number,
    onDataTableReceived: (data: object) => void,
  ): Promise<void> {
    const fullApiUrl = `${apiUrl}/${qualityId}`
    const source = new SSE(fullApiUrl, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    })

    source.addEventListener("message", (e) => {
      try {
        const data: any = JSON.parse(e.data)
        onDataTableReceived(data)
      } catch (error) {
        console.error("Error parsing SSE data: ", error)
      }
    })
  }

  async getSupportsData(): Promise<{ kind: "ok"; data: object } | GeneralApiProblem> {
    const response = {
      data: supportData,
      ok: true,
    }

    if (!response.ok) {
      const problem = getGeneralApiProblem(response.data)
      if (problem) return problem
    }

    try {
      const rawData = response.data

      return { kind: "ok", data: rawData }
    } catch (e) {
      if (__DEV__) {
        console.tron.error(`Bad data: ${e.message}\n${response.data}`, e.stack)
      }
      return { kind: "bad-data" }
    }
  }

  async getOthersData(): Promise<{ kind: "ok"; data: object } | GeneralApiProblem> {
    const response = {
      data: othersData,
      ok: true,
    }

    if (!response.ok) {
      const problem = getGeneralApiProblem(response.data)
      if (problem) return problem
    }

    try {
      const rawData = response.data

      return { kind: "ok", data: rawData }
    } catch (e) {
      if (__DEV__) {
        console.tron.error(`Bad data: ${e.message}\n${response.data}`, e.stack)
      }
      return { kind: "bad-data" }
    }
  }

  async getOpenOrders(
    apiUrl: string,
    apiToken: string,
    onDataOpenReceived: (data: object) => void,
  ): Promise<void> {
    let source: SSE | null = null

    try {
      source = new SSE(apiUrl, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      })

      source.addEventListener("message", (e) => {
        try {
          const data: any = JSON.parse(e.data)
          onDataOpenReceived(data)
        } catch (error) {
          console.error("Error parsing SSE data: ", error)
        }
      })

      source.addEventListener("error", (e) => {
        console.error("Error: ", e)
      })
    } catch (error) {
      console.error("Error establishing SSE connection: ", error)
    }
  }

  async getDeletedOrders(
    apiUrl: string,
    apiToken: string,
    onDataDeletedReceived: (data: object) => void,
    onError: (error: any) => void,
  ): Promise<void> {
    let source: SSE | null = null

    try {
      source = new SSE(apiUrl, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      })

      source.addEventListener("message", (e) => {
        try {
          const data: any = JSON.parse(e.data)
          onDataDeletedReceived(data)
        } catch (error) {
          console.error("Error parsing SSE data: ", error)
        }
      })

      source.addEventListener("error", (e) => {
        console.error("Error with SSE connection: ", e)
        source?.close()
      })
    } catch (error) {
      onError(error)
    }
  }

  async getLoginData(email, password, fcmToken) {
    const apiUrl = `${API_BASE_URL}/auth/login`

    try {
      const header = this.headers
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: header,
        body: JSON.stringify({
          email,
          password,
          fcmToken,
        }),
      })

      if (!response.ok) {
        throw new Error("Authentication failed")
      }

      const rawData = await response.json()
      return { kind: "ok", data: rawData }
    } catch (error) {
      if (__DEV__) {
        console.tron.error(`Error: ${error.message}`)
      }
      return { kind: "bad-data" }
    }
  }

  async getRegisterData(name, email, password, phoneNumber, whatsappNumber, role) {
    const apiUrl = `${API_BASE_URL}/auth/register`

    try {
      const header = this.headers
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: header,
        body: JSON.stringify({
          name,
          email,
          password,
          phoneNumber,
          whatsappNumber,
          role,
        }),
      })

      if (!response.ok) {
        throw new Error("Registration failed")
      }

      const rawData = await response.json()
      return { kind: "ok", data: rawData }
    } catch (error) {
      if (__DEV__) {
        console.tron.error(`Error: ${error.message}`)
      }
      return { kind: "bad-data" }
    }
  }

  async getSwipeToSell(quantity, price, creditDays, expirationMinutes, yarnId, token) {
    const apiUrl = `${API_BASE_URL}/orders/orderplace`
    this.headers.set("Authorization", `Bearer ${token}`)
    const header = this.headers

    const requestBody = {
      quantity,
      price,
      credit_days: creditDays,
      expirationMinutes,
      yarn_id: yarnId,
    }

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: header,
        body: JSON.stringify(requestBody),
      })

      if (!response.ok) {
        const errorResponse = await response.text() // Read the error message
        throw new Error(`${errorResponse}`)
      }

      const rawData = await response.json()
      return { kind: "ok", data: rawData }
    } catch (error) {
      console.error("Error:", error.message) // Log the error message
      return { kind: "bad-data", data: error.message }
    }
  }

  async getSwipeToBuy(quantity, price, creditDays, expirationMinutes, yarnId, brokerId, token) {
    const apiUrl = `${API_BASE_URL}/orders/orderplace`
    this.headers.set("Authorization", `Bearer ${token}`)
    const header = this.headers
    const requestBody = {
      quantity,
      price,
      credit_days: creditDays,
      expirationMinutes,
      yarn_id: yarnId,
      broker_id: brokerId,
    }

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: header,
        body: JSON.stringify(requestBody),
      })

      if (!response.ok) {
        const errorResponse = await response.text() // Read the error message
        throw new Error(`${errorResponse}`)
      }

      const rawData = await response.json()
      return { kind: "ok", data: rawData }
    } catch (error) {
      console.error("Error:", error.message) // Log the error message
      return { kind: "bad-data", data: error.message }
    }
  }

  async getSwipeToBuyModify(
    quantity = undefined,
    price = undefined,
    creditDays = undefined,
    expirationMinutes = undefined,
    brokerId = undefined,
    token,
    orderId,
  ) {
    const apiUrl = `${API_BASE_URL}/orders`
    this.headers.set("Authorization", `Bearer ${token}`)
    const header = this.headers
    const requestBody = {
      quantity,
      price,
      credit_days: creditDays,
      expirationMinutes,
      broker_id: brokerId,
    }

    try {
      const response = await fetch(`${apiUrl}/${orderId}`, {
        method: "PUT",
        headers: header,
        body: JSON.stringify(requestBody),
      })
      if (!response.ok) {
        const errorResponse = await response.text() // Read the error message
        throw new Error(`${errorResponse}`)
      }

      const rawData = await response.json()
      return { kind: "ok", data: rawData }
    } catch (error) {
      console.error("Error:", error.message)
      return { kind: "bad-data", data: error.message }
    }
  }

  async getSwipeToSellModify(
    quantity = undefined,
    price = undefined,
    creditDays = undefined,
    expirationMinutes = undefined,
    token,
    orderId,
  ) {
    const Url = `${API_BASE_URL}/orders/${orderId}`
    this.headers.set("Authorization", `Bearer ${token}`)
    const header = this.headers
    const requestBody = {
      quantity,
      price,
      credit_days: creditDays,
      expirationMinutes,
    }

    try {
      const response = await fetch(Url, {
        method: "PUT",
        headers: header,
        body: JSON.stringify(requestBody),
      })

      if (!response.ok) {
        const errorResponse = await response.text() // Read the error message
        throw new Error(`${errorResponse}`)
      }

      const rawData = await response.json()
      return { kind: "ok", data: rawData }
    } catch (error) {
      console.error("Error:", error.message) // Log the error message
      return { kind: "bad-data", data: error.message }
    }
  }

  async getExecutedOrders(
    apiUrl: string,
    apiToken: string,
    onDataExecutedReceived: (data: object) => void,
    onError: (error: any) => void,
  ): Promise<void> {
    let source: SSE | null = null

    try {
      source = new SSE(apiUrl, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      })

      source.addEventListener("message", (e) => {
        try {
          const data: any = JSON.parse(e.data)
          onDataExecutedReceived(data)
        } catch (error) {
          console.error("Error parsing SSE data: ", error)
          onError(error)
        }
      })

      source.addEventListener("error", (e) => {
        console.error("Error with SSE connection: ", e)
        source?.close() // Close the connection in case of error
      })
    } catch (error) {
      console.error("Error establishing SSE connection: ", error)
    }
  }

  async postProfileData(name, email, phoneNumber, whatsappNumber, id, token) {
    const apiUrl = `${API_BASE_URL}/users/${id}`
    this.headers.set("Authorization", `Bearer ${token}`)
    const header = this.headers
    const requestBody = {
      name,
      email,
      phoneNumber,
      whatsappNumber,
    }

    try {
      const response = await fetch(apiUrl, {
        method: "PATCH",
        headers: header,
        body: JSON.stringify(requestBody),
      })

      if (!response.ok) {
        throw new Error("Profile update failed")
      }

      const rawData = await response.json()
      return { kind: "ok", data: rawData }
    } catch (error) {
      if (__DEV__) {
        console.tron.error(`Error: ${error.message}`)
      }
      return { kind: "bad-data" }
    }
  }

  async postLogout(refreshToken) {
    const apiUrl = `${API_BASE_URL}/auth/logout`
    this.headers.set("Authorization", `Bearer ${refreshToken}`)
    const header = this.headers
    const requestBody = {
      refreshToken,
    }

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: header,
        body: JSON.stringify(requestBody),
      })

      if (!response.ok) {
        throw new Error("Logout failed")
      }

      const rawData = await response.json()
      return { kind: "ok", data: rawData }
    } catch (error) {
      if (__DEV__) {
        console.tron.error(`Error: ${error.message}`)
      }
      return { kind: "bad-data" }
    }
  }

  async getBuyerData(token, id) {
    try {
      const Url = `${API_BASE_URL}/users/${id}/blocklist`
      this.headers.set("Authorization", `Bearer ${token}`)
      const header = this.headers
      const response = await fetch(Url, {
        method: "GET",
        headers: header,
      })

      if (!response.ok) {
        if(response.status === 401){
          return { kind : "Unauthorized"}
        }
        console.error(`Request failed with status: ${response.status}`)
        return { kind: "bad-data" }
      }

      const rawData = await response.json()
      return { kind: "ok", data: rawData }
    } catch (error) {
      console.error(`Error: ${error.message}`)
      return { kind: "bad-data" }
    }
  }


  async postFirmData(
    firmName,
    ownerName,
    address,
    gstNo,
    panNo,
    bankName,
    bankAccountNo,
    ifscCode,
    token,
    id,
  ) {
    const apiUrl = `${API_BASE_URL}/users/${id}/firm`
    this.headers.set("Authorization", `Bearer ${token}`)
    const header = this.headers

    const requestBody = {
      firmName,
      ownerName,
      address,
      gstNo,
      panNo,
      bankName,
      bankAccountNo,
      ifscCode,
    }

    try {
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: header,
        body: JSON.stringify(requestBody),
      })

      if (!response.ok) {
        throw new Error("Firm data update failed")
      }

      const rawData = await response.json()
      return { kind: "ok", data: rawData }
    } catch (error) {
      if (__DEV__) {
        console.tron.error(`Error: ${error.message}`)
      }
      return { kind: "bad-data" }
    }
  }

  
  async postBlockUnblockUser(
    token: string,
    blocklistId: string,
    status: string
  ): Promise<{ kind: "ok"; data: object } | GeneralApiProblem> {
    const apiUrl = `${API_BASE_URL}/users/blocklist/${blocklistId}?status=${status}`
    this.headers.set("Authorization", `Bearer ${token}`)
    const header = this.headers
  
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: header,
      })
  
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`)
      }
  
      const rawData = await response.json()
      return { kind: "ok", data: rawData }
    } catch (error) {
      console.error(`Error: ${error.message}`)
      return { kind: "bad-data" }
    }
  }

  async getAccountData(): Promise<{ kind: "ok"; data: object } | GeneralApiProblem> {
    const response = {
      data: accoutData,
      ok: true,
    }

    if (!response.ok) {
      const problem = getGeneralApiProblem(response.data)
      if (problem) return problem
    }

    try {
      const rawData = response.data

      return { kind: "ok", data: rawData }
    } catch (e) {
      if (__DEV__) {
        console.tron.error(`Bad data: ${e.message}\n${response.data}`, e.stack)
      }
      return { kind: "bad-data" }
    }
  }

  async getOtersData(): Promise<{ kind: "ok"; data: object } | GeneralApiProblem> {
    const response = {
      data: othersData,
      ok: true,
    }

    if (!response.ok) {
      const problem = getGeneralApiProblem(response.data)
      if (problem) return problem
    }

    try {
      const rawData = response.data

      return { kind: "ok", data: rawData }
    } catch (e) {
      if (__DEV__) {
        console.tron.error(`Bad data: ${e.message}\n${response.data}`, e.stack)
      }
      return { kind: "bad-data" }
    }
  }
}

export const api = new Api()
