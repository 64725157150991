import React, { useState, useEffect } from "react"
import {
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  TouchableWithoutFeedback,
  RefreshControl,
  FlatList,
  ActivityIndicator,
} from "react-native"
import { observer } from "mobx-react-lite"
import { colors, fontSize, spacing, typography } from "app/theme"
import { Text } from "app/components/Text"
import { SearchBar } from "./WatchListSearchBar"
import { FILTER_TYPE, FilterResponseParams } from "app/types"
import { setTime, setDate } from "../../services/constants/constant.js"
import { useStores } from "app/models"
export interface DeleteOrderProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  openOrderData: any[]
  deletedOrderData: any[]
  openOrder: any[]
}

/**
 * Describe your component here
 */
export const DeleteOrder = observer(function DeleteOrder(props: DeleteOrderProps) {
  const { style, deletedOrderData, getDeleteOrder } = props
  const $styles = [$container, style]
  const [originalDeleteOrderData, setOriginalDeleteOrderData] = useState([])
  const [deleteOrderData, setDeleteOrderData] = useState(originalDeleteOrderData)
  const [refreshing, setRefreshing] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const {
    authenticationStore: { role },
  } = useStores()
  const onRefresh = React.useCallback(() => {
    setRefreshing(true)
    setTimeout(() => {
      setRefreshing(false)
    }, 2000)
  }, [])
  useEffect(() => {
    setOriginalDeleteOrderData(deletedOrderData)
    setDeleteOrderData(deletedOrderData)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [deletedOrderData])
  const searchBarCallback = (resp: Array<FilterResponseParams>) => {
    let clonedOriginalFilteredData = JSON.parse(JSON.stringify(originalDeleteOrderData))
    // eslint-disable-next-line array-callback-return
    resp.map((response) => {
      switch (response.filterType) {
        case FILTER_TYPE.SORT_ALPHABETICALLY: {
          if (response.data) {
            if (!deleteOrderData[0]) {
              clonedOriginalFilteredData = clonedOriginalFilteredData.sort((a, b) =>
                a.mill.localeCompare(b.mill),
              )
            } else {
              clonedOriginalFilteredData = clonedOriginalFilteredData.sort((a, b) =>
                a.mill.localeCompare(b.mill),
              )
            }
          }
          break
        }

        case FILTER_TYPE.COUNT: {
          if (response.data) {
            if (!response.data[0]) {
              break
            }
            clonedOriginalFilteredData = clonedOriginalFilteredData.filter((e) => {
              return e.count >= response.data[0] && e.count <= response.data[1]
            })
          }
          break
        }

        case FILTER_TYPE.YARN_TYPE: {
          if (response.data) {
            if (!response.data[0]) {
              break
            }
            clonedOriginalFilteredData = clonedOriginalFilteredData.filter((e) =>
              response.data.includes(e.variety),
            )
          }
          break
        }

        case FILTER_TYPE.SORRT_COUNT: {
          if (response.data) {
            if (!deleteOrderData[0]) {
              clonedOriginalFilteredData = clonedOriginalFilteredData.sort(
                (a, b) => a?.count - b?.count,
              )
            } else {
              clonedOriginalFilteredData = clonedOriginalFilteredData.sort(
                (a, b) => a?.count - b?.count,
              )
            }
          }
          break
        }

        case FILTER_TYPE.CLEAR: {
          clonedOriginalFilteredData = originalDeleteOrderData
          break
        }
        default: {
          break
        }
      }
      setDeleteOrderData(clonedOriginalFilteredData)
    })
  }

  return (
    <View style={$backContainer}>
      <View style={style}>
        <View style={$searchBar}>
          <SearchBar callback={searchBarCallback} data={originalDeleteOrderData} />
        </View>
        <View style={$topContainer} />
        {loading ? (
          <ActivityIndicator style={$activityIndicator} size="large" />
        ) : deleteOrderData?.length > 0 ? (
          <View style={$bottomContainer}>
            <FlatList
              data={deleteOrderData}
              keyExtractor={(item) => item.id}
              renderItem={({ item }) => (
                <TouchableWithoutFeedback>
                  <View style={$touchContainer}>
                    <View style={$row}>
                      <Text style={$text}>
                        <Text style={$textOrders} tx="ordersScreen.orderNo" /> {item.id}
                      </Text>
                      <Text style={$text}>
                        {setTime(item.created_at)} {setDate(item.created_at)}
                      </Text>
                    </View>
                    <View style={$row}>
                      <Text style={$subTextRate}>{item.quality_name}</Text>
                      <Text style={$textSuccess}>
                        ₹{item.price} ({item.quantity} boxes)
                      </Text>
                    </View>
                    <View style={$row}>
                      {role === "buyer" ? (
                        <Text style={$brokerName}>{item.broker_name}</Text>
                      ) : (
                        <Text style={$brokerName}> </Text>
                      )}
                    </View>
                    <View style={$line} />
                  </View>
                </TouchableWithoutFeedback>
              )}
              refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
            />
          </View>
        ) : (
          <View style={$noDataContainer}>
            <Text style={$noDataText} tx="AlertsScreen.noData" />
          </View>
        )}
      </View>
    </View>
  )
})
const $backContainer: ViewStyle = {
  backgroundColor: colors.palette.neutral100,
  height: "100%",
}
const $noDataContainer: ViewStyle = {
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
}

const $noDataText: TextStyle = {
  fontSize: fontSize.h4,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.bold,
  opacity: 0.2,
  zIndex: 4,
}
const $container: ViewStyle = {
  justifyContent: "center",
  backgroundColor: colors.palette.neutral100,
}
const $activityIndicator: ViewStyle = {
  position: "relative",
  top: spacing.md,
}
const $touchContainer: ViewStyle = {
  height: 67,
  backgroundColor: colors.palette.neutral100,
}
const $searchBar: ViewStyle = {
  zIndex: 1,
}
const $topContainer: ViewStyle = {
  height: spacing.llg,
  backgroundColor: colors.palette.gray100,
  zIndex: -1,
  position: "relative",
  bottom: spacing.lg + spacing.lg + spacing.xxs,
}
const $bottomContainer: ViewStyle = {
  backgroundColor: colors.palette.neutral100,
  position: "relative",
  bottom: spacing.lg + spacing.xs,
}
const $brokerName: TextStyle = {
  fontFamily: typography.fonts.inter.normal,
  fontSize: fontSize.p4,
  color: colors.palette.gray300,
  paddingHorizontal: spacing.md,
  position: "relative",
  bottom: spacing.xs,
}
const $row: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: colors.palette.neutral100,
  width: "100%",
}
const $textSuccess: TextStyle = {
  fontSize: fontSize.p3,
  color: colors.palette.highlight100,
  fontFamily: typography.fonts.inter.normal,
  paddingHorizontal: spacing.md,
}
const $text: TextStyle = {
  fontFamily: typography.fonts.inter.normal,
  fontSize: fontSize.p5,
  color: colors.palette.gray300,
  paddingHorizontal: spacing.md,
  position: "relative",
  top: spacing.xs,
}
const $textOrders: TextStyle = {
  fontFamily: typography.fonts.inter.normal,
  fontSize: fontSize.p5,
  color: colors.palette.gray300,
  marginRight: spacing.xxxs,
}
const $line: ViewStyle = {
  height: 1,
  width: "100%",
  backgroundColor: colors.palette.gray100,
  position: "relative",
  bottom: spacing.xxxs + spacing.xxs,
}

const $subTextRate: TextStyle = {
  fontSize: fontSize.p3,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.medium,
  paddingHorizontal: spacing.md,
}
