import {
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  TouchableOpacity,
  Dimensions,
  ScrollView,
  StatusBar,
} from "react-native"
import { Text } from "../Text"
import { colors, fontSize, spacing, typography } from "app/theme"
import { Icon } from "../Icon"
import RBSheet from "react-native-raw-bottom-sheet"
import React, { useState, forwardRef, useImperativeHandle, ForwardedRef, useEffect } from "react"
import { FILTER_TYPE, FilterResponseParams } from "app/types"
import { RangeSlider } from "./RangeSlider"

export interface FilterListProps {
  callback(callBackResp: FilterResponseParams[]): unknown
  style?: StyleProp<ViewStyle>
  data: any[]
}

export const FilterList = forwardRef(function FilterList(
  props: FilterListProps,
  ref: ForwardedRef<unknown>,
) {
  useImperativeHandle(ref, () => {
    return {
      openBottom: openBottomSheet,
    }
  })

  const { style, data } = props
  const $styles: StyleProp<ViewStyle>[] = [$container, style]
  const bottomSheetRef = React.useRef<RBSheet>(null)
  const [selectedFilterbuttons, setSelectedFilterbuttons] = useState<string[]>([])
  const [count, setCount] = useState([])
  const [isAlpabetSorted, setIsAlhabetSorted] = useState<boolean>(false)
  const [isCountSorted, setIsCountSorted] = useState<boolean>(false)
  const [clearAll, setclearAll] = useState<boolean>(false)
  const filterbottomSheetHeight = Dimensions.get("window").height / 2
  const buttonLabels = [...new Set(data?.map((item) => item.variety))]
  const openBottomSheet = () => {
    bottomSheetRef.current?.open()
  }

  useEffect(() => {
    let callBackResp: FilterResponseParams[] = []
    if (selectedFilterbuttons.length > 0) {
      callBackResp.push({
        data: selectedFilterbuttons,
        filterType: FILTER_TYPE.YARN_TYPE,
      })
    }
    if (count) {
      callBackResp.push({
        data: count,
        filterType: FILTER_TYPE.COUNT,
      })
    }
    if (isAlpabetSorted) {
      callBackResp.push({
        data: isAlpabetSorted,
        filterType: FILTER_TYPE.SORT_ALPHABETICALLY,
      })
    }
    if (isCountSorted) {
      callBackResp.push({
        data: isCountSorted,
        filterType: FILTER_TYPE.SORRT_COUNT,
      })
    }
    if (clearAll) {
      callBackResp = []
      callBackResp.push({
        filterType: FILTER_TYPE.CLEAR,
      })
    }
    props.callback(callBackResp)
  }, [selectedFilterbuttons, isAlpabetSorted, count, isCountSorted, clearAll])

  const handleButtonClick = async (label: string) => {
    if (selectedFilterbuttons.includes(label)) {
      setSelectedFilterbuttons(selectedFilterbuttons.filter((e) => e !== label))
    } else {
      setSelectedFilterbuttons([...selectedFilterbuttons, label])
    }
  }
  const handleCountData = (value) => {
    setCount(value)
  }
  const sortedAlphabet = () => {
    const updatedAlphabetValue = !isAlpabetSorted
    setIsAlhabetSorted(updatedAlphabetValue)
  }
  const sortDataNumber = () => {
    const updatedCountValue = !isCountSorted
    setIsCountSorted(updatedCountValue)
  }

  const clearAllData = () => {
    bottomSheetRef.current.close()
    setSelectedFilterbuttons([])
    setIsAlhabetSorted(false)
    setIsCountSorted(false)
    setclearAll(false)
    setCount([1, 100])
  }

  return (
    <View style={$styles}>
      <StatusBar backgroundColor={colors.palette.gray100} />
      <RBSheet
        ref={bottomSheetRef}
        closeOnDragDown={true}
        closeOnPressMask={true}
        openDuration={300}
        height={filterbottomSheetHeight}
        customStyles={{
          container: $bottomSheetContainer,
          wrapper: $bottomSheetWrapper,
          draggableIcon: $draggableIcon,
        }}
      >
        <View style={$bottomContainer}>
          <View style={$headerContainer}>
            <Text style={$leftText} tx="filterList.filter"></Text>
            <TouchableOpacity onPress={clearAllData}>
              <Text style={$rightText} tx="filterList.clear"></Text>
            </TouchableOpacity>
          </View>
          <Text style={$typetext} tx="filterList.type"></Text>
          <View style={$buttonContainer}>
            <ScrollView showsHorizontalScrollIndicator={false} horizontal={true}>
              {buttonLabels?.map((label, index) => (
                <TouchableOpacity
                  style={[$buttonBox, selectedFilterbuttons.includes(label) && $activenutton]}
                  key={index}
                  onPress={() => handleButtonClick(label)}
                >
                  <Text
                    text={label}
                    style={[
                      $buttontext,
                      selectedFilterbuttons.includes(label) && $activebuttontext,
                    ]}
                  ></Text>
                </TouchableOpacity>
              ))}
            </ScrollView>
          </View>
          <View>
            <Text style={$count} tx="filterList.counts"></Text>
            <RangeSlider sendCountData={handleCountData}></RangeSlider>
          </View>
          <View>
            <Text style={$sort} tx="filterList.sort"></Text>
            <TouchableOpacity onPress={sortedAlphabet}>
              <View style={$sortcontainer}>
                <Icon
                  icon={"alphabeticallysort"}
                  color={isAlpabetSorted ? colors.palette.highlight100 : colors.palette.neutral900}
                  size={fontSize.p1}
                />
                <Text
                  style={isAlpabetSorted ? $ActiveSortText : $sortText}
                  tx="filterList.sortAlphabetically"
                ></Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={sortDataNumber}>
              <View style={$sortcontainer}>
                <Icon
                  icon={"sort"}
                  color={isCountSorted ? colors.palette.highlight100 : colors.palette.neutral900}
                  size={fontSize.p1}
                />
                <Text
                  style={isCountSorted ? $ActiveSortText : $sortText}
                  tx="filterList.sortbycounts"
                ></Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </RBSheet>
    </View>
  )
})

const $container: ViewStyle = {
  marginHorizontal: spacing.md,
  justifyContent: "center",
  alignItems: "center",
}

const $bottomContainer: ViewStyle = {
  marginHorizontal: spacing.md,
}

const $bottomSheetContainer: ViewStyle = {
  backgroundColor: colors.palette.neutral100,
  borderTopLeftRadius: fontSize.p5,
  borderTopRightRadius: fontSize.p5,
}
const $bottomSheetWrapper: ViewStyle = {
  backgroundColor: colors.palette.semitransparent,
}
const $draggableIcon: ViewStyle = {
  backgroundColor: colors.palette.gray200,
}

const $headerContainer: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}

const $leftText: TextStyle = {
  fontSize: fontSize.h4,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.semiBold,
}

const $rightText: TextStyle = {
  fontSize: fontSize.p3,
  color: colors.palette.highlight100,
}

const $typetext: TextStyle = {
  fontSize: fontSize.p3,
  marginTop: spacing.ssm,
}

const $count: TextStyle = {
  fontSize: fontSize.p3,
  marginTop: spacing.ssm,
}

const $buttonContainer: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-around",
  marginTop: spacing.ssm,
}

const $buttonBox: ViewStyle = {
  height: 24,
  width: 80,
  alignItems: "center",
  borderWidth: 1,
  borderRadius: spacing.xxs,
  borderColor: colors.palette.neutral700,
  marginRight: spacing.smm,
}
const $activenutton: ViewStyle = {
  height: 24,
  width: 80,
  alignItems: "center",
  borderWidth: 1,
  borderRadius: spacing.xxs,
  borderColor: colors.palette.highlight100,
  marginRight: spacing.smm,
}

const $sort: TextStyle = {
  fontSize: fontSize.h4,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.semiBold,
  marginTop: spacing.smm,
}

const $sortcontainer: ViewStyle = {
  flexDirection: "row",
  marginTop: spacing.ssm,
}

const $sortText: TextStyle = {
  marginLeft: spacing.md,
  fontFamily: typography.fonts.inter.normal,
  fontSize: fontSize.p3,
}
const $ActiveSortText: TextStyle = {
  marginLeft: spacing.md,
  fontFamily: typography.fonts.inter.normal,
  fontSize: fontSize.p3,
  color: colors.palette.highlight100,
}

const $buttontext: TextStyle = {
  color: colors.palette.neutral700,
}

const $activebuttontext: TextStyle = {
  color: colors.palette.highlight100,
}
