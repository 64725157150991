import React, { useState, useRef } from "react"
import { StyleProp, TextStyle, View, ViewStyle, PanResponder, Animated } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, fontSize, spacing, typography } from "app/theme"
import { Text } from "app/components/Text"
import { Icon } from "./Icon"

export interface SwipeToModifyButtonProps {
  style?: StyleProp<ViewStyle>
  onButtonActivate: () => void
  disabled?: boolean
}

export const SwipeToModifyButton = observer(function SwipeToModifyButton(
  props: SwipeToModifyButtonProps,
) {
  const { style, onButtonModify, disabled } = props
  const $styles = [$container, style]
  const start = 0
  const end = 195
  const middle = 110
  const lessOpacity = 0.25
  const moreOpacity = 1.0

  type AnimatedValue = Animated.ValueXY
  type _toValue = { x: number; y: number }
  const [isButtonActive, setIsButtonActive] = useState(false)
  const pan = useRef(new Animated.ValueXY()).current

  const _panResponder = PanResponder.create({
    onStartShouldSetPanResponder: () => !disabled, // Check the disabled prop
    onPanResponderMove: (evt, gesture) => {
      if (!disabled && gesture.dx >= start && gesture.dx <= end) {
        pan.setValue({ x: gesture.dx, y: 0 })
      }
    },
    onPanResponderRelease: (e, gesture) => {
      if (!disabled) {
        if (gesture.dx > middle) {
          setIsButtonActive(true)
          onButtonModify()
          Animated.spring<AnimatedValue>(pan, {
            toValue: { x: end - 1, y: start },
            useNativeDriver: true,
          }).start()
        } else {
          Animated.spring<AnimatedValue>(pan, {
            toValue: { x: start, y: start },
            useNativeDriver: true,
          }).start()
        }
      }
    },
  })

  const AnimatedView = [{ transform: pan.getTranslateTransform() }]
  return (
    <View style={$styles}>
      <View
        style={[
          isButtonActive ? $swipeChangeConstiner : $swipeConstiner,
          { opacity: disabled ? lessOpacity : moreOpacity },
        ]}
      >
        <Animated.View style={AnimatedView} {..._panResponder.panHandlers}>
          <View style={$middleContainer}>
            <View style={$circle}>
              <Icon icon="arrowForward" size={fontSize.p2} color={colors.palette.highlight100} />
            </View>
          </View>
        </Animated.View>
      </View>
    </View>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
}

const $text: TextStyle = {
  color: colors.palette.neutral100,
  fontSize: fontSize.p4,
  fontFamily: typography.fonts.inter.normal,
  position: "relative",
  top: spacing.mmd + spacing.mmd + spacing.xxxs,
  left: spacing.xxxxl + spacing.xxxs,
  zIndex: 1,
}
const $buttonText: TextStyle = {
  color: colors.palette.neutral100,
  fontSize: fontSize.p4,
  fontFamily: typography.fonts.inter.normal,
}
const $swipeConstiner: ViewStyle = {
  width: 252,
  height: 59,
  borderRadius: spacing.mmd + spacing.ssm,
  backgroundColor: colors.palette.highlight100,
}

const $swipeChangeConstiner: ViewStyle = {
  width: 252,
  height: 59,
  borderRadius: spacing.mmd + spacing.ssm,
  backgroundColor: colors.palette.highlight100,
}
const $middleContainer: ViewStyle = {
  width: 55,
  height: 55,
  borderRadius: spacing.mmd + spacing.mmd + spacing.ssm,
  backgroundColor: "white",
  marginBottom: spacing.mmd,
  position: "relative",
  top: spacing.xxxs,
  left: spacing.xxxs,
}
const $circle: ViewStyle = {
  position: "relative",
  top: spacing.mmd,
  left: spacing.mdd,
}
