import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle, View, TextStyle } from "react-native"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { AppStackScreenProps } from "app/navigators"
import { Icon, Screen, Text } from "app/components"
import { termsOfUse } from "../services/terms-of-use.js"

import { colors, fontSize, spacing, typography } from "app/theme"


// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "app/models"

interface LicensesScreenProps extends NativeStackScreenProps<AppStackScreenProps<"Licenses">> {}

export const LicensesScreen: FC<LicensesScreenProps> = observer(function LicensesScreen(_props) {
  const { navigation} = _props
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()

  // Pull in navigation via hook
  // const navigation = useNavigation()
  const firstTerm = termsOfUse[0].content
  return (
    <Screen safeAreaEdges={["top"]} style={$root} preset="scroll">
      <View style={$LicensesContainer}>
        <View style={$rowcontainer}>
          <Icon icon="back" size={24} color={colors.text} onPress={()=>navigation.goBack()}/>
          <Text style={$text} tx="Licenses.title"></Text>
         </View>
      </View>
      <Text>{firstTerm}</Text>
    </Screen>
  )
})

const $root: ViewStyle = {
  flex: 1,
  padding: spacing.md,
  paddingBottom: spacing.lg,
}
const $LicensesContainer: ViewStyle = {
  flexDirection: "row",
  marginTop: spacing.xll,
  
}
const $rowcontainer: ViewStyle = {
  flexDirection: "row",
  marginHorizontal: spacing.smm,
}
const $text: TextStyle = {
  marginBottom: spacing.ssm,
  fontSize: fontSize.h2,
  marginLeft: spacing.md,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.bold,
}
