import React, { useEffect, useState } from "react"
import { StyleProp, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, fontSize, spacing, typography } from "app/theme"
import { Text } from "app/components/Text"
import { useStores } from "app/models"
import { api } from "app/services/api"
import { Icon } from "../Icon"

export interface ProfileProps {
  navigation: any
  style?: StyleProp<ViewStyle>
}

/**
 * Describe your component here
 */
export const Profile = observer(function Profile(props: ProfileProps) {
  const { style, navigation } = props
  const $styles = [$container, style]
  const [profileData, setProfileData] = useState([])
  const {
    authenticationStore: {
      authToken,
      id,
      setName,
      setAuthEmail,
      setphoneNumber,
      setWhatsAppNumber,
      setapprovalStatus,
      authRefreshToken,
      logout
    },
  } = useStores()

  const apiToken = authToken
  const initials = profileData.name
    ? profileData.name
        .split(" ")
        .map((part) => part.charAt(0).toUpperCase())
        .join("")
    : null
  const username = profileData?.name?.slice(0, 3) + profileData.phoneNumber?.slice(-4)
  const ManagePassword = () => {
    navigation.navigate("ManagePassword")
  }
  const ManageAccount = () => {
    navigation.navigate("ManageAccount")
  }

  async function fetchProfileData() {
    try {
      const response = await api.getProfileData(apiToken, id)
      if (response.kind === "Unauthorized") {
        navigation.navigate("Account")
        await api.postLogout(authRefreshToken)
        logout()
      }
      const listData: any = response.data
      if (listData.code === 200) {
        setProfileData(listData.data)
        setName(listData.data.name)
        setAuthEmail(listData.data.email)
        setphoneNumber(listData.data.phoneNumber)
        setWhatsAppNumber(listData.data.whatsappNumber)
        setapprovalStatus(listData.data.approvalStatus)
      } else {
        alert("Profile Not Fetched")
      }
    } catch (error) {}
  }
  useEffect(() => {
    fetchProfileData()
  }, [])
  return (
    <View style={$styles}>
      <View style={$ProfileContainer}>
        <View style={$rowcontainer}>
          <Icon icon="back" size={24} color={colors.text} onPress={()=>navigation.goBack()}/>
          <Text style={$Profiletext} tx="Profile.profile"></Text>
        </View>
      </View>
      <View style={$usernameContainer}>
        <View style={$usercontainer}>
          <View>
            <Text style={$nameText}>{profileData.name}</Text>
            <Text style={$usernameText}>{username}</Text>
          </View>
        </View>
        <View style={$circlecontainer}>
          <View style={$circle}>
            <Text style={$circleText}>{initials}</Text>
          </View>
        </View>
      </View>
      <View style={$border}></View>
      <View style={$passwordcontainer}>
        <Text style={$passwordText} tx="Profile.password"></Text>
        <TouchableOpacity onPress={() => ManagePassword()}>
          <Text style={$manageText} tx="Profile.managepassword"></Text>
        </TouchableOpacity>
      </View>
      <View style={$border}></View>
      <View style={$infocontainer}>
        <Text style={$profiletag} tx="Profile.emailLabel"></Text>
        <Text style={$profileinfo}>{profileData.email}</Text>
      </View>
      <View style={$infocontainer}>
        <Text style={$profiletag} tx="Profile.phoneLabel"></Text>
        <Text style={$profileinfo}>{profileData.phoneNumber}</Text>
      </View>
      <View style={$infocontainer}>
        <Text style={$profiletag} tx="Profile.whatsappLabel"></Text>
        <Text style={$profileinfo}>{profileData.whatsappNumber}</Text>
      </View>
      <View style={$infocontainer}>
        <Text style={$profiletag} tx="Profile.statusLabel"></Text>
        <Text style={$profileinfo}>{profileData.approvalStatus}</Text>
      </View>
      <View style={$infocontainer}>
        <Text style={$profiletag} tx="Profile.tagLabel"></Text>
        <Text style={$profileinfo}>{profileData.role}</Text>
      </View>
      <View style={$manageContainer}>
        <TouchableOpacity onPress={() => ManageAccount()}>
          <Text style={$manageText} tx="Profile.manageAccount"></Text>
        </TouchableOpacity>
      </View>
    </View>
  )
})

const $container: ViewStyle = {}
const $ProfileContainer: ViewStyle = {
  flexDirection: "row",
  marginTop: spacing.xll,
}
const $rowcontainer: ViewStyle = {
  flexDirection: "row",
  marginHorizontal: spacing.md,
}
const $Profiletext: TextStyle = {
  fontSize: fontSize.h2,
  marginLeft: spacing.md,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.bold,
}
const $usernameContainer: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
}
const $usercontainer: ViewStyle = {
  marginHorizontal: spacing.md,
}
const $nameText: TextStyle = {
  fontSize: fontSize.h4,
  marginTop: spacing.xxl,
  fontFamily: typography.fonts.inter.normal,
}
const $usernameText: TextStyle = {
  fontSize: fontSize.p4,
  color: colors.palette.gray300,
  marginTop: spacing.xxs,
  marginBottom: spacing.xl,
}
const $circlecontainer: ViewStyle = {
  borderColor: colors.palette.highlight100,
  borderWidth: 1,
  padding: spacing.llg,
  borderRadius: spacing.xxl,
  width: spacing.xxxl,
  height: spacing.xxxl,
  marginTop: spacing.xll,
  marginRight: spacing.md,
}
const $circle: ViewStyle = {
  flexDirection: "row",
  height: 64,
  width: 64,
  alignContent: "center",
  alignItems: "center",
}
const $circleText: TextStyle = {
  color: colors.palette.gray100,
  fontSize: spacing.lg,
  zIndex: 1,
  position: "relative",
  bottom: spacing.llg,
  right: spacing.smm,
}
const $border: ViewStyle = {
  borderBottomWidth: 1,
  paddingLeft: spacing.lg,
  paddingRight: spacing.lg,
  borderColor: colors.palette.gray100,
}
const $passwordcontainer: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  paddingTop: spacing.lg,
  paddingBottom: spacing.lg,
  marginHorizontal: spacing.md,
}
const $passwordText: TextStyle = {
  fontSize: fontSize.p3,
  color: colors.palette.gray300,
}
const $manageText: TextStyle = {
  fontSize: fontSize.p3,
  color: colors.palette.highlight100,
}
const $infocontainer: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  paddingTop: spacing.smm,
  marginHorizontal: spacing.md,
}
const $profiletag: TextStyle = {
  fontSize: fontSize.p3,
  color: colors.palette.gray300,
}
const $profileinfo: TextStyle = {
  fontSize: fontSize.p3,
  color: colors.palette.neutral700,
}
const $manageContainer: ViewStyle = {
  paddingTop: spacing.smm,
  marginHorizontal: spacing.md,
}
