import { OpenOrderItem } from "./models/OpenOrdersModel"

export function getOpenOrdersData(data) {
  return data.map((item) => {
    return new OpenOrderItem({
      brokerId: item.broker_id,
      brokername: item.broker_name,
      createdAt: item.created_at,
      creditDays: item.credit_days,
      expireAt: item.expire_at,
      OOI: item.id,
      OOP: item.price,
      name: item.quality_name,
      OOQ: item.quantity,
      status: item.type,
      type: item.type,
      unfilledQuantity: item.unfilled_quantity,
      userId: item.user_id,
      yarnId: item.yarn_id,
      count: item.count,
      mill: item.mill,
      variety: item.variety,
      weightPerBag: item.weight_per_bag,
      YEP: item.YEP,
      YEQ: item.YEQ,
      YET: item.YET,
      TEP: item.TEP,
      TEQ: item.TEQ,
      TET: item.TET,
      deleteId: item.deleteId,
      refValue: item.ref_value,
      groupNo: item.groupNo,
    })
  })
}
