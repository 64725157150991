import * as React from "react"
import {
  ImageStyle,
  StyleProp,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
  Linking,
} from "react-native"
import { observer } from "mobx-react-lite"
import Communications from "react-native-communications"
import { colors, fontSize, spacing, typography } from "app/theme"
import { Text } from "app/components/Text"
import { Icon } from "../Icon"
import { contacts } from "../../services/constants/constant.js"
export interface ContactProps {
  style?: StyleProp<ViewStyle>
  navigation: any
}

export const Contact = observer(function Contact(props: ContactProps) {
  const { style, navigation } = props
  const $styles = [$container, style]
  function contact1() {
    Communications.phonecall(contacts.contact1, true)
  }

  function whatsapp() {
    const url = "whatsapp://send?&phone=91" + contacts.whatsappNo
    Linking.openURL(url)
  }
  return (
    <View style={$styles}>
      <View style={$contactContainer}>
      <View style={$rowcontainer}>
          <Icon icon="back" size={24} color={colors.text} onPress={()=>navigation.goBack()}/>
          <Text style={$text} tx="Contact.contactText"></Text>
        </View>
      </View>
      <View>
        <Text style={$tag} tx="Contact.address"></Text>
        <Text style={$tagInfo} tx="Contact.addressInfo"></Text>
        <Text style={$tag} tx="Contact.contact"></Text>
        <TouchableOpacity activeOpacity={0.7} style={$contactRowContainer} onPress={contact1}>
          <Icon icon="contact" style={$icon} size={fontSize.p2} color={colors.palette.neutral700} />
          <Text style={$tagInfo} tx="Contact.contact1"></Text>
        </TouchableOpacity>
        <Text style={$tag} tx="Contact.whatsApp"></Text>
        <TouchableOpacity activeOpacity={0.7} style={$contactRowContainer} onPress={whatsapp}>
          <Icon icon="whatsapp" style={$icon} size={fontSize.p2} />
          <Text style={$tagInfo} tx="Contact.whatsappNo"></Text>
        </TouchableOpacity>
      </View>
    </View>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
  marginHorizontal: spacing.md,
}
const $text: TextStyle = {
  marginBottom: spacing.ssm,
  fontSize: fontSize.h2,
  marginLeft: spacing.md,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.bold,
}
const $contactRowContainer: ViewStyle = {
  flexDirection: "row",
}

const $icon: ImageStyle = {
  marginTop: spacing.smm,
  marginRight: spacing.ssm,
}

const $tag: TextStyle = {
  color: colors.palette.neutral700,
  fontFamily: typography.primary.bold,
  fontSize: fontSize.p2,
  marginTop: spacing.mmd,
}
const $tagInfo: TextStyle = {
  color: colors.palette.gray300,
  fontSize: fontSize.p3,
  marginTop: spacing.ssm,
}
const $rowcontainer: ViewStyle = {
  flexDirection: "row",
  marginHorizontal: spacing.md,
}
const $contactContainer: ViewStyle = {
  flexDirection: "row",
  marginTop: spacing.xll,
  
}
