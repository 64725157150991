import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle } from "react-native"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { AppStackScreenProps } from "app/navigators"
import { Screen } from "app/components"
import { FirmDetails } from "../components/yarnx"
// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "app/models"

interface FirmDetailsScreenProps
  extends NativeStackScreenProps<AppStackScreenProps<"FirmDetails">> {}

export const FirmDetailsScreen: FC<FirmDetailsScreenProps> = observer(function FirmDetailsScreen(
  _props,
) {
  const { navigation } = _props

  return (
    <Screen style={$root} preset="scroll" safeAreaEdges={["top"]}>
      <FirmDetails navigation={navigation}></FirmDetails>
    </Screen>
  )
})

const $root: ViewStyle = {
  flex: 1,
}
