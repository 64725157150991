import * as React from "react"
import { StyleProp, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Text } from "app/components/Text"
import { colors, fontSize } from "app/theme"
import { Slider } from "@miblanchard/react-native-slider"
import { useEffect, useState } from "react"

export interface RangeSliderProps {
  style?: StyleProp<ViewStyle>
  sendCountData: (data: number[]) => void
}

const SliderContainer = (props) => {
  const { value, setValue, trackMarks } = props

  const renderChildren = () => {
    return React.Children.map(props.children, (child) => {
      if (!!child && child.type === Slider) {
        return React.cloneElement(child, {
          onValueChange: setValue,
          trackMarks,
          value,
        })
      }
      return child
    })
  }

  return (
    <View>
      {renderChildren()}
      <View style={$value}>
        <Text style={$text}>{value[0]}</Text>
        <Text style={$text}>{value[1]}</Text>
      </View>
    </View>
  )
}
export const RangeSlider = observer(function RangeSlider(props: RangeSliderProps) {
  const [min, setMin] = useState(1)
  const [max, setMax] = useState(100)
  const [value, setValue] = React.useState<number[]>([min, max])
  useEffect(() => {
    props.sendCountData(value)
    setMin(value[0])
    setMax(value[1])
  }, [value])

  return (
    <View style={$container}>
      <SliderContainer value={value} setValue={setValue} sliderValue={[1, 100]}>
        <Slider
          maximumTrackTintColor={colors.palette.gray100}
          maximumValue={100}
          minimumTrackTintColor={colors.palette.highlight100}
          minimumValue={1}
          step={3}
          thumbTintColor={colors.palette.gray100}
          thumbStyle={$thumbStyle}
        />
      </SliderContainer>
    </View>
  )
})

const $container: ViewStyle = {
  marginHorizontal: "auto",
  width: "100%",
}

const $value: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
}
const $text: TextStyle = {
  fontSize: fontSize.p4,
}

const $thumbStyle: ViewStyle = {
  borderColor: colors.palette.highlight100,
  borderWidth: 2,
  backgroundColor: colors.palette.neutral100,
}
