export const termsOfUse = [
  {
    content: `TERMS OF USE
1. INTRODUCTION
1.1. THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF THE INFORMATION TECHNOLOGY ACT, 2000 AND RULES MADE THEREUNDER. THIS ELECTRONIC RECORD IS GENERATED BY A COMPUTER SYSTEM AND DOES NOT REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES.
	1.2.	THIS DOCUMENT IS PUBLISHED IN ACCORDANCE WITH THE PROVISIONS OF RULE 3 (1)
OF THE INFORMATION TECHNOLOGY (INTERMEDIARY GUIDELINES AND DIGITAL MEDIA ETHICS CODE) RULES, 2021 THAT REQUIRES PUBLISHING THE RULES AND REGULATIONS, PRIVACY POLICY AND TERMS OF USE FOR ACCESS OR USAGE OF THIS PLATFORM.
	1.3.	Welcome to https://theyarnx.in/ and any associated applications and services along with all upgrades, updates, enhancement and modifications thereto (the "Platform"), owned by Wild Musing Innovations LLP, acompany incorporated under the The Limited Liability Partnership Act, 2008 (bearing LLP identification number AAX-7600), having its registered office at 5/400/1, Date Mala, Ichalkaranji, Kolhapur, Maharashtra, 416115, India (hereinafter referred to as the "Company"). The Company is engaged in the business of providing a double auction based e-auction model for buying and selling yarn.
	1.4.	The Company reserves the right, at its sole discretion, to change, modify, add, or remove portions of these
Terms of Use ("Terms"), at any time and will notify you at least once in a year, or whenever there is a change in the Terms, as the case may be, by email or by posting a conspicuous notice on the Platform in the event of any material revisions to these Terms. Your continued use of the Platform following the incorporation of such changes shall be deemed to mean that you accept and agree to the revisions. As long as you comply with these Terms, the Company grants you a personal, non-exclusive, non-transferable, limited privilege to access and use the Platform.
	1.5.	You should read these Terms and access and read all further linked information, if any, referred to in these
Terms, as such information contains further terms and conditions that apply to you as a user of the Platform. Such linked information including but not limited to the Company"s privacy policy ("Privacy Policy") is hereby incorporated by reference into these Terms.
2. MEMBERSHIP ELIGIBILITY
Use of the Platform is available only to natural persons resident in India who can enter into legally binding contracts under the Indian Contract Act, 1872. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents and persons of unsound mind are not eligible to use the Platform. Any person under the age of 18 shall not use the Platform. If a minor wishes to use the Platform, such use may be made by the minor"s legal guardian or parents. The Company reserves the right to terminate any person"s membership in accordance with these Terms and/or refuse to provide such person with access to the Platform if it is brought to the notice of the Company or if the Company discovers for itself that such person is not eligible to use the Platform.
3. ACCEPTANCE OF TERMS
	3.1.	You are required to carefully read these Terms, as it contains the terms and conditions governing your use
of the Platform and any content such as text, data, information, software, graphics, audio, video, or photographs etc. that the Company may make available through the Platform. By further accessing or using the Platform, you are agreeing to these Terms and entering into a legally binding contract with the Company. You may not use the Platform if you do not accept the Terms or are unable to be bound by these Terms.
1


	3.2.	In order to use the Platform, you must first agree to these Terms by:
3.2.1. Visiting the Platform. In this case, you understand and agree that the Company will treat your access to the Platform as acceptance of these Terms from that point onwards; or
3.2.2. By clicking to accept these Terms, if and when prompted on the Platform.
4. ACESS TO THE PLATFORM
	4.1.	Your access to the Platform may be interrupted, for reasons including but not limited to maintenance,
repairs, upgrades, network or equipment failures. The Company strives to keep the Platform up and running; however, all online services suffer occasional disruptions and outages, and the Company isn"t liable for any disruption or loss you may suffer as a result.
	4.2.	The Company may discontinue content displayed on the Platform, as it may deem fit, at any time.
5. AGREEMENT TO RECEIVE COMMUNICATION
You hereby by way of accepting these Terms consent to the receipt of communication from the Company by way of Short Message Service (SMS) messages, e-mails, promotional and marketing calls and newsletters. These communications could relate to promotions that are undertaken by the Company and its third-party partners.
6. LINKS TO THIRD PARTY PLATFORMS
The Platform may contain links and interactive functionality interacting with platforms of third parties. The Company is not responsible for and has no liability for the functionality, actions, inactions, privacy settings, privacy policies, terms, or content of any such other platforms. Before enabling any sharing functions to communicate with any other platform or otherwise visiting any such platforms, the Company strongly recommends that you review and understand the terms and conditions, privacy policies, settings, and information-sharing functions of each such other third- party platform.
7. USE OF THE PLATFORM
	7.1.	You agree, undertake and covenant that, during your access to and/or use of the Platform, you shall not
host, display, upload, modify, publish, transmit, store, update or share any information that:
(a) belongs to another person and to which you do not have any right, except as expressly provided in these Terms.
(b) is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another person's privacy, insulting or harassing on the basis of religion or gender, hateful or racially or politically motivated or ethnically objectionable, relating or encouraging money laundering or gambling, or otherwise inconsistent or contrary to the applicable laws in force, in any manner whatsoever.
(c) is misleading in any way.
(d) is harmful to minors.
(e) involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming".

2


(f) infringes upon or violates any third party's rights including, but not limited to, any patent, trademark, copyright or other proprietary rights or intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address, or phone number).
(g) provides instructional information about illegal activities such as violating someone's privacy or providing or creating computer viruses.
(h) tries to gain unauthorized access or exceeds the scope of authorized access to the Platform or to profiles, communities, bulletins, or other areas of the Platform or solicits passwords or personally identifying information for commercial or unlawful purposes from other users of the Platform.
(i) engages in commercial activities without the Company"s prior written consent such as engages in contests, sweepstakes, barter, advertising etc.
(j) interferes with another person"s use of the Platform.
(k) impersonates another person.
(l) refers to any website or URL that, in the Company"s sole discretion, contains material that is inappropriate for the Platform or any other website, contains content that would be prohibited or violates the letter or spirit of these Terms.
(m) deceives or misleads the addressee/ users about the origin of the messages or knowingly and intentionally communicates any information which is patently false or misleading or grossly offensive or menacing in nature but may reasonably be perceived as a fact.
(n) is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity, or agency for financial gain or to cause any injury to any person.
(o) contains software viruses or any other computer codes, files or programs designed to interrupt, destroy, or limit the functionality of any computer resource; or contains any trojan horses, worms or other computer programming routines that may damage, detrimentally interfere with, diminish value of, covertly intercept or steal any system, data, or personal information.
(p) where the laws of India are applicable to you, as a user, threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign States, or public order, or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any foreign States.
(q) violates any law for the time being in force.
7.2.	You hereby accept full responsibility for any consequences that may arise from your access and/or use of the Platform, and expressly agree and acknowledge than in no event and under no circumstances shall the Company be held liable to you for any liabilities or damages resulting from or arising out of your access and/or use of the Platform.
7.3.	You agree and acknowledge that you shall not access and/or use the Platform for any fraudulent, malicious, illegal, or unauthorized purpose/activities. The Company shall have the right, but not the obligation, to monitor your access to and/or use of the Platform to ensure your compliance with these Terms or applicable laws, at its sole discretion.
8.	CONTENT AND INTELLECTUAL PROPERTY RIGHTS


	8.1.	All of the content provided on the Platform, including text, software, scripts, code, designs, graphics, photos and
any other content and the copyrights, trademarks, service marks, logos, trade names, and other intellectual and proprietary rights associated therewith ("IP") is owned by the Company or others (including without limitation, third party service providers, if any) that the Company licenses such content from, and is protected by copyright, trademark, patent and other intellectual property laws.
	8.2.	You hereby acknowledge that the IP constitutes original works and has been developed, compiled, prepared,
revised, selected, and arranged by the Company and others through the application of methods and standards of judgment developed and applied through the expenditure of substantial time, effort, and money and constitutes valuable intellectual property of the Company and such others. You thereby agree to protect the proprietary rights of the Company and other limited rights (including, as a licensee) during and after the subsistence of these Terms. You may not selectively download portions of the Platform without retaining the copyright notices. You may download material from the Platform only for your own personal use and for no commercial purposes whatsoever.
	8.3.	You shall access and/or use the Platform strictly in accordance with these Terms, and shall not, directly or
indirectly, (a) decompile, disassemble, reverse engineer, or attempt to derive the source code of, or in any manner decrypt, the Platform; (b) make any modification, adaptation or improvement, enhancement, translation or derivative work from the Platform; (c) violate any applicable laws, rules or regulations in connection with your access or use of the Platform, including your use of any Content (defined below); (d) remove or obscure any proprietary notice (including any notices of copyright or trademark) forming a part of the Platform; (e) access and/or use the Platform for any commercial or revenue generation endeavors, or other purposes for which it is not designed or intended; (f) distribute or transmit the Platform or other services; (g) access and/or use the Platform for data mining, scraping, crawling, redirecting, or for any purpose not in accordance with these Terms; (h) access and/or use the Platform for creating a service or software that is directly or indirectly, competitive with the Platform or any services offered by the Company; or (i) derive any confidential information, processes, data or algorithms from the Platform, as may be applicable.
	8.4.	Any infringement shall lead to appropriate legal proceedings against you at an appropriate forum for
seeking all available remedies under applicable laws.
9. TERMINATION OF ACCESS TO THE PLATFORM
	9.1.	Your access to the Platform can be terminated at any time by the Company, in its sole discretion for any
reason or no reason, including your violation of these Terms. You acknowledge that the termination of access to the Platform may be affected without any prior notice.
	9.2.	The disclaimer of warranties, the limitation of liability, and the governing law provisions shall survive such
termination of your access of the Platform.
10. DISCLAIMER OF WARRANTIES
THE PLATFORM AND ALL INFORMATION, CONTENT AND MATERIALS INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE PLATFORM (COLLECTIVELY, THE "CONTENT") ARE PROVIDED BY THE COMPANY ON AN "AS IS," "AS AVAILABLE" BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND. THE COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE PLATFORM, THE ACCURACY OR COMPLETENESS OF THE CONTENT. WITHOUT LIMITING THE FOREGOING, THE COMPANY MAKES NO WARRANTY THAT THE DISPLAY OF CONTENT ON THE PLATFORM WILL BE UNINTERRUPTED, SECURE OR ERROR-FREE, ACCURATE OR RELIABLE. THE COMPANY SHALL HAVE NO
4


RESPONSIBILITY FOR ANY DAMAGE TO YOUR ELECTRONIC DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY CONTENT, MATERIALS, DOCUMENTS, OR INFORMATION FROM THE PLATFORM. YOU EXPRESSLY AGREE THAT THE ACCESS AND USE OF PLATFORM IS AT YOUR OWN DISCRETION AND SOLE RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE RESULTING FROM ANY TRANSACTION. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE COMPANY OR THROUGH OR FROM THE PLATFORM OR CONTENT SHALL CREATE ANY WARRANTY.
TO THE FULL EXTENT PERMITTED BY APPLICABLE LAW, THE COMPANY DISCLAIMS ANY AND ALL REPRESENTATIONS AND WARRANTIES WITH RESPECT TO THE PLATFORM AND ITS CONTENT, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF TITLE AND NON-INFRINGEMENT, MERCHANTABILITY, SATISFACTORY QUALITY, ACCURACY, COMPATIBILITY, APPLICABILITY, USABILITY, APPROPRIATENESS OR FITNESS FOR A PARTICULAR PURPOSE. YOU HEREBY AGREE TO WAIVE, RELEASE, DISCHARGE, AND HOLD HARMLESS THE COMPANY, ITS AFFILIATES AND EACH OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS (AS APPLICABLE), FROM ANY AND ALL CLAIMS, LOSSES, DAMAGES, LIABILITIES, EXPENSES AND CAUSES OF ACTION ARISING OUT OF ACESS OR USE OF THE PLATFORM.
11. REPRESENTATIONS
You hereby represent and warrant that you have validly entered into these Terms and have the legal power to do so. You further represent and warrant that you shall be solely responsible for the due compliance with these Terms.
12. LIMITATION OF LIABILITY
In no event shall the Company, its affiliates and each of their respective officers, directors, employees and agents (as applicable) be liable for any special, incidental, indirect, consequential, exemplary or punitive damages, or loss of profit or revenues whatsoever resulting from any (a) errors, mistakes, or inaccuracies of Content; (b) any unauthorized access to or use of the secure servers and/or any and all personal and/or other user information stored therein; (c) any interruption or cessation of transmission to or from the Platform; (d) any bugs, viruses, trojan horses, or the like, which may be transmitted to or through the Platform by any third party; (e) any loss of your data arising from any use of or inability to use any Content or other parts of the Platform; (g) non- availability of the Platform in certain geographical areas; and/or (h) any errors or omissions in any Content or for any loss or damage of any kind incurred as a result of the use of any Content posted, emailed, transmitted, or otherwise made available via. the Platform, whether based on warranty, contract, tort, or any other legal theory, and whether or not the Company is advised of the possibility of such damages.
13. INDEMNIFICATION
To the extent permitted by the applicable law, you shall indemnify and hold harmless the Company, its affiliates, and their respective officers, directors, agents, and employees, from any and all claims or demands, or actions including reasonable attorneys' fees, made by any third party or penalty imposed due to or arising out of your breach of these Terms, Privacy Policy and other policies, or your violation of any applicable law, rules or regulations or the rights (including infringement of any intellectual property rights) of a third party.
14. PRIVACY POLICY
Please review the Privacy Policy, which also governs your visit to the Platform to understand the Company"s privacy practices. The personal information/data provided to the Company by you during usage of the Platform will be treated as strictly confidential and in accordance with the

5


Privacy Policy and applicable laws and regulations. If you object to your information being transferred or used, please do not use the Platform.
15. ASSIGNMENT
You shall not license, sell, transfer, or assign your rights, obligations, or covenants under these Terms in any manner without the Company"s prior written consent. The Company reserves the right, at its own discretion, to freely assign and transfer the rights and obligations under these Terms to any third party.
16. GOVERNING LAW AND DISPUTE RESOLUTION
These Terms shall be governed by and interpreted and construed in accordance with the laws of India. The place of jurisdiction shall exclusively be in Bangalore, India. In the event of any dispute arising out of these Terms, the same shall be settled by a binding arbitration conducted by a sole arbitrator, appointed jointly by both parties, and governed by the Arbitration and Conciliation Act, 1996. The venue and seat of arbitration shall be Bangalore, India.
17. SEVERABILITY AND WAIVER
These Terms, the Privacy Policy and other referenced material herein or on the Platform, are the entire agreement between you and the Company with respect to your use of the Platform and supersede all prior or contemporaneous communications and proposals (whether oral, written or electronic) between you and the Company with respect thereto and govern the future relationship. If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that these Terms will otherwise remain in full force and effect and enforceable. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder.
18. GRIEVANCE REDRESSAL MECHANISM
In accordance with the Information Technology Act, 2000 and Rules made thereunder, the name and contact details of the Grievance Officer are provided below:
Name:	Parag Randar
Address:	5/400/1, Date Mala, Ichalkaranji, Kolhapur, 416115, India
Email id:	info@wmsinnovations.com	 
Contact no: +91 9145770000
For registering your complaint, please write to the Grievance Officer at the above-mentioned email address in relation to any violation of the Terms or the Information Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules, 2021. The Grievance Officer shall redress the complaint in accordance with the provisions of the Information Technology Act, 2000 and Rules made thereunder.
19. HOW TO CONTACT US
If you have questions or concerns about these Terms, you may contact the Company at info@wmsinnovations.com	`,
  },
]
