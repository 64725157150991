import React, { FC, useEffect, useState } from "react"
import {
  TextStyle,
  ImageStyle,
  View,
  ViewStyle,
  TouchableWithoutFeedback,
  FlatList,
} from "react-native"
import { observer } from "mobx-react-lite"
import { colors, fontSize, spacing, typography } from "app/theme"
import { Text } from "app/components/Text"
import { Icon } from "../Icon"
import { BottomSheet } from "./BottomSheet"
import { ActionType } from "..//../type/type"
import { useRoute } from "@react-navigation/native"

interface WatchListProps {
  style?: object
  data: any[]
  navigation: any
  yarnId: string | null
  onYarnIdChange: (yarnId: string | null) => void
}

export const Watchlist: FC<WatchListProps> = observer(function WatchList(props) {
  const { style, data, navigation, yarnId, onYarnIdChange} = props
  const route = useRoute()
  const [showBottomSheet, setShowBottomSheet] = useState<string>("")

  useEffect(() => {
    if (route.params?.yarnId) {
      setShowBottomSheet(route.params.yarnId)
    }
  }, [route.params])

  const bottomSheetCallback = (response) => {
    if (response.actionType === ActionType.BottomSheetClose) {
      setShowBottomSheet("")
      navigation.setParams({ yarnId: undefined })
    } else if (response.actionType === ActionType.UpdateYarnId) {
      onYarnIdChange(response.yarnId)
    }
  }

  useEffect(() => {
    if (yarnId) {
      setShowBottomSheet(yarnId)
    } else {
      setShowBottomSheet("")
    }
  }, [yarnId])

  return (
    <View style={style}>
      <FlatList
        data={data}
        keyExtractor={(item) => item?.yarnId}
        renderItem={({ item }) => (
          <TouchableWithoutFeedback onPress={() => setShowBottomSheet(item.yarnId.toString())}>
            <View>
              {String(item.yarnId) === showBottomSheet ? (
                <BottomSheet
                  item={item}
                  callback={bottomSheetCallback}
                  showBottomSheet={showBottomSheet}
                  setShowBottomSheet={setShowBottomSheet}
                  navigation={navigation}
                  showMoreData={0}
                  data={[]}
                />
              ) : null}

              <View style={$row}>
                <Text style={$text}>
                  {item.name}
                  {item.weightPerBag !== 0 &&
                    item.weightPerBag !== undefined &&
                    item.weightPerBag !== null && (
                      <Text style={$kgText}>&nbsp;({item.weightPerBag})</Text>
                    )}
                </Text>
                {item.TEP !== null && item.TEP !== undefined ? (
                  <Text style={$text}>₹{item.TEP}</Text>
                ) : (
                  item.refValue !== 0 &&
                  item.refValue !== undefined &&
                  item.refValue !== null && <Text style={$text}>₹{item.refValue}</Text>
                )}
              </View>
              <View style={$row}>
                <Text style={$subText}>
                  {item.OOQ != null && (
                    <View style={$orderDetailsSubTitle}>
                      <Icon
                        style={$watchListBagIcon}
                        icon={"yourOrder"}
                        color={colors.palette.gray200}
                        size={fontSize.p4}
                      />
                      <Text style={$subTextRate}>
                        {item.OOQ}&nbsp;
                        <Text style={$OpenOrderQuantity} tx="watchListScreen.box" />
                        {item.OOP}
                      </Text>
                    </View>
                  )}
                </Text>
                {/* <Text
                  style={
                    item.TEP - item.YEP < 0
                      ? $subTextRateNegative
                      // : $subTextRatePositive
                  }
                >
                  {item.TEP - item.YEP}
                </Text> */}
                {<Text style={$subTextPosition}></Text>}
              </View>
              <View style={$line} />
            </View>
          </TouchableWithoutFeedback>
        )}
      />
    </View>
  )
})

const $kgText: TextStyle = {
  fontSize: fontSize.p5,
  color: colors.palette.gray300,
}

const $row: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: colors.palette.neutral100,
  width: "100%",
}

const $text: TextStyle = {
  paddingTop: spacing.sm,
  fontFamily: typography.primary.medium,
  fontSize: fontSize.p3,
  color: colors.palette.neutral700,
  paddingHorizontal: spacing.md,
}

const $subText: TextStyle = {
  fontFamily: typography.primary.normal,
  fontSize: fontSize.p4,
  color: colors.palette.gray200,
  paddingBottom: spacing.sm,
}

const $line: ViewStyle = {
  height: 1,
  width: "100%",
  backgroundColor: colors.palette.gray100,
}

// const $subTextRateNegative: TextStyle = {
//   color: colors.palette.failure100,
//   fontFamily: typography.primary.medium,
//   fontSize: fontSize.p4,
//   paddingBottom: spacing.md,
//   paddingHorizontal: spacing.md,
// }

// const $subTextRatePositive: TextStyle = {
//   color: colors.palette.success100,
//   fontFamily: typography.primary.medium,
//   fontSize: fontSize.p4,
//   paddingBottom: spacing.md,
//   paddingHorizontal: spacing.md,
// }

const $subTextPosition: TextStyle = {
  paddingBottom: spacing.md,
  paddingHorizontal: spacing.md,
}

const $subTextRate: TextStyle = {
  fontSize: fontSize.p4,
  color: colors.palette.gray200,
  bottom: spacing.xxxs,
  position: "relative",
  left: spacing.mdd,
}
const $OpenOrderQuantity: TextStyle = {
  fontSize: fontSize.p4,
  color: colors.palette.gray200,
  position: "relative",
  left: spacing.xxxs,
  marginRight: spacing.xxs,
}

const $watchListBagIcon: ImageStyle = {
  left: spacing.md,
  top: spacing.xxs,
}

const $orderDetailsSubTitle: ViewStyle = {
  flexDirection: "row",
  position: "relative",
  top: spacing.mxxxs,
}
