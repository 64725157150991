import React, { useState, useEffect } from "react"
import { StyleProp, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, fontSize, typography, spacing } from "app/theme"
import { Text } from "../Text"
import { Icon } from "../Icon"

export interface CountdownTimerProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  initialTime: number // Define the initialTime property
}

/**
 * Describe your component here
 */
export const CountdownTimer = observer(function CountdownTimer(props: CountdownTimerProps) {
  const { style, initialTime } = props
  const $styles = [$container, style]
  const [timeRemaining, setTimeRemaining] = useState(initialTime)

  useEffect(() => {
    if (timeRemaining > 0) {
      const timerId = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1)
      }, 1000)

      return () => {
        clearInterval(timerId)
      }
    }
  }, [timeRemaining])

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    if (hours > 0) {
      const formattedHours = hours < 10 ? `0${hours}` : `${hours}`
      const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
      const formattedSeconds =
        remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`

      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
    } else {
      const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
      const formattedSeconds =
        remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`

      return `${formattedMinutes}:${formattedSeconds}`
    }
  }

  return (
    <View style={$styles}>
      <View style={$row}>
        <Icon
          color={colors.palette.failure100}
          size={fontSize.p4}
          icon="history"
          style={$iconStyle}
        />
        <Text style={$timerText}>{formatTime(timeRemaining)}</Text>
      </View>
    </View>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
}
const $iconStyle: ViewStyle = {
  marginRight: spacing.xxs,
}
const $row: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}

const $timerText: TextStyle = {
  fontFamily: typography.primary.normal,
  fontSize: fontSize.p4,
  color: colors.palette.failure100,
}
