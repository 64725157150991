/* eslint-disable react/no-children-prop */
import React, { FC, useEffect, useState, memo, useCallback } from "react"
import { TextStyle, ViewStyle, View, StatusBar, Platform } from "react-native"
import { TabScreenProps } from "../../navigators/AppCoreNavigator"
import { Screen, Text } from "../../components"
import { colors, fontSize, spacing, typography } from "../../theme"
import { FILTER_TYPE, FilterResponseParams } from "app/types"
import { useStores } from "app/models"
import { getWatchListData } from "app/presenter/WatchListPresenter"
import { API_BASE_URL } from "../../../url.js"
import SSE from "react-native-sse"
import { api } from "app/services/api"
import {
  createMaterialTopTabNavigator,
  MaterialTopTabNavigationOptions,
} from "@react-navigation/material-top-tabs"
import WatchListTab from "app/components/yarnx/WachlistTab"

const Tab = createMaterialTopTabNavigator()

export const WatchListScreen: FC<TabScreenProps<"WatchList">> = memo(
  function WatchListScreen(_props) {
    const { navigation } = _props
    const {
      authenticationStore: { authToken, setBrokers, logout, authRefreshToken },
    } = useStores()

    const [originalWatchListData, setOriginalWatchListData] = useState([])
    const [readyDeliveryData, setReadyDeliveryData] = useState([])
    const [onDemandData, setOnDemandData] = useState([])
    const [readyDeliveryFilteredData, setReadyDeliveryFilteredData] = useState([])
    const [onDemandFilteredData, setOnDemandFilteredData] = useState([])
    const [readyDeliverySearchData, setReadyDeliverySearchData] = useState([])
    const [onDemandSearchData, setOnDemandSearchData] = useState([])
    const [isReadyDeliveryFiltered, setIsReadyDeliveryFiltered] = useState(false)
    const [isOnDemandFiltered, setIsOnDemandFiltered] = useState(false)
    const [loading, setLoading] = useState(true)
    const [refreshing, setRefreshing] = useState(false)
    const [readyDeliverySearchQuery, setReadyDeliverySearchQuery] = useState("")
    const [onDemandSearchQuery, setOnDemandSearchQuery] = useState("")
    const [currentTab, setCurrentTab] = useState("ReadyDelivery")
    const apiURL = `${API_BASE_URL}/watchlist`

    const useQuery = () => {
      return new URLSearchParams(window.location.search)
    }

    const query = useQuery()
    const [yarnId, setYarnId] = useState(query.get("q"))
    const [updatedYarnId, setUpdatedYarnId] = useState<string | null>(null)
    if (Platform.OS === "web") {
      const [url] = useState(window.location.href)
      useEffect(() => {
        if (url) {
          navigation.navigate("/")
        }
      }, [])
    }

    useEffect(() => {
      const navigateBasedOnYarnId = () => {
        if (yarnId || updatedYarnId) {
          const currentYarnId = updatedYarnId || yarnId
          const foundInReadyDelivery = readyDeliveryData.find(
            (item) => String(item.yarnId) === currentYarnId,
          )
          const foundInOnDemand = onDemandData.find((item) => String(item.yarnId) === currentYarnId)

          if (foundInReadyDelivery) {
            if (currentTab !== "ReadyDelivery") {
              navigation.navigate("ReadyDelivery")
              setCurrentTab("ReadyDelivery")
            }
          } else if (foundInOnDemand) {
            if (currentTab !== "OnDemand") {
              navigation.navigate("OnDemand")
              setCurrentTab("OnDemand")
            }
          }
        }
      }

      navigateBasedOnYarnId()
    }, [yarnId, updatedYarnId, readyDeliveryData, onDemandData, navigation, currentTab])

    const apiToken = authToken

    const onRefresh = useCallback(async () => {
      setRefreshing(true)
      setTimeout(() => {
        setRefreshing(false)
      }, 2000)
    }, [])

    async function fetchAllBrokers() {
      try {
        const response = await api.getBrokerData(authToken)
        if (response.kind === "ok") {
          const Data: any = response.data
          if (Data.code === 200) {
            setBrokers(JSON.stringify(response.data.data.Brokers))
          } else {
            alert("Brokers not Fetched")
          }
        }
      } catch (error) {}
    }

    useEffect(() => {
      fetchAllBrokers()
    }, [])

    useEffect(() => {
      const establishSSEConnection = (eventCallback, readyDelivery) => {
        const url = `${apiURL}?readydelivery=${readyDelivery}`
        const sse = new SSE(url, {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        })

        sse.addEventListener("message", eventCallback)

        sse.addEventListener("error", async (event) => {
          if (event && event.message) {
            try {
              const errorData = JSON.parse(event.message)
              if (errorData.code === 401) {
                try {
                  await api.postLogout(authRefreshToken)
                } catch (logoutError) {
                  console.error("Error during logout:", logoutError)
                }
                logout()
                sse.close()
              }
            } catch (parseError) {
              console.error("Error parsing error message:", parseError)
            }
          } else {
            console.error("Unknown SSE error:", event)
          }
        })

        return () => {
          sse.close()
        }
      }

      const handleSSEMessage = (event) => {
        const eventData = JSON.parse(event.data)
        const sortbyname = eventData.sort((a, b) => a.variety.localeCompare(b.variety))
        const sortbycount = sortbyname.sort((a, b) => b?.count - a?.count)
        const updatedYarnData = getWatchListData(sortbycount)

        setOriginalWatchListData((prevData) => {
          if (prevData.length === 0) {
            return updatedYarnData
          }
          return prevData.map((item) => {
            const updatedItem = updatedYarnData.find((updated) => updated.yarnId === item.yarnId)
            return updatedItem || item
          })
        })

        setReadyDeliveryData((prevData) => {
          const updatedReadyDelivery = updatedYarnData.filter(
            (item) => item.YEP || item.TEP || item.refValue,
          )
          if (prevData.length === 0) {
            return updatedReadyDelivery
          }
          return prevData.map((item) => {
            const updatedItem = updatedReadyDelivery.find(
              (updated) => updated.yarnId === item.yarnId,
            )
            return updatedItem || item
          })
        })

        setOnDemandData((prevData) => {
          const updatedOnDemand = updatedYarnData.filter(
            (item) => !item.YEP && !item.TEP && !item.refValue,
          )
          if (prevData.length === 0) {
            return updatedOnDemand
          }
          return prevData.map((item) => {
            const updatedItem = updatedOnDemand.find((updated) => updated.yarnId === item.yarnId)
            return updatedItem || item
          })
        })

        setReadyDeliverySearchData((prevData) => {
          return prevData.map((item) => {
            const updatedItem = updatedYarnData.find((updated) => updated.yarnId === item.yarnId)
            return updatedItem || item
          })
        })

        setOnDemandSearchData((prevData) => {
          return prevData.map((item) => {
            const updatedItem = updatedYarnData.find((updated) => updated.yarnId === item.yarnId)
            return updatedItem || item
          })
        })

        setReadyDeliveryFilteredData((prevData) => {
          return prevData.map((item) => {
            const updatedItem = updatedYarnData.find((updated) => updated.yarnId === item.yarnId)
            return updatedItem || item
          })
        })

        setOnDemandFilteredData((prevData) => {
          return prevData.map((item) => {
            const updatedItem = updatedYarnData.find((updated) => updated.yarnId === item.yarnId)
            return updatedItem || item
          })
        })

        setLoading(false)
      }

      const cleanupReadyDelivery = establishSSEConnection(handleSSEMessage, true)
      const cleanupOnDemand = establishSSEConnection(handleSSEMessage, false)

      return () => {
        cleanupReadyDelivery()
        cleanupOnDemand()
      }
    }, [apiToken, apiURL, authRefreshToken, logout])

    const searchBarCallback = useCallback(
      (resp: Array<FilterResponseParams>, tab: string) => {
        let dataToFilter = tab === "ReadyDelivery" ? readyDeliveryData : onDemandData
        let isFiltered = false

        resp.forEach((response) => {
          const isCountFilter = resp.length === 1 && response.filterType === FILTER_TYPE.COUNT
          isFiltered = !isCountFilter

          switch (response.filterType) {
            case FILTER_TYPE.QUERY:
              if (response.data) {
                const searchQuery =
                  typeof response.data === "string" ? response.data.toLowerCase() : ""
                const searchTerms = searchQuery.split(" ")
                dataToFilter = dataToFilter.filter((item) => {
                  const itemNameLowerCase = item.name.toLowerCase()
                  return searchTerms.every((term) => itemNameLowerCase.includes(term))
                })
              }
              break
            case FILTER_TYPE.SORT_ALPHABETICALLY:
              if (response.data) {
                dataToFilter = dataToFilter.sort((a, b) => a.variety.localeCompare(b.variety))
              }
              break
            case FILTER_TYPE.COUNT:
              if (response.data && response.data[0]) {
                dataToFilter = dataToFilter.filter(
                  (e) => e.count >= response.data[0] && e.count <= response.data[1],
                )
              }
              break
            case FILTER_TYPE.YARN_TYPE:
              if (response.data && response.data[0]) {
                dataToFilter = dataToFilter.filter((e) => response.data.includes(e.variety))
              }
              break
            case FILTER_TYPE.SORRT_COUNT:
              if (response.data) {
                dataToFilter = dataToFilter.sort((a, b) => a?.count - b?.count)
              }
              break
            case FILTER_TYPE.CLEAR:
              dataToFilter = tab === "ReadyDelivery" ? readyDeliveryData : onDemandData
              isFiltered = false
              break
            default:
              break
          }
        })

        if (tab === "ReadyDelivery") {
          setIsReadyDeliveryFiltered(isFiltered)
          setReadyDeliveryFilteredData(dataToFilter)
          setReadyDeliverySearchData([])
          setReadyDeliverySearchQuery("")
        } else {
          setIsOnDemandFiltered(isFiltered)
          setOnDemandFilteredData(dataToFilter)
          setOnDemandSearchData([])
          setOnDemandSearchQuery("")
        }
      },
      [readyDeliveryData, onDemandData],
    )

    const handleSearch = useCallback(
      (query: string, tab: string) => {
        const searchQuery = query.toLowerCase()
        const searchTerms = searchQuery.split(" ")
        const dataToSearch =
          tab === "ReadyDelivery"
            ? isReadyDeliveryFiltered
              ? readyDeliveryFilteredData
              : readyDeliveryData
            : isOnDemandFiltered
            ? onDemandFilteredData
            : onDemandData

        if (query === "") {
          if (tab === "ReadyDelivery") {
            setReadyDeliverySearchData([])
          } else {
            setOnDemandSearchData([])
          }
        } else {
          const filteredSearchData = dataToSearch.filter((item) => {
            const itemNameLowerCase = item.name.toLowerCase()
            return searchTerms.every((term) => itemNameLowerCase.includes(term))
          })
          if (tab === "ReadyDelivery") {
            setReadyDeliverySearchData(filteredSearchData)
          } else {
            setOnDemandSearchData(filteredSearchData)
          }
        }

        setSearchQuery(tab, query)
      },
      [
        isReadyDeliveryFiltered,
        isOnDemandFiltered,
        readyDeliveryFilteredData,
        onDemandFilteredData,
        readyDeliveryData,
        onDemandData,
      ],
    )

    const setSearchQuery = (tab: string, query: string) => {
      if (tab === "ReadyDelivery") {
        setReadyDeliverySearchQuery(query)
      } else {
        setOnDemandSearchQuery(query)
      }
    }

    const tabPressHandler = useCallback(
      (tabName: string) => {
        setCurrentTab(tabName)
      },
      [setCurrentTab],
    )

    return (
      <Screen preset="fixed" contentContainerStyle={$container} safeAreaEdges={["top"]}>
        <StatusBar backgroundColor={colors.palette.gray100} />
        <View style={$header}>
          <Text tx="watchListScreen.title" style={$title}></Text>
        </View>

        <Tab.Navigator screenOptions={$screenOptions}>
          <Tab.Screen
            name="ReadyDelivery"
            listeners={{
              tabPress: () => tabPressHandler("ReadyDelivery"),
            }}
            children={() =>
              currentTab === "ReadyDelivery" && (
                <WatchListTab
                  data={
                    readyDeliverySearchQuery
                      ? readyDeliverySearchData
                      : isReadyDeliveryFiltered
                      ? readyDeliveryFilteredData
                      : readyDeliveryData
                  }
                  loading={loading}
                  refreshing={refreshing}
                  onRefresh={onRefresh}
                  navigation={navigation}
                  yarnId={updatedYarnId || yarnId}
                  searchBarCallback={(resp) => searchBarCallback(resp, "ReadyDelivery")}
                  handleSearch={(query) => handleSearch(query, "ReadyDelivery")}
                  originalWatchListData={originalWatchListData}
                  setUpdatedYarnId={setUpdatedYarnId}
                />
              )
            }
            options={{ tabBarLabel: "Ready Delivery" }}
          />
          <Tab.Screen
            name="OnDemand"
            listeners={{
              tabPress: () => tabPressHandler("OnDemand"),
            }}
            children={() =>
              currentTab === "OnDemand" && (
                <WatchListTab
                  data={
                    onDemandSearchQuery
                      ? onDemandSearchData
                      : isOnDemandFiltered
                      ? onDemandFilteredData
                      : onDemandData
                  }
                  loading={loading}
                  refreshing={refreshing}
                  onRefresh={onRefresh}
                  navigation={navigation}
                  yarnId={updatedYarnId || yarnId}
                  searchBarCallback={(resp) => searchBarCallback(resp, "OnDemand")}
                  handleSearch={(query) => handleSearch(query, "OnDemand")}
                  originalWatchListData={originalWatchListData}
                  setUpdatedYarnId={setUpdatedYarnId}
                />
              )
            }
            options={{ tabBarLabel: "On Demand" }}
          />
        </Tab.Navigator>
      </Screen>
    )
  },
  () => true,
)
const $container: ViewStyle = {
  flex: 1,
  backgroundColor: colors.palette.gray100,
}

const $header: ViewStyle = {
  width: "100%",
  backgroundColor: colors.palette.gray100,
  paddingTop: spacing.xlll,
  marginLeft: spacing.md,
}

const $title: TextStyle = {
  fontSize: fontSize.h2,
  fontFamily: typography.fonts.inter.bold,
}

const $screenOptions: MaterialTopTabNavigationOptions = {
  swipeEnabled: false,
  tabBarStyle: {
    elevation: 0,
    width: "65%",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    backgroundColor: colors.palette.gray100,
    position: "relative",
  },
  tabBarActiveTintColor: colors.palette.highlight100,
  tabBarInactiveTintColor: colors.palette.gray300,
  tabBarLabelStyle: {
    textTransform: "capitalize",
    fontSize: fontSize.p3,
    fontFamily: typography.fonts.inter.medium,
  },
}
