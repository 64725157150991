import React, { useState, useEffect } from "react"
import {
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  TouchableWithoutFeedback,
  RefreshControl,
  FlatList,
  ActivityIndicator,
} from "react-native"
import { observer } from "mobx-react-lite"
import { colors, fontSize, spacing, typography } from "app/theme"
import { Text } from "app/components/Text"
import { SearchBar } from "./WatchListSearchBar"
import { FILTER_TYPE, FilterResponseParams } from "app/types"
import { setTime, setDate } from "../../services/constants/constant.js"
import { useStores } from "app/models"
export interface ExecutedorderProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  openOrderData: any[]
  excutedOrderDatas: any[]
}

/**
 * Describe your component here
 */
export const Executedorder = observer(function Executedorder(props: ExecutedorderProps) {
  const { style, excutedOrderDatas, getExeuctedOrder } = props
  const $styles = [$container, style]
  const [originalExecutedOrderData, setOriginalExecutedOrderData] = useState([])
  const [executedOrderData, setExecutedOrderData] = useState(originalExecutedOrderData)
  const [refreshing, setRefreshing] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const {
    authenticationStore: { role },
  } = useStores()

  useEffect(() => {
    setOriginalExecutedOrderData(excutedOrderDatas)
      setExecutedOrderData(excutedOrderDatas)
      setTimeout(() => {
        setLoading(false)
      }, 2000)
  }, [excutedOrderDatas])

  const onRefresh = React.useCallback(() => {
    setRefreshing(true)
    setTimeout(() => {
      setRefreshing(false)
    }, 2000)
  }, [])

  const searchBarCallback = (resp: Array<FilterResponseParams>) => {
    let clonedOriginalFilteredData = JSON.parse(JSON.stringify(originalExecutedOrderData))
    // eslint-disable-next-line array-callback-return
    resp.map((response) => {
      switch (response.filterType) {
        case FILTER_TYPE.SORT_ALPHABETICALLY: {
          if (response.data) {
            if (!executedOrderData[0]) {
              clonedOriginalFilteredData = clonedOriginalFilteredData.sort((a, b) =>
                a.mill.localeCompare(b.mill),
              )
            } else {
              clonedOriginalFilteredData = clonedOriginalFilteredData.sort((a, b) =>
                a.mill.localeCompare(b.mill),
              )
            }
          }
          break
        }

        case FILTER_TYPE.COUNT: {
          if (response.data) {
            if (!response.data[0]) {
              break
            }
            clonedOriginalFilteredData = clonedOriginalFilteredData.filter((e) => {
              return e.count >= response.data[0] && e.count <= response.data[1]
            })
          }
          break
        }

        case FILTER_TYPE.YARN_TYPE: {
          if (response.data) {
            if (!response.data[0]) {
              break
            }
            clonedOriginalFilteredData = clonedOriginalFilteredData.filter((e) =>
              response.data.includes(e.variety),
            )
          }
          break
        }

        case FILTER_TYPE.SORRT_COUNT: {
          if (response.data) {
            if (!executedOrderData[0]) {
              clonedOriginalFilteredData = clonedOriginalFilteredData.sort(
                (a, b) => a?.count - b?.count,
              )
            } else {
              clonedOriginalFilteredData = clonedOriginalFilteredData.sort(
                (a, b) => a?.count - b?.count,
              )
            }
          }
          break
        }

        case FILTER_TYPE.CLEAR: {
          clonedOriginalFilteredData = originalExecutedOrderData

          break
        }
        default: {
          break
        }
      }
      setExecutedOrderData(clonedOriginalFilteredData)
    })
  }
  return (
    <View style={$backContainer}>
      <View style={$styles}>
        <View style={$searchBar}>
          <SearchBar callback={searchBarCallback} data={originalExecutedOrderData} />
          <View style={$searchBarContainer} />
        </View>
        <View style={$topContainer} />
        {loading ? (
          <>
            <ActivityIndicator style={$activityIndicator} size="large" />
          </>
        ) : executedOrderData?.length > 0 ? (
          <FlatList
            data={executedOrderData}
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => (
              <TouchableWithoutFeedback>
                <View>
                  <View style={$touchContainer}>
                    <View style={$row}>
                      <Text style={$text}>
                        <Text style={$textOrders} tx="ordersScreen.orderNo" />
                        {item.id}{" "}
                      </Text>
                      <Text style={$text}>
                        {setTime(item.executed_at)} {setDate(item.executed_at)}{" "}
                      </Text>
                    </View>

                    <View style={$row}>
                      <Text style={$subTextRate}>{item.quality_name}</Text>
                      <Text style={$textSuccess}>
                        ₹{item.price} ({item.quantity} boxes)
                      </Text>
                    </View>

                    <View style={$row}>
                      <Text tx="ordersScreen.brokerName" style={$brokerName} />
                      <Text style={$buyRate}>{item.broker_name}</Text>
                    </View>

                    <View style={$row}>
                      {role === "seller" ? (
                        <Text style={$buyname} tx="ordersScreen.buyerName" />
                      ) : (
                        <Text style={$buyname} tx="ordersScreen.sellerName" />
                      )}
                      <Text style={$buyName}>
                        {role === "buyer" ? item.sellername : item.buyername}
                      </Text>
                    </View>

                    <View style={$line} />
                  </View>
                </View>
              </TouchableWithoutFeedback>
            )}
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
            style={$bottomContainer}
          />
        ) : (
          <View style={$noDataContainer}>
            <Text style={$noDataText} tx="AlertsScreen.noData" />
          </View>
        )}
      </View>
    </View>
  )
})
const $backContainer: ViewStyle = {
  backgroundColor: colors.palette.neutral100,
  height: "100%",
}
const $activityIndicator: ViewStyle = {
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  zIndex: 3,
}
const $searchBarContainer: ViewStyle = {
  backgroundColor: colors.palette.neutral100,
  height: 22,
  position: "relative",
  bottom: spacing.mmd,
  zIndex: -1,
}
const $noDataContainer: ViewStyle = {
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
}

const $noDataText: TextStyle = {
  fontSize: fontSize.h4,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.bold,
  opacity: 0.2,
  zIndex: 3,
}
const $container: ViewStyle = {
  justifyContent: "center",
  backgroundColor: colors.palette.neutral100,
}
const $touchContainer: ViewStyle = {
  height: 78,
  backgroundColor: colors.palette.neutral100,
}
const $searchBar: ViewStyle = {
  zIndex: 1,
  backgroundColor: colors.palette.gray100,
  height: 38,
}
const $topContainer: ViewStyle = {
  backgroundColor: colors.palette.gray100,
}
const $bottomContainer: ViewStyle = {
  backgroundColor: colors.palette.neutral100,
}
const $brokerName: TextStyle = {
  fontFamily: typography.fonts.inter.normal,
  fontSize: fontSize.p4,
  color: colors.palette.gray300,
  paddingHorizontal: spacing.md,
  position: "relative",
  bottom: spacing.xs,
}
const $row: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: colors.palette.neutral100,
  width: "100%",
}
const $textSuccess: TextStyle = {
  fontSize: fontSize.p3,
  color: colors.palette.highlight100,
  fontFamily: typography.fonts.inter.normal,
  paddingHorizontal: spacing.md,
}
const $text: TextStyle = {
  fontFamily: typography.fonts.inter.normal,
  fontSize: fontSize.p5,
  color: colors.palette.gray300,
  paddingHorizontal: spacing.md,
  position: "relative",
  top: spacing.xs,
}
const $textOrders: TextStyle = {
  fontFamily: typography.fonts.inter.normal,
  fontSize: fontSize.p5,
  color: colors.palette.gray300,
  marginRight: spacing.xxxs,
}
const $line: ViewStyle = {
  height: 1,
  width: "100%",
  backgroundColor: colors.palette.gray100,
  position: "relative",
  bottom: 20,
}

const $subTextRate: TextStyle = {
  fontSize: fontSize.p3,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.medium,
  paddingHorizontal: spacing.md,
}

const $buyRate: TextStyle = {
  fontFamily: typography.fonts.inter.normal,
  fontSize: fontSize.p4,
  color: colors.palette.success100,
  position: "absolute",
  left: spacing.xxl + spacing.xxl,
  bottom: 8,
}
const $buyName: TextStyle = {
  fontFamily: typography.fonts.inter.normal,
  fontSize: fontSize.p4,
  color: colors.palette.success100,
  position: "absolute",
  bottom: spacing.mmd,
  left: spacing.xxl + spacing.mmd + spacing.mmd + spacing.xxxs,
}
const $buyname: TextStyle = {
  fontFamily: typography.fonts.inter.normal,
  fontSize: fontSize.p4,
  color: colors.palette.neutral700,
  paddingHorizontal: spacing.md,
  position: "relative",
  bottom: spacing.mmd,
}
