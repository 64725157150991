import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle } from "react-native"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { AppStackScreenProps } from "app/navigators"
import { Screen } from "app/components"
import { Contact } from "app/components/yarnx"

interface ContactScreenProps extends NativeStackScreenProps<AppStackScreenProps<"Contact">> {}

export const ContactScreen: FC<ContactScreenProps> = observer(function ContactScreen(_props) {
  const { navigation } = _props
  return (
    <Screen style={$root} preset="scroll" safeAreaEdges={["top"]}>
      <Contact navigation={navigation}></Contact>
    </Screen>
  )
})

const $root: ViewStyle = {
  flex: 1,
}
