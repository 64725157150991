import React, { useState } from "react"
import {
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  TextInput,
  Alert,
  StatusBar,
  ActivityIndicator,
  ImageStyle,
} from "react-native"
import { observer } from "mobx-react-lite"
import { colors, fontSize, spacing, typography } from "app/theme"
import { Text } from "app/components/Text"
import { SwipeButton } from "../SwipeButton"
import { Dropdown } from "react-native-element-dropdown"
import { creditPeriodData, validityData } from "../../services/constants/constant"
import { api } from "app/services/api"
import { useRoute } from "@react-navigation/native"
import { SwipeToModifyButton } from "../SwipeToModifyButton"
import { useStores } from "app/models"
import { Icon, IconTypes } from "../Icon"
export interface SwipeToBuyProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
}

/**
 * Describe your component here
 */
export const SwipeToBuy = observer(function SwipeToBuy(props: SwipeToBuyProps) {
  const { style, navigation } = props
  const $styles = [$container, style]
  const {
    authenticationStore: { authToken, brokers },
  } = useStores()

  const [validity, setValidity] = useState()
  const [broker] = useState(JSON.parse(brokers))
  const route = useRoute()
  const data = route?.params
  const item = data?.item
  const [quantity, setQuantity] = useState(item?.OOQ)
  const [creditPeriod, setCreditPeriod] = useState(30 || item?.creditDays)
  const brokerData = broker[0]
  const [brokerName, setBrokerName] = useState(101)
  const creditPeriodLabel =
    creditPeriodData.find((item) => item.value === creditPeriod)?.label || ""
  const brokerNameLabel = brokerData ? brokerData.broker_name : "Yarnx"
  const [rate, setRate] = useState(item?.OOP)
  const [dataReceiveds] = useState(JSON.stringify(data?.item?.yarnId))
  const [loading, setLoading] = useState(false)
  const apiToken = authToken
  async function fetchSwipeToBuy() {
    try {
      setLoading(true)
      const response = await api.getSwipeToBuy(
        parseInt(quantity),
        parseFloat(rate),
        parseInt(creditPeriod),
        parseInt(validity),
        parseInt(dataReceiveds),
        brokerName,
        apiToken,
      )

      const listData: any = response.data
      const listDataMessage = JSON.parse(listData)

      const errorMessage = listDataMessage.message

      
      if (response.kind === "ok") {
        if (listData.code === 201) {
          setLoading(false)
          testAlert()
          handleBack()
        } else if (listData.code === 403) {
          alert("Your approval status is not approved yet. Contact admin")
          setLoading(false)
          handleBack()
        } else {
          alert("Order Not Created. Please try again")
          setLoading(false)
          handleBack()
        }
      } else if (response.kind === "bad-data") {
        alert(errorMessage)
        setLoading(false)
        handleBack()
      }
    } catch (error) {
      setLoading(false)
      handleBack()
    }
  }

  async function fetchSwipeToBuyModify() {
    try {
      setLoading(true)
      const response = await api.getSwipeToBuyModify(
        parseInt(quantity),
        parseFloat(rate),
        parseInt(creditPeriod),
        parseInt(validity),
        brokerName,
        apiToken,
        parseInt(data?.item?.OOI),
      )

      const listData: any = response.data
      const listDataMessage = JSON.parse(listData)
      const errorMessage = listDataMessage.message
      if (response.kind === "ok") {
        if (listData.code === 200) {
          setLoading(false)
          testAlertModify()
          handleBack()
        } else {
          alert("Order Not Modify. Please try again")
          setLoading(false)
          handleBack()
        }
      } else if (response.kind === "bad-data") {
        alert(errorMessage)
        setLoading(false)
        handleBack()
      }
    } catch (error) {
      setLoading(false)
      handleBack()
    }
  }

  const testAlert = () =>
    Alert.alert(
      "Buy Successfully",
      "Swiping to buy has been Successfully",
      [{ text: "OK", onPress: () => handleBack() }],
      { cancelable: false },
    )
  const testAlertModify = () =>
    Alert.alert(
      "Modify Successfully",
      "Swiping to Modify has been Successfully",
      [{ text: "OK", onPress: () => handleBack() }],
      { cancelable: false },
    )
  const isFieldEmpty = (value) => {
    return (
      value === undefined || value === null || (typeof value === "string" && value.trim() === "")
    )
  }

  const handleButtonActivate = () => {
    if (
      isFieldEmpty(quantity) ||
      isFieldEmpty(rate) ||
      isFieldEmpty(creditPeriod) ||
      isFieldEmpty(validity) ||
      isFieldEmpty(brokerName)
    ) {
      Alert.alert("Validation Error", "Please fill in all required fields.")
      return
    }

    fetchSwipeToBuy()
  }

  const handleButtonActivateModify = () => {
    if (
      isFieldEmpty(quantity) ||
      isFieldEmpty(rate) ||
      isFieldEmpty(creditPeriod) ||
      isFieldEmpty(validity) ||
      isFieldEmpty(brokerName)
    ) {
      Alert.alert("Validation Error", "Please fill in all required fields.")
      return
    }

    testAlertModify()
    fetchSwipeToBuyModify()
  }
  const handleBack = () => {
    navigation.navigate("WatchList")
  }

  return (
    <View style={$styles}>
      <StatusBar backgroundColor={colors.palette.gray100} />
      <View style={loading ? $blurredContainer : null}>
        <View style={$topcontainer}>
          <View style={$row}>
            <Icon style={$backIcon} icon="back" size={24} color={colors.text} onPress={()=>navigation.goBack()}/>
            <Text style={$swipeTpBuyText}>{data?.item?.name}</Text>
          </View>
          <View style={$middleContainer}>
            <View style={$textInputContainer}>
              <View style={$rowContainer}>
                <Text style={$label} tx="swipeToBuy.quantity" />
                <Text style={$boxText} tx="swipeToBuy.boxes" />
                <Text style={$labelRate} tx="swipeToBuy.rate" />
              </View>

              <View style={$rowContainer}>
                <TextInput
                  style={$TextInput}
                  keyboardType="numeric"
                  value={quantity === null ? " " : `${quantity}`}
                  onChangeText={(value) => setQuantity(value)}
                />
                <TextInput
                  style={$TextInput}
                  keyboardType="decimal-pad"
                  value={rate === null ? " " : `${rate}`}
                  onChangeText={(value) => setRate(value)}
                />
              </View>
            </View>
          </View>
        </View>
        <View style={$bottomContainer}>
          <View style={$rowContainer}>
            <Text style={$labelCredit} tx="swipeToBuy.creditPeriod" />
            <Text style={$labelValidity} tx="swipeToBuy.validity" />
          </View>
          <View style={$rowContainer}>
            <Text style={[$dropdown, $dropdownLabel]}>{creditPeriodLabel}</Text>
            {/* <Dropdown
              style={$dropdown}
              data={creditPeriodData}
              labelField="label"
              valueField="value"
              placeholderStyle={$brokerName}
              selectedTextStyle={$containerStyle}
              value={creditPeriod}
              onChange={(item) => {
                setCreditPeriod(item.value)
              }}
            /> */}
            <Dropdown
              style={$dropdown}
              data={validityData}
              labelField="day"
              valueField="value"
              placeholderStyle={$brokerName}
              value={validity}
              onChange={(item) => {
                setValidity(item.value)
              }}
            />
          </View>
        </View>
        <View style={$bottomViewContainer}>
          <Text style={$labelBroker} tx="swipeToBuy.broker" />
          <View style={$rowContainer}>
            <Text style={[$dropdownBroker, $dropdownBrokerLabel]}>{brokerNameLabel}</Text>
            {/* <Dropdown
              style={$dropdownBroker}
              data={broker}
              labelField="broker_name"
              valueField="id"
              placeholderStyle={$brokerName}
              value={brokerName}
              onChange={(item) => {
                setBrokerName(item.id)
              }}
            /> */}
          </View>
        </View>
        <View style={$discontMsg}>
          <Text style={$discontText} tx="swipeToSell.discount" />
        </View>
        {loading ? (
          <ActivityIndicator size="large" />
        ) : (
          <View style={$swipeButton}>
            {item.OOP === null || item.OOQ === null ? (
              <SwipeButton
                disabled={
                  isFieldEmpty(quantity) ||
                  isFieldEmpty(rate) ||
                  isFieldEmpty(creditPeriod) ||
                  isFieldEmpty(validity) ||
                  isFieldEmpty(brokerName)
                }
                onButtonActivate={handleButtonActivate}
              />
            ) : (
              <SwipeToModifyButton
                disabled={
                  isFieldEmpty(quantity) ||
                  isFieldEmpty(rate) ||
                  isFieldEmpty(creditPeriod) ||
                  isFieldEmpty(validity) ||
                  isFieldEmpty(brokerName)
                }
                onButtonModify={handleButtonActivateModify}
              />
            )}
          </View>
        )}
      </View>
    </View>
  )
})

const $dropdownLabel: TextStyle = {
  fontSize: fontSize.p4,
  fontFamily: typography.fonts.inter.medium,
  color: colors.palette.neutral700,
}
const $dropdownBrokerLabel: TextStyle = {
  fontSize: fontSize.p3,
  fontFamily: typography.fonts.inter.medium,
  color: colors.palette.neutral700,
}

const $discontMsg: ViewStyle = {
  position: "relative",
  top: spacing.lg + spacing.lg + spacing.lg,
  paddingHorizontal: spacing.lg + spacing.lg,
  opacity: 0.5,
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
}
const $discontText: TextStyle = {
  fontSize: fontSize.p5,
  fontFamily: typography.fonts.inter.medium,
  color: colors.palette.neutral700,
  lineHeight: 18,
}

const $container: ViewStyle = {
  justifyContent: "center",
}
const $containerStyle: TextStyle = {
  fontSize: spacing.smm,
}
const $blurredContainer: ViewStyle = {
  backgroundColor: "transparent",
  opacity: 0.5,
}
const $topcontainer: ViewStyle = {
  backgroundColor: colors.palette.gray100,
  height: 143,
  paddingHorizontal: spacing.md,
}
const $row: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
}
const $rowContainer: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "center",
}

const $backIcon: ImageStyle = {
  position: "relative",
  marginRight: spacing.sm,
  top: spacing.lg + spacing.mmd,
}

const $swipeTpBuyText: TextStyle = {
  fontSize: fontSize.p3,
  fontFamily: typography.fonts.inter.medium,
  color: colors.palette.neutral700,
  position: "relative",
  top: spacing.lg + spacing.mmd,
}
const $middleContainer: ViewStyle = {
  height: 91,
  elevation: spacing.ssm,
  shadowColor: colors.palette.neutral900,
  shadowOffset: { width: 0, height: 0 },
  shadowOpacity: 0.1,
  shadowRadius: spacing.xxs,
  backgroundColor: colors.palette.neutral100,
  position: "relative",
  top: 70,
}
const $bottomContainer: ViewStyle = {
  position: "relative",
  top: 50,
  height: 95,
}

const $bottomViewContainer: ViewStyle = {
  position: "relative",
  top: 30,
  height: 65,
}

const $TextInput: ViewStyle = {
  borderWidth: 1,
  borderColor: colors.palette.gray400,
  height: 38,
  width: 150,
  padding: spacing.ssm,
}
const $textInputContainer: ViewStyle = {
  position: "relative",
  top: spacing.xxs,
}
const $label: TextStyle = {
  fontFamily: typography.fonts.inter.medium,
  fontSize: fontSize.p4,
  color: colors.palette.neutral700,
  position: "relative",
  right: spacing.lg + spacing.mmd,
}
const $boxText: TextStyle = {
  fontFamily: typography.fonts.inter.medium,
  fontSize: fontSize.p5,
  color: colors.palette.gray200,
  position: "relative",
  right: spacing.lg + spacing.mmd,
}
const $labelRate: TextStyle = {
  fontFamily: typography.fonts.inter.medium,
  fontSize: fontSize.p4,
  color: colors.palette.neutral700,
  position: "relative",
  right: spacing.lg + spacing.lg + spacing.lg + spacing.xxs,
}
const $labelCredit: TextStyle = {
  fontFamily: typography.fonts.inter.medium,
  fontSize: fontSize.p4,
  color: colors.palette.neutral700,
  position: "relative",
  right: spacing.lg + spacing.lg + spacing.ssm,
}
const $labelValidity: TextStyle = {
  fontFamily: typography.fonts.inter.medium,
  fontSize: fontSize.p4,
  color: colors.palette.neutral700,
  position: "relative",
  right: spacing.lg + spacing.mmd,
}
const $labelBroker: TextStyle = {
  fontSize: fontSize.p4,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.medium,
  position: "relative",
  left: spacing.lg + spacing.md,
}
const $swipeButton: ViewStyle = {
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  height: 300,
}
const $dropdown: ViewStyle = {
  height: 38,
  width: 150,
  padding: spacing.ssm,
  backgroundColor: "white",
  borderWidth: 1,
  borderColor: colors.palette.gray400,
}
const $dropdownBroker: ViewStyle = {
  height: 38,
  width: "82%",
  padding: spacing.ssm,
  backgroundColor: "white",
  borderWidth: 1,
  borderColor: colors.palette.gray400,
}
const $brokerName: TextStyle = {
  fontSize: fontSize.p4,
  fontFamily: typography.fonts.inter.normal,
  color: colors.palette.neutral700,
}
