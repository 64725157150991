import * as React from "react"
import {
  ImageStyle,
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  TouchableWithoutFeedback,
  TextInput,
} from "react-native"
import { observer } from "mobx-react-lite"
import { Icon } from "../Icon"
import { FilterList } from "../yarnx/FilterList"
import { colors, fontSize, spacing } from "app/theme"
import { useRef, useState } from "react"
import { FILTER_TYPE, FilterResponseParams } from "app/types"

export interface SearchBarProps {
  style?: StyleProp<ViewStyle>
  callback?: (filters: FilterResponseParams[]) => void
  searchCallback?: (query: string) => void
  data: any[]
}

export const SearchBar = observer(function SearchBar(props: SearchBarProps) {
  const { style, data, searchCallback } = props
  const $styles = [$container, style]
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true)
  const [searchQuery, setSearchQuery] = useState("")
  const textInputRef = useRef<TextInput>(null)
  const [filterApplied, setFilterApplied] = useState(false)

  const clearSearch = () => {
    setSearchQuery("")
    searchCallback("")
    setIsPlaceholderVisible(true)
    if (textInputRef.current) {
      textInputRef.current.blur()
    }
  }

  const handleIconPress = () => {
    if (textInputRef.current) {
      textInputRef.current.focus()
    }
  }

  const handleSearchQueryChange = (query: string) => {
    setSearchQuery(query)
    searchCallback(query)
  }

  const filterRef = useRef()
  const filterListCallback = (res: Array<FilterResponseParams>) => {
    const isOnlyCountFilter = res.length === 1 && res[0].filterType === FILTER_TYPE.COUNT
    if (isOnlyCountFilter) {
      setFilterApplied(false)
    } else {
      setFilterApplied(true)
    }
    props.callback(res)
  }

  const onFocus = () => {
    setIsPlaceholderVisible(false)
  }

  const onBlur = () => {
    if (!searchQuery.trim()) {
      setIsPlaceholderVisible(true)
    }
  }

  return (
    <View style={$styles}>
      <View style={$viewContainer}>
        <Icon
          style={$iconContainer}
          icon={"search"}
          color={colors.palette.gray200}
          size={fontSize.p2}
          onPress={handleIconPress}
        />
        <TextInput
          style={$searchText}
          placeholder={isPlaceholderVisible ? "Search" : ""}
          value={searchQuery}
          onChangeText={handleSearchQueryChange}
          underlineColorAndroid="transparent"
          selectionColor="#595959"
          ref={textInputRef}
          onFocus={onFocus}
          onBlur={onBlur}
          maxLength={25}
        />
        <View style={$iconsContainer}>
          {searchQuery ? (
            <TouchableWithoutFeedback onPress={clearSearch}>
              <Icon
                style={$clearIconContainer}
                icon="close"
                color={colors.palette.gray200}
                size={fontSize.p2}
              />
            </TouchableWithoutFeedback>
          ) : null}
          <TouchableWithoutFeedback onPress={() => filterRef.current.openBottom()}>
            <Icon
              style={$filterContainer}
              icon={"filter"}
              color={filterApplied ? colors.palette.highlight100 : colors.palette.gray200}
              size={fontSize.p2}
            />
          </TouchableWithoutFeedback>
        </View>
      </View>
      <FilterList ref={filterRef} callback={filterListCallback} data={data} />
    </View>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
}
const $viewContainer: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  backgroundColor: colors.palette.neutral100,
  height: 46,
  elevation: 10,
  shadowColor: colors.palette.neutral900,
  shadowOffset: { width: 0, height: 0 },
  shadowOpacity: 0.1,
  shadowRadius: 4,
  marginLeft: spacing.md,
  marginRight: spacing.md,
  zIndex: 1,
  flex: 1,
}
const $iconsContainer: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  marginLeft: spacing.sm,
}

const $searchText: TextStyle | ViewStyle = {
  color: colors.palette.gray200,
  flex: 1,
  height: 44,
  fontSize: fontSize.p2,
}

const $clearIconContainer: ImageStyle = {
  marginRight: spacing.sm,
  width: fontSize.p2,
  height: fontSize.p2,
}

const $filterContainer: ImageStyle = {
  marginRight: spacing.md,
  width: fontSize.p2,
  height: fontSize.p2,
}

const $iconContainer: ImageStyle = {
  margin: spacing.md,
}
