import { observer } from "mobx-react-lite"
import React, { FC, useEffect, useState } from "react"
import { TextStyle, TouchableOpacity, View, ViewStyle, Platform } from "react-native"
import { Text, Icon, Screen } from "../components"
import { AppStackScreenProps } from "../navigators" // @demo remove-current-line
import { colors, fontSize, spacing, typography } from "../theme"
interface WelcomeScreenProps extends AppStackScreenProps<"Welcome"> {}

export const WelcomeScreen: FC<WelcomeScreenProps> = observer(function WelcomeScreen(
  _props, // @demo remove-current-line
) {
  // @demo remove-block-start
  const { navigation } = _props

  function goNext() {
    navigation.navigate("Login")
  }
  function goRegister() {
    navigation.navigate("Register")
  }
  if (Platform.OS === "web") {
    const [url] = useState(window.location.href)
    useEffect(() => {
      if (url) {
        navigation.navigate("/")
      }
    }, [])
  }

  return (
    <Screen
      preset="auto"
      contentContainerStyle={$screenContentContainer}
      safeAreaEdges={["top", "bottom"]}
    >
      <View style={$topContainer}>
        <Text
          testID="welcome-heading"
          tx="welcomeScreen.hello"
          preset="heading"
          style={$welcomeHeading}
        />
        <View style={$welcomecontainer}>
          <Text
            testID="welcome-heading"
            tx="welcomeScreen.welcomeText"
            preset="heading"
            style={$welcomeHeading}
          />
          <Text
            testID="welcome-heading"
            tx="welcomeScreen.redX"
            preset="heading"
            style={$welcomeHeadingredX}
          />
        </View>

        <View style={$border}></View>
        <TouchableOpacity onPress={goNext}>
          <View style={$rowcontainer}>
            <Text testID="welcome-heading" tx="welcomeScreen.login" style={$text} />
            <Icon icon="login" size={fontSize.p1}></Icon>
          </View>
        </TouchableOpacity>
        <View style={$border}></View>
        <TouchableOpacity onPress={goRegister}>
          <View style={$rowcontainer}>
            <Text testID="welcome-heading" tx="welcomeScreen.opennewacc" style={$text} />
            <Icon icon="profile" size={fontSize.p1}></Icon>
          </View>
        </TouchableOpacity>
        <View style={$border}></View>
      </View>
    </Screen>
  )
})

const $screenContentContainer: ViewStyle = {
  flex: 1,
  paddingHorizontal: spacing.lg,
  backgroundColor: colors.background,
}

const $topContainer: ViewStyle = {
  marginTop: 167,
}

const $welcomeHeading: TextStyle = {
  fontFamily: typography.fonts.inter.semiBold,
  fontSize: fontSize.h1,
}

const $welcomecontainer: TextStyle = {
  fontFamily: typography.fonts.inter.semiBold,
  fontSize: fontSize.h1,
  flexDirection: "row",
}

const $welcomeHeadingredX: TextStyle = {
  fontFamily: typography.fonts.inter.semiBold,
  fontSize: fontSize.h1,
  marginBottom: spacing.xxxl,
  color: colors.palette.failure100,
}

const $border: ViewStyle = {
  borderBottomWidth: 1,
  paddingLeft: spacing.lg,
  paddingRight: spacing.lg,
  borderColor: colors.palette.gray200,
}

const $rowcontainer: ViewStyle = {
  paddingTop: spacing.lg,
  paddingBottom: spacing.lg,
  flexDirection: "row",
  justifyContent: "space-between",
}

const $text: TextStyle = {
  fontSize: fontSize.h4,
  fontFamily: typography.fonts.inter.normal,
}
