import * as React from "react"
import {
  StyleProp,
  TextStyle,
  Share,
  ImageStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native"
import { observer } from "mobx-react-lite"
import { colors, fontSize, spacing, typography } from "app/theme"
import { Text } from "app/components/Text"
import { Icon } from "../Icon"

export interface InviteProps {
  navigation:any
  style?: StyleProp<ViewStyle>
  
}

export const Invite = observer(function Invite(props: InviteProps) {
  const { style, navigation } = props
  const $styles = [$container, style]
  const share = async () => {
    try {
      const result = await Share.share({
        message:
          "Download the Yarn eXchange (YarnX) app to buy/sell Yarn on an exchange.\n Step1: Use the below given link to download the app. \n Step 2: Register using you E-mail Id.\n Download the app now! https://play.google.com/store/apps/details?id=com.trading.yarnx",
      })
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      alert(error.message)
    }
  }
  return (
    <View style={$styles}>
      <View style={$inviteContainer}>
        <View style={$rowcontainer}>
          <Icon icon="back" size={24} color={colors.text} onPress={()=>navigation.goBack()}/>
          <Text style={$text} tx="Invite.invite"></Text>
         </View>
      </View>
      <Icon style={$icon} icon="congrats" size={100}></Icon>
      <Text style={$textInfo} tx="Invite.invitemsg"></Text>
      <TouchableOpacity activeOpacity={0.7} style={$shareContainer} onPress={share}>
        <Text style={$shareText} tx="Invite.share"></Text>
      </TouchableOpacity>
    </View>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
  marginHorizontal: spacing.md,
}

const $text: TextStyle = {
  marginBottom: spacing.ssm,
  fontSize: fontSize.h2,
  marginLeft: spacing.md,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.bold,
}
const $textInfo: TextStyle = {
  fontFamily: typography.primary.bold,
}

const $icon: ImageStyle = {
  alignSelf: "center",
  marginVertical: spacing.xll,
}

const $shareContainer: ViewStyle = {
  backgroundColor: colors.palette.highlight100,
  borderRadius: spacing.xxs,
  marginTop: spacing.xl,
}

const $shareText: TextStyle = {
  color: colors.palette.neutral100,
  fontSize: fontSize.p3,
  padding: spacing.smm,
  textAlign: "center",
  fontFamily: typography.fonts.inter.bold,
}
const $inviteContainer: ViewStyle = {
  flexDirection: "row",
  marginTop: spacing.xll,
  
}
const $rowcontainer: ViewStyle = {
  flexDirection: "row",
  marginHorizontal: spacing.md,
}