import React, { FC, useEffect, useState } from "react"
import { ViewStyle, StatusBar } from "react-native"
import { Screen } from "../components"
import { TabScreenProps } from "../navigators/AppCoreNavigator"
import { Account } from "app/components/yarnx/Account"
import { api } from "app/services/api"
import { colors } from "app/theme"

export const AccountScreen: FC<TabScreenProps<"Account">> = function AccountScreen(_props) {
  const { navigation } = _props
  const [accountData, setAccountData] = useState([])
  const [supportData, setSupportData] = useState([])
  const [othersData, setOthersData] = useState([])

  async function fetchAccount() {
    try {
      const response = await api.getAccountData()
      if (response.kind === "ok") {
        const listData: any = response.data
        setAccountData(listData)
      } else if (response.kind === "bad-data") {
        alert("Something went wrong, Please try again")
        navigation.navigate("Account")
      }
    } catch (error) {}
  }

  async function fetchSupport() {
    try {
      const response = await api.getSupportsData()
      if (response.kind === "ok") {
        const listData: any = response.data
        setSupportData(listData)
      } else if (response.kind === "bad-data") {
        alert("Something went wrong, Please try again")
        navigation.navigate("Account")
      }
    } catch (error) {}
  }
  async function fetchOthers() {
    try {
      const response = await api.getOthersData()
      if (response.kind === "ok") {
        const listData: any = response.data
        setOthersData(listData)
      } else if (response.kind === "bad-data") {
        alert("Something went wrong, Please try again")
        navigation.navigate("Account")
      }
    } catch (error) {}
  }

  useEffect(() => {
    fetchAccount()
    fetchSupport()
    fetchOthers()
  }, [])

  return (
    <Screen preset="scroll" safeAreaEdges={["top"]} contentContainerStyle={$container}>
      <StatusBar backgroundColor={colors.palette.gray100} />
      <Account
        navigation={navigation}
        accountData={accountData}
        supportData={supportData}
        othersData={othersData}
      />
    </Screen>
  )
}

const $container: ViewStyle = {}

// @demo remove-file
