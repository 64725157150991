import { observer } from "mobx-react-lite"
import React, { FC, useEffect, useState } from "react"
import {
  ImageStyle,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
  ActivityIndicator,
  Platform,
} from "react-native"
import { Icon, Screen, Text } from "../components"
import { useStores } from "../models"
import { TextInput, IconButton } from "@react-native-material/core"
import { AppStackScreenProps } from "../navigators"
import { colors, fontSize, spacing, typography } from "../theme"
import I18n from "i18n-js"
import { api } from "app/services/api"
import * as Notifications from "expo-notifications"
import messaging from "@react-native-firebase/messaging"

interface LoginScreenProps extends AppStackScreenProps<"Login"> {}
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
})
export const LoginScreen: FC<LoginScreenProps> = observer(function LoginScreen(_props) {
  const { navigation } = _props

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [attemptsCount, setAttemptsCount] = useState(0)
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState()
  if (Platform.OS === "web") {
    const [url] = useState(window.location.href)
    useEffect(() => {
      if (url) {
        navigation.navigate("/")
      }
    }, [])
  }

  if (Platform.OS === "android") {
    PushNotification = require("react-native-push-notification").default
    async function requestUserPermission() {
      const authStatus = await messaging().requestPermission()
      const enabled =
        authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
        authStatus === messaging.AuthorizationStatus.PROVISIONAL

      if (enabled) {
        console.log("Authorization status:", authStatus)
      }
    }

    useEffect(() => {
      if (requestUserPermission()) {
        messaging()
          .getToken()
          .then((fcmToken) => {
            setToken(fcmToken)
          })
      }
    }, [])
    useEffect(() => {
      const unsubscribe = messaging().onMessage(async () => {
        PushNotification.localNotification({
          channelId: "yarnX_channel_id",
          title: "Android App",
          message: "Test Body", // Use "message" instead of "body"
          soundName: "default",
          vibrate: true,
          playSound: true,
        })
      })

      return unsubscribe
    }, [])
  }

  const {
    authenticationStore: {
      authEmail,
      setAuthEmail,
      authPassword,
      setAuthPassword,
      setAuthToken,
      validationEmail,
      validationPassword,
      setAuthRefreshToken,
      setAuthName,
      setRole,
      setName,
      setphoneNumber,
      setWhatsAppNumber,
      setapprovalStatus,
      setId,
    },
  } = useStores()

  async function getLoginUsers() {
    setIsSubmitted(true)
    setAttemptsCount(attemptsCount + 1)
    if (validationEmail) return
    try {
      setLoading(true)
      const response = await api.getLoginData(authEmail, authPassword, token)
      if (response.kind === "ok") {
        const listData: any = response.data.data
        if (response.data.code === 200) {
          setIsSubmitted(false)
          setLoading(false)
          setAuthPassword("")
          setAuthEmail(listData.user.email)
          setAuthName(listData.user.name)
          setRole(listData.user.role)
          setName(listData.user.name)
          setId(listData.user.id)
          setphoneNumber(listData.user.phoneNumber)
          setWhatsAppNumber(listData.user.whatsappNumber)
          setapprovalStatus(listData.user.approvalStatus)
          setAuthToken(listData.tokens.access.token)
          setAuthRefreshToken(listData.tokens.refresh.token)
          api.getToken(listData.tokens.access.token)
          alert(response.data.message)
        } else {
          alert("Invalid email or password")
          setLoading(false)
        }
      } else {
        alert("Invalid email or password")
        setLoading(false)
      }
    } catch (error) {
      alert("Something went wrong, Please try again")
      setLoading(false)
    }
  }

  useEffect(() => {
    setAuthEmail(authEmail)
    setAuthPassword(authPassword)
  }, [])

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const error = isSubmitted ? validationEmail : ""
  const error2 = isSubmitted ? validationPassword : ""

  function login() {
    getLoginUsers()
  }

  function goPrevious() {
    navigation.navigate("Welcome")
  }

  return (
    <Screen
      preset="auto"
      contentContainerStyle={$screenContentContainer}
      safeAreaEdges={["top", "bottom"]}
    >
      <View style={$backButton}>
        <TouchableOpacity>
          <Icon
            onPress={() => goPrevious()}
            icon="back"
            color={colors.palette.neutral900}
            size={fontSize.p1}
          />
        </TouchableOpacity>
      </View>

      <Text testID="login-heading" tx="loginScreen.login" preset="heading" style={$signIn} />

      <TextInput
        style={$input}
        label={I18n.translate("loginScreen.usernameLabel")}
        color={colors.palette.gray200}
        variant="outlined"
        value={authEmail}
        onChangeText={(value) => setAuthEmail(value)}
        trailing={(props) => (
          <IconButton icon={(props) => <Icon icon="account" {...props} />} {...props} />
        )}
      />
      <Text style={$error}>{error}</Text>
      <TextInput
        style={$input}
        label={I18n.t("loginScreen.passwordLabel")}
        color={colors.palette.gray200}
        variant="outlined"
        value={authPassword}
        onChangeText={(value) => setAuthPassword(value)}
        secureTextEntry={!showPassword}
        trailing={(props) => (
          <IconButton
            icon={(props) => <Icon icon={showPassword ? "view" : "hide"} {...props} />}
            {...props}
            onPress={togglePasswordVisibility}
          />
        )}
      />
      <Text style={$error}>{error2}</Text>
      {loading ? (
        <ActivityIndicator size="large" />
      ) : (
        <TouchableOpacity style={$buttonContainer} onPress={login}>
          
          <Text style={$buttontext} tx="loginScreen.loginCaps"></Text>
        </TouchableOpacity>
      )}

      <TouchableOpacity>
        <Text tx="loginScreen.forgotUsername" style={$forgotusername}></Text>
      </TouchableOpacity>
    </Screen>
  )
})

const $screenContentContainer: ViewStyle = {
  paddingVertical: spacing.xxl,
  paddingHorizontal: spacing.lg,
}

const $signIn: TextStyle = {
  marginBottom: spacing.lg,
  fontSize: fontSize.h2,
  fontFamily: typography.fonts.inter.bold,
}

const $backButton: ViewStyle = {
  marginBottom: spacing.xxl,
  marginLeft: spacing.mxxs,
  paddingRight: spacing.md,
  paddingBottom: spacing.md,
}

const $input: ViewStyle | ImageStyle | TextStyle = {
  marginTop: spacing.mdd,
}

const $forgotusername: TextStyle = {
  marginTop: spacing.xl,
  color: colors.palette.highlight100,
  textAlign: "right",
  fontSize: fontSize.p3,
}

const $buttonContainer: ViewStyle = {
  backgroundColor: colors.palette.highlight100,
  borderRadius: spacing.xxs,
  marginTop: spacing.mdd,
}

const $buttontext: TextStyle = {
  color: colors.palette.neutral100,
  fontSize: fontSize.p3,
  padding: spacing.md,
  textAlign: "center",
}

const $error: TextStyle = {
  color: colors.palette.failure100,
  fontSize: fontSize.p5,
}
