import React, { useState } from "react"
import {
  ImageStyle,
  StyleProp,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
  ActivityIndicator,
} from "react-native"
import { observer } from "mobx-react-lite"
import { colors, fontSize, spacing, typography } from "app/theme"
import { Text } from "app/components/Text"
import { TextInput } from "@react-native-material/core"
import I18n from "i18n-js"
import { api } from "app/services/api"
import { useStores } from "app/models"

export interface ManageProfileProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  navigation: any
}

/**
 * Describe your component here
 */
export const ManageProfile = observer(function ManageProfile(props: ManageProfileProps) {
  const { style, navigation } = props
  const $styles = [$container, style]
  const {
    authenticationStore: { authToken, name, authEmail, phoneNumber, id, whatsappNumber },
  } = useStores()
  const [fname, setfName] = useState(name)
  const [email, setEmail] = useState(authEmail)
  const [number, setNumber] = useState(phoneNumber)
  const [whatapp, setWhatapp] = useState(whatsappNumber)
  const [loading, setLoading] = useState(false)
  const goPrevious = () => {
    navigation.navigate("ProfileScreen")
  }

  function submitProfileDetails() {
    try {
      setLoading(true)
      api
        .postProfileData(fname, email, parseInt(number), parseInt(whatapp), id, authToken)
        .then((response) => {
          if (response.kind === "ok") {
            const listData: any = response
            if (listData.data.code === 200) {
              navigation.navigate("Account")
              alert(listData.data.message)
              setLoading(false)
            } else {
              alert("User not updated successfully")
              setLoading(false)
            }
          } else if (response.kind === "bad-data") {
            alert("Something went wrong, Please try again")
            setLoading(false)
            navigation.navigate("Account")
          }
        })
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <View style={$styles}>
      <View style={$ProfileContainer}>
        <Text style={$Profiletext} tx="Profile.profile"></Text>
      </View>
      <TextInput
        style={$input}
        label={I18n.translate("Profile.name")}
        color={colors.palette.gray200}
        variant="outlined"
        value={fname}
        onChangeText={(value) => setfName(value)}
      />
      <TextInput
        style={$input}
        label={I18n.translate("Profile.emailLabel")}
        color={colors.palette.gray200}
        variant="outlined"
        value={email}
        keyboardType="email-address"
        onChangeText={(value) => setEmail(value)}
      />
      <TextInput
        style={$input}
        label={I18n.translate("Profile.phoneLabel")}
        color={colors.palette.gray200}
        variant="outlined"
        value={number}
        keyboardType="numeric"
        maxLength={10}
        onChangeText={(value) => setNumber(value)}
      />

      <TextInput
        style={$input}
        label={I18n.translate("Profile.whatsappLabel")}
        color={colors.palette.gray200}
        variant="outlined"
        value={whatapp}
        keyboardType="numeric"
        maxLength={10}
        onChangeText={(value) => setWhatapp(value)}
      />
      <View style={$buttoncontainer}>
        {loading ? (
          <ActivityIndicator size="large" />
        ) : (
          <View>
            <TouchableOpacity
              activeOpacity={0.8}
              style={$submitbutton}
              onPress={submitProfileDetails}
            >
              <Text style={$submitbuttonText} tx="Profile.submit"></Text>
            </TouchableOpacity>
          </View>
        )}
        <View style={$canclebuttonContainer}>
          <TouchableOpacity activeOpacity={0.8} style={$cancelbutton} onPress={goPrevious}>
            <Text style={$cancelbuttonText} tx="Profile.cancel"></Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
})

const $container: ViewStyle = {
  marginHorizontal: spacing.md,
}
const $ProfileContainer: ViewStyle = {
  flexDirection: "row",
  marginTop: spacing.xll,
  marginBottom: spacing.xll,
}
const $Profiletext: TextStyle = {
  fontSize: fontSize.h2,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.bold,
}
const $input: ViewStyle | ImageStyle | TextStyle = {
  marginBottom: spacing.smm,
}

const $buttoncontainer: ViewStyle = {
  flexDirection: "row",
}
const $submitbutton: ViewStyle = {
  width: spacing.xxxxl,
  backgroundColor: colors.palette.highlight100,
  borderRadius: spacing.xxs,
}
const $submitbuttonText: TextStyle = {
  color: colors.palette.neutral100,
  fontSize: fontSize.p3,
  padding: spacing.ssm,
  textAlign: "center",
}
const $canclebuttonContainer: ViewStyle = {
  marginLeft: spacing.md,
}
const $cancelbutton: ViewStyle = {
  width: spacing.xxxxl,
  borderWidth: 1,
  borderColor: colors.palette.gray200,
  backgroundColor: colors.palette.neutral100,
  borderRadius: spacing.xxs,
}
const $cancelbuttonText: TextStyle = {
  color: colors.palette.gray300,
  fontSize: fontSize.p3,
  padding: spacing.ssm,
  textAlign: "center",
}
