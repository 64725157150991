export class WatchListItem {
  yarnId: number
  name: string
  count: number
  mill: string
  variety: string
  weightPerBag: number
  conePerBag: number
  YEP: number
  YEQ: number
  YET: string
  TEP: number
  TEQ: number
  TET: string
  OOQ: number
  OOP: number
  OOT: string
  OOI: number
  creditDays: number
  brokerId: string
  refValue: number
  groupNo: number;
  constructor({
    yarnId,
    name,
    count,
    mill,
    variety,
    weightPerBag,
    conePerBag,
    YEP,
    YEQ,
    YET,
    TEP,
    TEQ,
    TET,
    OOQ,
    OOP,
    OOT,
    OOI,
    creditDays,
    brokerId,
    refValue,
    groupNo,
  }: {
    yarnId: number
    name: string
    count: number
    mill: string
    variety: string
    weightPerBag: number
    conePerBag: number
    YEP: number
    YEQ: number
    YET: string
    TEP: number
    TEQ: number
    TET: string
    OOQ: number
    OOP: number
    OOT: string
    OOI: number
    creditDays: number
    brokerId: string
    refValue: number
    groupNo:number;
  }) {
    this.yarnId = yarnId
    this.name = name
    this.count = count
    this.mill = mill
    this.variety = variety
    this.weightPerBag = weightPerBag
    this.conePerBag = conePerBag
    this.YEP = YEP
    this.YEQ = YEQ
    this.YET = YET
    this.TEP = TEP
    this.TEQ = TEQ
    this.TET = TET
    this.OOQ = OOQ
    this.OOP = OOP
    this.OOT = OOT
    this.OOI = OOI
    this.creditDays = creditDays
    this.brokerId = brokerId
    this.refValue = refValue
    this.groupNo = groupNo;
  }
}
