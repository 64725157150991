export const fontSize = {
  h1: 28,
  h2: 24,
  h3: 20,
  h4: 18,
  p1: 18,
  p2: 16,
  p3: 14,
  p4: 12,
  p5: 10,
  p6: 8,
} as const

export type FontSize = keyof typeof fontSize
