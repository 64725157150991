export interface FilterResponseParams {
  filterType: FILTER_TYPE
  data?: any
}

export enum FILTER_TYPE {
  COUNT = "count",
  YARN_TYPE = "yarnType",
  SORT_ALPHABETICALLY = "sortAlphabetically",
  CLEAR = "clear",
  SORRT_COUNT = "sortByCount",
  QUERY = "query",
}
