import React, { ErrorInfo } from "react"
import { TouchableOpacity, TextStyle, View, ViewStyle } from "react-native"
import { Text } from "../../components"
import { colors, spacing, fontSize, typography } from "../../theme"
export interface ErrorDetailsProps {
  error: Error
  errorInfo: ErrorInfo
  onReset(): void
}

export function ErrorDetails(props: ErrorDetailsProps) {
  const handleGoToHome = async () => {
    navigation.navigate("/")
  }
  return (
    <View style={$container}>
      <TouchableOpacity onPress={handleGoToHome} style={$buyButton}>
        <Text style={$buyText}>Go To Home</Text>
      </TouchableOpacity>
    </View>
  )
}

const $container: ViewStyle = {
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
}
const $buyText: TextStyle = {
  color: colors.palette.neutral100,
  fontSize: fontSize.p3,
  fontFamily: typography.fonts.inter.semiBold,
}
const $buyButton: TextStyle = {
  backgroundColor: colors.palette.highlight100,
  color: colors.palette.neutral100,
  height: spacing.xxl + spacing.xxxs,
  width: "65%",
  margin: spacing.xxs + spacing.xxxs,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: spacing.xxs,
}
