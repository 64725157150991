import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle } from "react-native"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { AppStackScreenProps } from "app/navigators"
import { Screen } from "app/components"
import { Invite } from "app/components/yarnx"
import { useNavigation } from "@react-navigation/native"
// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "app/models"

interface InviteFriendsScreenProps
  extends NativeStackScreenProps<AppStackScreenProps<"InviteFriends">> {}

export const InviteFriendsScreen: FC<InviteFriendsScreenProps> = observer(
  function InviteFriendsScreen(_props) {

    const { navigation } = _props

    // Pull in one of our MST stores
    // const { someStore, anotherStore } = useStores()

    // Pull in navigation via hook
    
    return (
      <Screen style={$root} preset="scroll" safeAreaEdges={["top"]}>
        <Invite navigation={navigation}></Invite>
      </Screen>
    )
  },
)

const $root: ViewStyle = {
  flex: 1,
}
