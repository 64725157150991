import React, { useState, useEffect } from "react"
import {
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  TouchableWithoutFeedback,
  FlatList,
  RefreshControl,
  ActivityIndicator,
} from "react-native"
import { setTime, setDate, currectTime, expireTime } from "../../services/constants/constant.js"
import { observer } from "mobx-react-lite"
import { colors, fontSize, spacing, typography } from "app/theme"
import { Text } from "app/components/Text"
import { BottomSheet, CountdownTimer, SearchBar } from "../yarnx"
import { FILTER_TYPE, FilterResponseParams } from "app/types"
import { useStores } from "app/models"
export interface OpenOrderProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  openOrderData?: any[]
  deletedOrderData?: any[]
  excutedOrderDatas?: any[]
  openOrder?: any[]
  navigation: any
}

/**
 * Describe your component here
 */
export const OpenOrder = observer(function OpenOrder(props: OpenOrderProps) {
  const { style, openOrderData, navigation } = props
  const $styles = [$container, style]
  const [originalOpenOrder, setOriginalOpenOrder] = useState([])
  const [filteredOpenOrderData, setFilteredOpenOrderData] = useState(originalOpenOrder)
  const [showBottomSheet, setShowBottomSheet] = useState<string>("")
  const [refreshing, setRefreshing] = React.useState(false)
  const [loading, setLoading] = useState(true)
  const {
    authenticationStore: { role },
  } = useStores()
  useEffect(() => {
    if (openOrderData) {
      setOriginalOpenOrder(openOrderData)
      setFilteredOpenOrderData(openOrderData)
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }
  }, [openOrderData])

  const bottomSheetCallback = (response) => {
    if (response.actionType === "BottomSheetClose") {
      setShowBottomSheet("")
    } else if (response.actionType === "UpdateYarnId") {
      navigation.navigate("WatchList", {
        yarnId: response.yarnId,
      })
    }
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true)
    setTimeout(() => {
      setRefreshing(false)
    }, 2000)
  }, [])

  const searchBarCallback = (resp: Array<FilterResponseParams>) => {
    let clonedOriginalFilteredData = JSON.parse(JSON.stringify(originalOpenOrder))
    // eslint-disable-next-line array-callback-return
    resp.map((response) => {
      switch (response.filterType) {
        case FILTER_TYPE.SORT_ALPHABETICALLY: {
          if (response.data) {
            if (!filteredOpenOrderData[0]) {
              clonedOriginalFilteredData = clonedOriginalFilteredData.sort((a, b) =>
                a.mill.localeCompare(b.mill),
              )
            } else {
              clonedOriginalFilteredData = clonedOriginalFilteredData.sort((a, b) =>
                a.mill.localeCompare(b.mill),
              )
            }
          }
          break
        }

        case FILTER_TYPE.COUNT: {
          if (response.data) {
            if (!response.data[0]) {
              break
            }
            clonedOriginalFilteredData = clonedOriginalFilteredData.filter((e) => {
              return e.count >= response.data[0] && e.count <= response.data[1]
            })
          }
          break
        }

        case FILTER_TYPE.YARN_TYPE: {
          if (response.data) {
            if (!response.data[0]) {
              break
            }
            clonedOriginalFilteredData = clonedOriginalFilteredData.filter((e) =>
              response.data.includes(e.variety),
            )
          }
          break
        }

        case FILTER_TYPE.SORRT_COUNT: {
          if (response.data) {
            if (!filteredOpenOrderData[0]) {
              clonedOriginalFilteredData = clonedOriginalFilteredData.sort(
                (a, b) => a?.count - b?.count,
              )
            } else {
              clonedOriginalFilteredData = clonedOriginalFilteredData.sort(
                (a, b) => a?.count - b?.count,
              )
            }
          }
          break
        }

        case FILTER_TYPE.CLEAR: {
          clonedOriginalFilteredData = originalOpenOrder
          break
        }
        default: {
          break
        }
      }
      setFilteredOpenOrderData(clonedOriginalFilteredData)
    })
  }

  const isCountDownTimer = (item) => {
    const finalTime = expireTime(item.expireAt) - currectTime()
    return parseInt(finalTime / 1000)
  }
  return (
    <View style={$backContainer}>
      <View style={$styles}>
        <View style={$searchBar}>
          <SearchBar callback={searchBarCallback} data={originalOpenOrder} />
          <View style={$searchBarContainer} />
        </View>
        <View style={$topContainer} />
        {loading ? (
          <>
            <ActivityIndicator style={$activityIndicator} size="large" />
            <View style={$noDataContainer}>
              <Text style={$noDataText}> </Text>
            </View>
          </>
        ) : filteredOpenOrderData?.length > 0 ? (
          <FlatList
            data={filteredOpenOrderData}
            keyExtractor={(item) => item.OOI + item.expireAt}
            renderItem={({ item }) => (
              <TouchableWithoutFeedback onPress={() => setShowBottomSheet(item.yarnId.toString())}>
                <View>
                  {String(item.yarnId) === showBottomSheet ? (
                    <BottomSheet
                      item={item}
                      callback={bottomSheetCallback}
                      showBottomSheet={showBottomSheet}
                      setShowBottomSheet={setShowBottomSheet}
                      navigation={navigation}
                      showMoreData={0}
                      data={[]}
                    />
                  ) : null}
                  <View style={$touchContainer}>
                    <View style={$row}>
                      <Text style={$text}>
                        <Text style={$textOrders} tx="ordersScreen.orderNo" />
                        {item.OOI}
                      </Text>
                      <Text style={$text}>
                        {setTime(item.createdAt)} {setDate(item.createdAt)}
                      </Text>
                    </View>
                    <View style={$row}>
                      <Text style={$subTextRate}>{item.name}</Text>
                      <Text style={$textSuccess}>
                        ₹{item.OOP} ({item.OOQ} boxes)
                      </Text>
                    </View>
                    <View style={$row}>
                      {role === "buyer" ? (
                        <Text style={$brokerName}>{item.brokername}</Text>
                      ) : (
                        <Text style={$brokerName} />
                      )}

                      {isCountDownTimer(item) > 0 ? (
                        <React.Fragment>
                          <CountdownTimer
                            style={$countDownTimer}
                            initialTime={isCountDownTimer(item)}
                          />
                        </React.Fragment>
                      ) : (
                        <Text style={$iconCountDownTimer} tx="ordersScreen.expired" />
                      )}
                    </View>
                    <View style={$line} />
                  </View>
                </View>
              </TouchableWithoutFeedback>
            )}
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
            style={$bottomContainer}
          />
        ) : (
          <View style={$noDataContainer}>
            <Text style={$noDataText} tx="AlertsScreen.noData" />
          </View>
        )}
      </View>
    </View>
  )
})
const $backContainer: ViewStyle = {
  backgroundColor: colors.palette.neutral100,
  height: "100%",
}
const $activityIndicator: ViewStyle = {
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  zIndex: 3,
}
const $searchBarContainer: ViewStyle = {
  backgroundColor: colors.palette.neutral100,
  height: 22,
  position: "relative",
  bottom: spacing.mmd,
  zIndex: -1,
}
const $noDataContainer: ViewStyle = {
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
}

const $noDataText: TextStyle = {
  fontSize: fontSize.h4,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.bold,
  opacity: 0.2,
  zIndex: 3,
}
const $container: ViewStyle = {
  justifyContent: "center",
  backgroundColor: colors.palette.neutral100,
}
const $touchContainer: ViewStyle = {
  height: 67,
  backgroundColor: colors.palette.neutral100,
}
const $searchBar: ViewStyle = {
  zIndex: 1,
  backgroundColor: colors.palette.gray100,
  height: 38,
}
const $topContainer: ViewStyle = {
  backgroundColor: colors.palette.gray100,
}
const $countDownTimer: ViewStyle = {
  paddingHorizontal: spacing.mmd,
  position: "relative",
  bottom: spacing.ssm,
}
const $iconCountDownTimer: ViewStyle = {
  paddingHorizontal: spacing.mmd,
  position: "relative",
  bottom: spacing.ssm,
  fontFamily: typography.primary.normal,
  fontSize: fontSize.p4,
  color: colors.palette.failure100,
}
const $bottomContainer: ViewStyle = {
  backgroundColor: colors.palette.neutral100,
}
const $brokerName: TextStyle = {
  fontFamily: typography.fonts.inter.normal,
  fontSize: fontSize.p4,
  color: colors.palette.gray300,
  paddingHorizontal: spacing.md,
  position: "relative",
  bottom: spacing.xs,
}
const $row: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: colors.palette.neutral100,
  width: "100%",
}
const $textSuccess: TextStyle = {
  fontSize: fontSize.p3,
  color: colors.palette.highlight100,
  fontFamily: typography.fonts.inter.normal,
  paddingHorizontal: spacing.md,
}
const $text: TextStyle = {
  fontFamily: typography.fonts.inter.normal,
  fontSize: fontSize.p5,
  color: colors.palette.gray300,
  paddingHorizontal: spacing.md,
  position: "relative",
  top: spacing.xs,
}
const $textOrders: TextStyle = {
  fontFamily: typography.fonts.inter.normal,
  fontSize: fontSize.p5,
  color: colors.palette.gray300,
  marginRight: spacing.xxxs,
}
const $line: ViewStyle = {
  height: 1,
  width: "100%",
  backgroundColor: colors.palette.gray100,
  position: "relative",
  bottom: spacing.xxxs + spacing.xxs,
}

const $subTextRate: TextStyle = {
  fontSize: fontSize.p3,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.medium,
  paddingHorizontal: spacing.md,
}
