export const WatchlistData = [
  {
    id: "1",
    title: "67 KW Sanathan",
    kg: "(45.36)",
    subTitle: "15 boxes at ₹ 430",
    rate: "₹450",
    subRate: "0",
    type: "KW",
    count: "67",
    mill: "Sanathan",
  },
  {
    id: "2",
    title: "61 CW Patil",
    kg: "(45.36)",
    subTitle: "15 boxes at ₹ 430",
    rate: "₹450",
    subRate: "1",
    type: "CW",
    count: "61",
    mill: "Patil",
  },
  {
    id: "3",
    title: "68 CCW RV Mill",
    kg: "(45.36)",
    rate: "₹450",
    subRate: "30",
    type: "CCW",
    count: "68",
    mill: "RV Mill",
  },
  {
    id: "4",
    title: "69 KW Patil",
    kg: "(45.36)",
    subTitle: "15 boxes at ₹ 430",
    rate: "₹450",
    subRate: "20",
    type: "KW",
    count: "69",
    mill: "Patil",
  },
  {
    id: "5",
    title: "47 CW Sanathan",
    kg: "(45.36)",
    rate: "₹450",
    subRate: "-13",
    type: "CW",
    count: "47",
    mill: "Sanathan",
  },
  {
    id: "6",
    title: "98 CW Sanathan",
    kg: "(45.36)",
    subTitle: "15 boxes at ₹ 430",
    rate: "₹450",
    subRate: "10",
    type: "CW",
    count: "98",
    mill: "Sanathan",
  },
  {
    id: "7",
    title: "98 CW Sanathan",
    kg: "(45.36)",
    subTitle: "15 boxes at ₹ 430",
    rate: "₹450",
    subRate: "10",
    type: "CW",
    count: "98",
    mill: "Sanathan",
  },
  {
    id: "8",
    title: "64 CW Sanathan",
    kg: "(45.36)",
    subTitle: "15 boxes at ₹ 430",
    rate: "₹450",
    subRate: "-10",
    type: "CW",
    count: "64",
    mill: "Sanathan",
  },
  {
    id: "9",
    title: "64 CCW Sanathan",
    kg: "(45.36)",
    subTitle: "15 boxes at ₹ 430",
    rate: "₹450",
    subRate: "-10",
    type: "CCW",
    count: "64",
    mill: "Sanathan",
  },
  {
    id: "10",
    title: "63 CCW Sanathan",
    kg: "(45.36)",
    subTitle: "15 boxes at ₹ 430",
    rate: "₹450",
    subRate: "-10",
    type: "CCW",
    count: "63",
    mill: "Sanathan",
  },
  {
    id: "11",
    title: "62 OE Sanathan",
    kg: "(45.36)",
    subTitle: "15 boxes at ₹ 430",
    rate: "₹450",
    subRate: "-10",
    type: "OE",
    count: "62",
    mill: "Sanathan",
  },
  {
    id: "12",
    title: "69 KCW Sanathan",
    kg: "(45.36)",
    subTitle: "15 boxes at ₹ 430",
    rate: "₹450",
    subRate: "-10",
    type: "KCW",
    count: "69",
    mill: "Sanathan",
  },
  {
    id: "13",
    title: "699 KW Sanathan",
    kg: "(45.36)",
    subTitle: "15 boxes at ₹ 430",
    rate: "₹450",
    subRate: "-10",
    type: "KW",
    count: "69",
    mill: "Sanathan",
  },
]

export const tableData = [
  {
    id: "1",
    buy: "360",
    buyOrders: "120",
    buyQty: "12",
    sell: "360",
    sellOrders: "120",
    sellQty: "12",
  },
  {
    id: "2",
    buy: "360",
    buyOrders: "120",
    buyQty: "12",
    sell: "360",
    sellOrders: "120",
    sellQty: "12",
  },
  {
    id: "3",
    buy: "360",
    buyOrders: "120",
    buyQty: "12",
    sell: "360",
    sellOrders: "120",
    sellQty: "12",
  },
  {
    id: "4",
    buy: "360",
    buyOrders: "120",
    buyQty: "12",
    sell: "360",
    sellOrders: "120",
    sellQty: "12",
  },
]

export const accoutData = [
  {
    id: "1",
    profile: "Profile",
    icon: "profile",
  },
  {
    id: "2",
    profile: "Firm Details",
    icon: "yourOrder",
  },
  {
    id: "3",
    profile: "Blocklist",
    icon: "block",
  },
  {
    id: "4",
    profile: "Logout",
    icon: "logout",
  },
]
export const supportData = [
  {
    id: "1",
    contact: "Contact",
    icon: "contact",
  },
]
export const othersData = [
  {
    id: "1",
    invite: "Invite Friends",
    icon: "inviteFriend",
  },
  {
    id: "2",
    invite: "Terms of Use",
    icon: "licenses",
  },
]

export const AlertData = [
  {
    id: "1",
    day: "Today",
    data: "Congratulations! Your order has been matched! do eiusmod tempor incididgna aliqua.ad minim veniam, quis nostrud exercitation olore eu fugiat",
    icon: "congrats",
  },
  {
    id: "2",
    day: "2 days ago",
    data: "Alert! Quality xyz has reached the rate of Rs.48.00 do eiusmod tempor incididgna aliqua.ad minim veniam, quis Hurry up, place the order.",
    icon: "alertX",
  },
  {
    id: "3",
    day: "5 days ago",
    data: "Successfully executed 10 order today! do eiusmod tempor incididgna aliqua.ad minim veniam, quis nostrud exercitation olore eu fugiat",
    icon: "thumb",
  },
]

export const OpenOrder = [
  {
    id: "1",
    orderNo: "Order no. 123456",
    time: "10:36 am 28/05/23",
    qualityName: "611 CCW Sanathan",
    Rate: "₹450 (15 boxes)",
    brokerName: "Harshad Mehta",
    broName: "Broker Name:",
    buyName: "Buyer Name:",
    buyRate: " 9000090000",
    type: "CCW",
    count: "611",
    mill: "Sanathan",
  },
  {
    id: "2",
    orderNo: "Order no. 123456",
    time: "10:36 am 28/05/23",
    qualityName: "61 CW Patil",
    Rate: "₹450 (15 boxes)",
    brokerName: "Harshad Mehta",
    broName: "Broker Name:",
    buyName: "Buyer Name:",
    buyRate: " 9000090000",
    type: "CW",
    count: "61",
    mill: "Patil",
  },
  {
    id: "3",
    orderNo: "Order no. 123456",
    time: "10:36 am 28/05/23",
    qualityName: "62 CW Sanathan",
    Rate: "₹450 (15 boxes)",
    brokerName: "Harshad Mehta",
    broName: "Broker Name:",
    buyName: "Buyer Name:",
    buyRate: " 9000090000",
    type: "CW",
    count: "62",
    mill: "Sanathan",
  },
  {
    id: "4",
    orderNo: "Order no. 123456",
    time: "10:36 am 28/05/23",
    qualityName: "65 CCW Akshay",
    Rate: "₹450 (15 boxes)",
    brokerName: "Harshad Mehta",
    broName: "Broker Name:",
    buyName: "Buyer Name:",
    buyRate: " 9000090000",
    type: "CCW",
    count: "65",
    mill: "Akshay",
  },
  {
    id: "5",
    orderNo: "Order no. 123456",
    time: "10:36 am 28/05/23",
    qualityName: "64 KW Sanathan",
    Rate: "₹450 (15 boxes)",
    brokerName: "Harshad Mehta",
    broName: "Broker Name:",
    buyName: "Buyer Name:",
    buyRate: " 9000090000",
    type: "KW",
    count: "64",
    mill: "Sanathan",
  },
  {
    id: "6",
    orderNo: "Order no. 123456",
    time: "10:36 am 28/05/23",
    qualityName: "70 KW Sanathan",
    Rate: "₹450 (15 boxes)",
    brokerName: "Harshad Mehta",
    broName: "Broker Name:",
    buyName: "Buyer Name:",
    buyRate: " 9000090000",
    type: "KW",
    count: "70",
    mill: "Sanathan",
  },
  {
    id: "7",
    orderNo: "Order no. 123456",
    time: "10:36 am 28/05/23",
    qualityName: "66 KW Sanathan",
    Rate: "₹450 (15 boxes)",
    brokerName: "Harshad Mehta",
    broName: "Broker Name:",
    buyName: "Buyer Name:",
    buyRate: " 9000090000",
    type: "KW",
    count: "66",
    mill: "Sanathan",
  },
  {
    id: "8",
    orderNo: "Order no. 123456",
    time: "10:36 am 28/05/23",
    qualityName: "61 KCW Sanathan",
    Rate: "₹450 (15 boxes)",
    brokerName: "Harshad Mehta",
    broName: "Broker Name:",
    buyName: "Buyer Name:",
    buyRate: " 9000090000",
    type: "KCW",
    count: "61",
    mill: "Sanathan",
  },
  {
    id: "9",
    orderNo: "Order no. 123456",
    time: "10:36 am 28/05/23",
    qualityName: "40 KCW Sanathan",
    Rate: "₹450 (15 boxes)",
    brokerName: "Harshad Mehta",
    broName: "Broker Name:",
    buyName: "Buyer Name:",
    buyRate: " 9000090000",
    type: "KCW",
    count: "40",
    mill: "Sanathan",
  },
  {
    id: "10",
    orderNo: "Order no. 123456",
    time: "10:36 am 28/05/23",
    qualityName: "49 CCW Sanathan",
    Rate: "₹450 (15 boxes)",
    brokerName: "Harshad Mehta",
    broName: "Broker Name:",
    buyName: "Buyer Name:",
    buyRate: " 9000090000",
    type: "CCW",
    count: "49",
    mill: "Sanathan",
  },
  {
    id: "11",
    orderNo: "Order no. 123456",
    time: "10:36 am 28/05/23",
    qualityName: "56 CCW Sanathan",
    Rate: "₹450 (15 boxes)",
    brokerName: "Harshad Mehta",
    broName: "Broker Name:",
    buyName: "Buyer Name:",
    buyRate: " 9000090000",
    type: "CCW",
    count: "56",
    mill: "Sanathan",
  },
  {
    id: "12",
    orderNo: "Order no. 123456",
    time: "10:36 am 28/05/23",
    qualityName: "55 OE Sanathan",
    Rate: "₹450 (15 boxes)",
    brokerName: "Harshad Mehta",
    broName: "Broker Name:",
    buyName: "Buyer Name:",
    buyRate: " 9000090000",
    type: "OE",
    count: "55",
    mill: "Sanathan",
  },
  {
    id: "13",
    orderNo: "Order no. 123456",
    time: "10:36 am 28/05/23",
    qualityName: "54 CCW Sanathan",
    Rate: "₹450 (15 boxes)",
    brokerName: "Harshad Mehta",
    broName: "Broker Name:",
    buyName: "Buyer Name:",
    buyRate: " 9000090000",
    type: "CCW",
    count: "54",
    mill: "Sanathan",
  },
  {
    id: "14",
    orderNo: "Order no. 123456",
    time: "10:36 am 28/05/23",
    qualityName: "53 OE Sanathan",
    Rate: "₹450 (15 boxes)",
    brokerName: "Harshad Mehta",
    broName: "Broker Name:",
    buyName: "Buyer Name:",
    buyRate: " 9000090000",
    type: "OE",
    count: "53",
    mill: "Sanathan",
  },
  {
    id: "15",
    orderNo: "Order no. 1234567",
    time: "10:36 am 28/05/23",
    qualityName: "52 CCW Sanathan",
    Rate: "₹450 (15 boxes)",
    brokerName: "Harshad Mehta",
    broName: "Broker Name:",
    buyName: "Buyer Name:",
    buyRate: " 9000090000",
    type: "CCW",
    count: "52",
    mill: "Sanathan",
  },
  {
    id: "16",
    orderNo: "Order no. 123456",
    time: "10:36 am 28/05/23",
    qualityName: "51 OE Sanathan",
    Rate: "₹450 (15 boxes)",
    brokerName: "Harshad Mehta",
    broName: "Broker Name:",
    buyName: "Buyer Name:",
    buyRate: " 9000090000",
    type: "OE",
    count: "51",
    mill: "Sanathan",
  },
  {
    id: "17",
    orderNo: "Order no. 123456",
    time: "10:36 am 28/05/23",
    qualityName: "616 OE Sanathan",
    Rate: "₹450 (15 boxes)",
    brokerName: "Harshad Mehta",
    broName: "Broker Name:",
    buyName: "Buyer Name:",
    buyRate: " 9000090000",
    type: "OE",
    count: "616",
    mill: "Sanathan",
  },
  {
    id: "18",
    orderNo: "Order no. 123456",
    time: "10:36 am 28/05/23",
    qualityName: "615 OE Sanathan",
    Rate: "₹450 (15 boxes)",
    brokerName: "Harshad Mehta",
    broName: "Broker Name:",
    buyName: "Buyer Name:",
    buyRate: " 9000090000",
    type: "OE",
    count: "615",
    mill: "Sanathan",
  },
  {
    id: "19",
    orderNo: "Order no. 12345678",
    time: "10:36 am 28/05/23",
    qualityName: "613 OE Sanathan",
    Rate: "₹450 (15 boxes)",
    brokerName: "Harshad Mehta",
    broName: "Broker Name:",
    buyName: "Buyer Name:",
    buyRate: " 9000090000",
    type: "OE",
    count: "613",
    mill: "Sanathan",
  },
]

export const ProfileData = [
  {
    id: "1",
    email: "emailaddress@.com",
  },
  {
    id: "2",
    phoneNo: "00000 00000",
  },
  {
    id: "3",
    whatsAppo: "00000 00000",
  },
  {
    id: "4",
    approvalStatus: "Pending",
  },
  {
    id: "5",
    tag: "Buyer",
  },
  {
    id: "6",
    name: "Name Surname",
  },
  {
    id: "7",
    username: "Username",
  },
]

export const brokerNameData = [
  {
    value: "1",
    broker: "Broker name 1",
  },
  {
    value: "2",
    broker: "Broker name 2",
  },
  {
    value: "3",
    broker: "Broker name 3",
  },
  {
    value: "4",
    broker: "Broker name 4",
  },
  {
    value: "5",
    broker: "Broker name 5",
  },
  {
    value: "6",
    broker: "Broker name 6",
  },
  {
    value: "7",
    broker: "Broker name 7",
  },
]

export const FirmDetails = [
  {
    id: "1",
    firmName: "Patil Enterprises",
  },
  {
    id: "2",
    ownerName: "Patil RV Mill",
  },
  {
    id: "3",
    address: "Address line 1",
  },
  {
    id: "4",
    gstno: "12345678901",
  },
  {
    id: "5",
    panno: "1234567891",
  },
  {
    id: "6",
    bankName: "Bank of India",
  },
  {
    id: "7",
    bankAccountNo: "100000100000",
  },
  {
    id: "8",
    ifscCode: "123456789123456",
  },
]
