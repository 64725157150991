// TODO: write documentation for colors and palette in own markdown file and add links from here

const palette = {
  neutral100: "#FFFFFF",
  neutral900: "#000000",
  neutral700: "#303030",

  gray100: "#EBECED",
  gray200: "#7E7E82",
  gray300: "#707070",
  gray400: "#D2D2D2",
  gray500: "#E1E1E1",
  gray600: "#CECECE",

  highlight100: "#5282EB",

  success100: "#40B716",

  failure100: "#DF1F29",
  failure200: "#CE5A52",

  accent100: "#FFEED4",

  semitransparent: "#00000080",
  blue100:"#F9FBFF",
} as const

export const colors = {
  /**
   * The palette is available to use, but prefer using the name.
   * This is only included for rare, one-off cases. Try to use
   * semantic names as much as possible.
   */
  palette,
  /**
   * A helper for making something see-thru.
   */
  transparent: "rgba(0, 0, 0, 0)",
  /**
   * The default text color in many components.
   */
  text: palette.neutral900,
  /**
   * Secondary text information.
   */
  textDim: palette.neutral100,
  /**
   * The default color of the screen background.
   */
  background: palette.neutral100,
  /**
   * The default border color.
   */
  border: palette.neutral100,
  /**
   * The main tinting color.
   */
  tint: palette.highlight100,
  /**
   * A subtle color used for lines.
   */
  separator: palette.neutral100,
  /**
   * Error messages.
   */
  error: palette.failure100,
  /**
   * Error Background.
   *
   */
  errorBackground: palette.success100,
}
