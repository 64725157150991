import * as React from "react"
import { useState } from "react"
import {
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography, spacing, fontSize } from "app/theme"
import { Text } from "app/components/Text"
import { TextInput, IconButton } from "@react-native-material/core"
import { Icon } from "../Icon"
import I18n from "i18n-js"
import { api } from "app/services/api"
import { Dropdown } from "react-native-element-dropdown"
import { roleData } from "../../services/constants/constant"
export interface RegistrationProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
}

/**
 * Describe your component here
 */
export const Registration = observer(function Registration(props: RegistrationProps) {
  const { style, navigation } = props
  const $styles = [$container, style]
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [loading, setLoading] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState()
  const [role, setRole] = useState()
  const [whatsappNumber, setWhatsappNumber] = useState()
  const [showPassword, setShowPassword] = useState(false)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  function goPrevious() {
    navigation.navigate("Welcome")
  }
  const isFieldEmpty = (value) => {
    return (
      value === undefined || value === null || (typeof value === "string" && value.trim() === "")
    )
  }

  async function Register() {
    try {
      if (
        isFieldEmpty(name) ||
        isFieldEmpty(email) ||
        isFieldEmpty(password) ||
        isFieldEmpty(phoneNumber) ||
        isFieldEmpty(whatsappNumber) ||
        isFieldEmpty(role)
      ) {
        alert("All required fields")
      } else if (phoneNumber.length !== 10 || whatsappNumber.length !== 10) {
        alert("Number should be 10 digits")
      } else {
        setLoading(true)
        const response = await api.getRegisterData(
          name,
          email,
          password,
          phoneNumber,
          whatsappNumber,
          role,
        )
        if (response.kind === "ok") {
          const listData: any = response.data
          if (listData.code === 201) {
            alert("Registration SuccessFully")
            setLoading(false)
            navigation.navigate("Welcome")
          } else {
            alert("Registration not SuccessFully")
          }
        } else if (response.kind === "bad-data") {
          alert("Something went wrong, Please try again")
          navigation.navigate("Welcome")
        } else {
          alert("Invalid email or password")
          setLoading(false)
        }
      }
    } catch (error) {}
  }
  return (
    <View style={$styles}>
      <View style={$backButton}>
        <TouchableOpacity>
          <Icon
            onPress={() => goPrevious()}
            icon="back"
            color={colors.palette.neutral900}
            size={fontSize.p1}
          />
        </TouchableOpacity>
      </View>

      <Text testID="login-heading" tx="registerScreen.register" preset="heading" style={$signIn} />
      <TextInput
        style={$input}
        label={I18n.translate("registerScreen.nameLabel")}
        color={colors.palette.gray200}
        variant="outlined"
        value={name}
        onChangeText={(value) => setName(value)}
        trailing={(props) => (
          <IconButton icon={(props) => <Icon icon="account" {...props} />} {...props} />
        )}
      />
      <TextInput
        style={$input}
        label={I18n.translate("registerScreen.emailLabel")}
        color={colors.palette.gray200}
        variant="outlined"
        value={email}
        onChangeText={(value) => setEmail(value)}
        trailing={(props) => (
          <IconButton icon={(props) => <Icon icon="account" {...props} />} {...props} />
        )}
      />
      <TextInput
        style={$input}
        label={I18n.translate("registerScreen.phoneNumber")}
        color={colors.palette.gray200}
        variant="outlined"
        maxLength={10}
        minLenght={10}
        value={phoneNumber}
        onChangeText={(value) => setPhoneNumber(value)}
        trailing={(props) => (
          <IconButton icon={(props) => <Icon icon="contact" {...props} />} {...props} />
        )}
      />
      <TextInput
        style={$input}
        label={I18n.translate("registerScreen.whatsappNumber")}
        color={colors.palette.gray200}
        variant="outlined"
        maxLength={10}
        minLenght={10}
        value={whatsappNumber}
        onChangeText={(value) => setWhatsappNumber(value)}
        trailing={(props) => (
          <IconButton icon={(props) => <Icon icon="contact" {...props} />} {...props} />
        )}
      />

      <TextInput
        style={$input}
        label={I18n.t("registerScreen.passwordLabel")}
        color={colors.palette.gray200}
        variant="outlined"
        value={password}
        onChangeText={(value) => setPassword(value)}
        secureTextEntry={!showPassword}
        trailing={(props) => (
          <IconButton
            icon={(props) => <Icon icon={showPassword ? "view" : "hide"} {...props} />}
            {...props}
            onPress={togglePasswordVisibility}
          />
        )}
      />
      <Dropdown
        style={$dropdown}
        data={roleData}
        labelField="name"
        valueField="id"
        value={role}
        placeholder={"Select Role"}
        onChange={(item) => {
          setRole(item.id)
        }}
      />
      {loading ? (
        <ActivityIndicator size="large" />
      ) : (
        <TouchableOpacity style={$buttonContainer} onPress={Register}>
          <Text style={$buttontext} tx="registerScreen.btnName"></Text>
        </TouchableOpacity>
      )}
    </View>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
  paddingHorizontal: spacing.md,
  paddingTop: spacing.md,
}
const $signIn: TextStyle = {
  marginBottom: spacing.lg,
  fontSize: fontSize.h2,
  fontFamily: typography.fonts.inter.bold,
}

const $backButton: ViewStyle = {
  marginBottom: spacing.xxl,
  marginLeft: spacing.mxxs,
  paddingRight: spacing.md,
  paddingBottom: spacing.md,
}

const $input: ViewStyle | ImageStyle | TextStyle = {
  marginTop: spacing.mdd,
}
const $buttonContainer: ViewStyle = {
  backgroundColor: colors.palette.highlight100,
  borderRadius: spacing.xxs,
  marginTop: spacing.mmd,
  marginBottom: spacing.mmd,
}

const $buttontext: TextStyle = {
  color: colors.palette.neutral100,
  fontSize: fontSize.p3,
  padding: spacing.md,
  textAlign: "center",
}
const $dropdown: ViewStyle = {
  marginTop: spacing.mdd,
  height: 56,
  width: "100%",
  padding: spacing.ssm,
  backgroundColor: "white",
  borderWidth: 1,
  borderColor: colors.palette.gray200,
  borderRadius: spacing.xxs,
}
