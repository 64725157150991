import * as React from "react"
import { StyleProp, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs"
import { OpenOrder } from "./OpenOrder"
import { DeleteOrder } from "./DeleteOrder"
import { Executedorder } from "./Executedorder"
import { colors, fontSize, spacing, typography } from "app/theme"

export interface OpenOrderListProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  data: any[]
  openOrderData: any[]
  excutedOrderData: any[]
  deletedOrderData: any[]
  authToken: string
}

interface ScreenOptions {
  tabBarStyle: StyleProp<ViewStyle>
  tabBarActiveTintColor: string
  tabBarInactiveTintColor: string
  tabBarLabelStyle: StyleProp<ViewStyle>
  tabBarIndicatorStyle: StyleProp<ViewStyle>
}

/**
 * Describe your component here
 */
export const OpenOrderList: React.FC<OpenOrderListProps> = observer(function OpenOrderList(
  props: OpenOrderListProps,
) {
  const {
    style,
    data,
    openOrderData,
    excutedOrderData,
    deletedOrderData,
    authToken,
    navigation,
    getOpenOrder,
    getExeuctedOrder,
    getDeleteOrder,
  } = props
  const $styles: StyleProp<ViewStyle>[] = [$containerStyle, style]
  const Tab = createMaterialTopTabNavigator()

  const $screenOptions: ScreenOptions = {
    tabBarStyle: {
      elevation: 0,
      marginLeft: spacing.md,
      marginRight: spacing.md,
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0,
      backgroundColor: colors.palette.gray100,
      position: "relative",
      bottom: spacing.md + spacing.xxxs,
    },
    tabBarActiveTintColor: colors.palette.highlight100,
    tabBarInactiveTintColor: colors.palette.gray300,
    tabBarLabelStyle: {
      textTransform: "capitalize",
      fontSize: fontSize.p3,
      fontFamily: typography.fonts.inter.medium,
    },
  }

  return (
    <View style={$styles}>
      <View style={$containerStyle}>
        <Tab.Navigator screenOptions={$screenOptions}>
          <Tab.Screen name="Open">
            {() => (
              <OpenOrder
                openOrderData={data}
                navigation={navigation}
                authToken={authToken}
                openOrder={openOrderData}
                style={$tabContainerOpen}
                getOpenOrder={getOpenOrder}
                deletedOrderData={deletedOrderData}
                excutedOrderDatas={excutedOrderData}
              />
            )}
          </Tab.Screen>
          <Tab.Screen name="Executed">
            {() => (
              <Executedorder
                openOrderData={data}
                excutedOrderDatas={excutedOrderData}
                style={$tabContainerExecuted}
                getExeuctedOrder={getExeuctedOrder}
              />
            )}
          </Tab.Screen>
          <Tab.Screen name="Deleted">
            {() => (
              <DeleteOrder
                openOrderData={data}
                openOrder={openOrderData}
                deletedOrderData={deletedOrderData}
                style={$tabContainer}
                getDeleteOrder={getDeleteOrder}
              />
            )}
          </Tab.Screen>
        </Tab.Navigator>
      </View>
    </View>
  )
})

const $containerStyle: ViewStyle = {
  flex: 1,
}
const $tabContainer: ViewStyle = {
  flex: 1,
  paddingBottom: spacing.xxl - spacing.xxs,
}
const $tabContainerOpen: ViewStyle = {
  flex: 1,
}
const $tabContainerExecuted: ViewStyle = {
  flex: 1,
}
