import * as React from "react"
import {
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  Platform,
  TouchableOpacity,
  Alert,
} from "react-native"
import { observer } from "mobx-react-lite"
import { colors, fontSize, spacing, typography } from "app/theme"
import { Text } from "app/components/Text"
import { Icon } from "../Icon"
import { useStores } from "app/models"
import { ActionAccountType, NavigationType } from "../../type/type"
import { api } from "app/services/api"
export interface AccountProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  accountData: any[]
  supportData: any[]
  othersData: any[]
  navigation: any
}

/**
 * Describe your component here
 */
export const Account = observer(function Account(props: AccountProps) {
  const { style, accountData, supportData, othersData } = props
  const { navigation } = props
  const $styles = [$container, style]
  const {
    authenticationStore: { logout, name, authEmail, phoneNumber, authRefreshToken,role },
  } = useStores()
  const initials = name
    ? name
        .split(" ")
        .map((part) => part.charAt(0).toUpperCase())
        .join("")
    : null
  const username = name.slice(0, 3) + phoneNumber.slice(-4)
  const testAlert = () =>
    Alert.alert(
      "Confirm Logout",
      "Are you sure, you want to Logout?",
      [
        {
          text: "Cancel",
          style: "cancel",
        },

        { text: "Logout", onPress: () => handleLogoutApi() },
      ],
      { cancelable: false },
    )
  const confirmAlertLogOut = () => {
    if (confirm("Are you sure, you want to Logout?")) {
      handleLogoutApi()
    }
  }
  const handleLogout = () => {
    if (Platform.OS === "android" || Platform.OS === "ios") {
      testAlert()
    } else if (Platform.OS === "web") {
      confirmAlertLogOut()
    }
  }
  const handleLogoutApi = async () => {
    try {
      const response = await api.postLogout(authRefreshToken)
      if (response.kind === "ok") {
        if (response.data.code === 200) {
          logout()
          alert(response.data.message)
        }
      }
      if (response.kind === "bad-data") {
        alert("Something went wrong, Please try again")
        logout()
      }
    } catch (error) {}
  }

  const filteredAccountData = role === "buyer" 
  ? accountData.filter(item => item.id !== ActionAccountType.Three) // Remove "Block List" data for buyers
  : accountData;
  const handlePress = (item) => {
  
    if (role === "buyer") {
      // For buyers
      if (item.id === ActionAccountType.One) {
        navigation.navigate(NavigationType.ProfileScreen);
      } else if (item.id === ActionAccountType.Two) {
        navigation.navigate(NavigationType.FirmDetailsScreen);
      } else if (item.id === ActionAccountType.Four) {
        handleLogout();
      }
    } else {
      // For sellers
      if (item.id === ActionAccountType.One) {
        navigation.navigate(NavigationType.ProfileScreen);
      } else if (item.id === ActionAccountType.Two) {
        navigation.navigate(NavigationType.FirmDetailsScreen);
      } else if (item.id === ActionAccountType.Three) {
        navigation.navigate(NavigationType.BlockListScreen); // Only visible for sellers
      } else if (item.id === ActionAccountType.Four) {
        handleLogout();
      }
    }
    }
  const handleSupport = (item) => {
    if (item.id === ActionAccountType.One) {
      navigation.navigate(NavigationType.Contact)
    }
  }
  const handleOtherPress = (item) => {
    if (item.id === ActionAccountType.One) {
      navigation.navigate(NavigationType.InviteFriends)
    } else if (item.id === ActionAccountType.Two) {
      navigation.navigate(NavigationType.Licenses)
    }
  }

  return (
    <View style={$styles}>
      <View style={$topContainer}>
        <View style={$account}>
          <Text style={$accountText} tx="accountScreen.title" />
          <Text style={$surName}>{name}</Text>
        </View>
        <View style={$middleContainer}>
          <View style={$middleView}>
            <Text style={$userName}>{username}</Text>
            <Text style={$email}>{authEmail}</Text>
          </View>
          <View style={$circle}>
            <Text style={$circleText}>{initials}</Text>
          </View>
        </View>
      </View>
      <View style={$bottomContainers}>
        <View>
          <Text style={$accoutName} tx="accountScreen.title"></Text>
          <View style={$line} />
        </View>
        <View>
          {filteredAccountData?.map((item) => (
            <View key={item.id}>
              <TouchableOpacity onPress={() => handlePress(item)}>
                <View style={$row}>
                  <Text style={$accoutText}>{item.profile}</Text>
                  <Icon icon={item.icon} size={fontSize.p2} color={colors.palette.gray300} />
                </View>
                <View style={$line} />
              </TouchableOpacity>
            </View>
          ))}
        </View>
      </View>
      <View>
        <View>
          <Text style={$accoutName} tx="accountScreen.support"></Text>
          <View style={$line} />
        </View>
        <View>
          {supportData?.map((item) => (
            <TouchableOpacity key={item.id} onPress={() => handleSupport(item)}>
              <View style={$row}>
                <Text style={$accoutText}>{item.contact}</Text>
                <Icon icon={item.icon} size={fontSize.p2} color={colors.palette.gray300} />
              </View>
              <View style={$line} />
            </TouchableOpacity>
          ))}
        </View>
      </View>
      <View>
        <View>
          <Text style={$accoutName} tx="accountScreen.others"></Text>
          <View style={$line} />
        </View>
        <View>
          {othersData?.map((item) => (
            <TouchableOpacity key={item.id} onPress={() => handleOtherPress(item)}>
              <View style={$row}>
                <Text style={$accoutText}>{item.invite}</Text>
                <Icon icon={item.icon} size={fontSize.p2} color={colors.palette.gray300} />
              </View>
              <View style={$line} />
            </TouchableOpacity>
          ))}
        </View>
        <View>
          <Text style={$appversion} tx="accountScreen.version"></Text>
        </View>
      </View>
    </View>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
}
const $middleView: ViewStyle = {
  flex: 1,
  justifyContent: "center",
  paddingHorizontal: spacing.md,
}
const $userName: TextStyle = {
  fontSize: fontSize.h4,
  fontFamily: typography.fonts.inter.normal,
  color: colors.palette.neutral700,
}
const $circle: ViewStyle = {
  width: spacing.xl + spacing.xl,
  height: spacing.xl + spacing.xl,
  borderRadius: spacing.xl,
  backgroundColor: colors.palette.highlight100,
  opacity: 0.2,
  marginRight: spacing.md,
}
const $circleText: TextStyle = {
  color: colors.palette.gray100,
  fontSize: spacing.lg,
  zIndex: 1,
  position: "relative",
  bottom: spacing.mxs + spacing.mxs + spacing.mxxs,
  marginLeft: spacing.smm,
}
const $email: TextStyle = {
  fontSize: fontSize.p3,
  fontFamily: typography.fonts.inter.normal,
  color: colors.palette.gray200,
}
const $account: ViewStyle = {
  position: "absolute",
  top: spacing.xl + spacing.ssm,
}
const $accoutText: TextStyle = {
  fontSize: fontSize.p3,
  fontFamily: typography.fonts.inter.normal,
  color: colors.palette.neutral700,
  marginTop: spacing.md,
  marginBottom: spacing.md,
}

const $accountText: TextStyle = {
  fontSize: fontSize.h2,
  fontFamily: typography.fonts.inter.bold,
  color: colors.palette.neutral700,
  ...Platform.select({
    android: {
      paddingHorizontal: spacing.md,
    },
  }),
  ...Platform.select({
    ios: {
      paddingHorizontal: spacing.md,
    },
  }),
}
const $surName: TextStyle = {
  fontSize: fontSize.h4,
  fontFamily: typography.fonts.inter.normal,
  color: colors.palette.neutral700,
  position: "relative",
  top: spacing.llg,
  ...Platform.select({
    android: {
      paddingHorizontal: spacing.md,
    },
  }),
  ...Platform.select({
    ios: {
      paddingHorizontal: spacing.md,
    },
  }),
}
const $topContainer: ViewStyle = {
  height: 200,
  backgroundColor: colors.palette.gray100,
  paddingHorizontal: spacing.md,
}
const $middleContainer: ViewStyle = {
  height: 104,
  backgroundColor: colors.palette.neutral100,
  position: "relative",
  top: 145,
  elevation: spacing.ssm,
  shadowColor: colors.palette.neutral900,
  shadowOffset: { width: spacing.xxxxs, height: spacing.xxxxs },
  shadowOpacity: 0.1,
  shadowRadius: spacing.xxs,
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}
const $bottomContainers: ViewStyle = {
  marginTop: 65,
}
const $row: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingHorizontal: spacing.md,
}
const $accoutName: TextStyle = {
  fontSize: fontSize.p3,
  fontFamily: typography.fonts.inter.semiBold,
  color: colors.palette.neutral700,
  paddingHorizontal: spacing.md,
  marginTop: spacing.md,
  marginBottom: spacing.md,
}
const $line: ViewStyle = {
  height: 1,
  backgroundColor: colors.palette.gray100,
}
const $appversion: TextStyle = {
  fontSize: fontSize.p4,
  fontFamily: typography.fonts.inter.normal,
  color: colors.palette.gray300,
  marginTop: spacing.md,
  marginBottom: spacing.md,
  paddingHorizontal: spacing.md,
}
