import React, { useEffect, useState } from "react"
import {
  ImageStyle,
  StyleProp,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
  ActivityIndicator,
} from "react-native"
import { observer } from "mobx-react-lite"
import { colors, fontSize, spacing } from "app/theme"
import { Text } from "app/components/Text"
import { TextInput } from "@react-native-material/core"
import I18n from "i18n-js"
import { api } from "app/services/api"
import { useStores } from "app/models"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"
export interface AddFirmDetailProps {
  style?: StyleProp<ViewStyle>
}

export const AddFirmDetail = observer(function AddFirmDetail(props: AddFirmDetailProps) {
  const { style, navigation } = props
  const $styles = [$container, style]

  const [firmName, setFirmName] = useState("")
  const [ownerName, setOwnerName] = useState("")
  const [address, setAddress] = useState("")
  const [gstno, setGstno] = useState("")
  const [panno, setPanno] = useState("")
  const [bankName, setBankName] = useState("")
  const [bankAccountNo, setBankAccountNo] = useState("")
  const [ifscCode, setIfscCode] = useState("")
  const [submitClicked, setSubmitClicked] = useState(false)
  const [loading, setLoading] = useState(false)
  const {
    authenticationStore: { authToken, id },
  } = useStores()
  const apiToken = authToken
  const userid = id

  function validateInputs() {
    return (
      firmName.trim() !== "" &&
      ownerName.trim() !== "" &&
      address.trim() !== "" &&
      gstno.trim() !== "" &&
      panno.trim() !== "" &&
      bankName.trim() !== "" &&
      bankAccountNo.trim() !== "" &&
      ifscCode.trim() !== ""
    )
  }

  function goPrevious() {
    navigation.navigate("FirmDetailsScreen")
  }

  function submitPasswordDetails() {
    setSubmitClicked(true)
    setLoading(true)
    if (validateInputs()) {
      api
        .postFirmData(
          firmName,
          ownerName,
          address,
          gstno,
          panno,
          bankName,
          bankAccountNo,
          ifscCode,
          apiToken,
          userid,
        )
        .then((response) => {
          if (response.kind === "ok") {
            const listData: any = response.data
            if (listData.code === 200) {
              alert(listData.message)
              setLoading(false)
              navigation.navigate("Account")
            } else {
              alert("Firm not updated successfully")
              setLoading(false)
            }
          } else if (response.kind === "bad-data") {
            alert("Something went wrong, Please try again")
            setLoading(false)
            navigation.navigate("Account")
          }
        })
    }
  }
  async function fetchFirmData() {
    try {
      const response = await api.getFirmDetails(apiToken, userid)
      if (response.kind === "ok") {
        if (response.data.code === 200) {
          const Data: any = response.data.data
          setFirmName(Data.moreDetails.firm.firmName)
          setOwnerName(Data.moreDetails.firm.ownerName)
          setAddress(Data.moreDetails.firm.address)
          setGstno(Data.moreDetails.firm.gstNo)
          setPanno(Data.moreDetails.firm.panNo)
          setBankName(Data.moreDetails.firm.bankName)
          setBankAccountNo(Data.moreDetails.firm.bankAccountNo)
          setIfscCode(Data.moreDetails.firm.ifscCode)
        }
      } else if (response.kind === "bad-data") {
        alert("Something went wrong, Please try again")
        navigation.navigate("Account")
      }
    } catch (error) {}
  }
  useEffect(() => {
    fetchFirmData()
  }, [])

  return (
    <KeyboardAwareScrollView>
      <View style={$styles}>
        <Text style={$detailsText} tx="AddFirmDetails.detailsText"></Text>
        <TextInput
          style={$input}
          label={I18n.translate("AddFirmDetails.firmName")}
          color={colors.palette.gray200}
          variant="outlined"
          value={firmName}
          onChangeText={(value) => setFirmName(value)}
        />
        <TextInput
          style={$input}
          label={I18n.translate("AddFirmDetails.ownerName")}
          color={colors.palette.gray200}
          variant="outlined"
          value={ownerName}
          onChangeText={(value) => setOwnerName(value)}
        />
        <TextInput
          style={$input}
          label={I18n.translate("AddFirmDetails.address")}
          color={colors.palette.gray200}
          variant="outlined"
          value={address}
          onChangeText={(value) => setAddress(value)}
        />

        <TextInput
          style={$input}
          label={I18n.translate("AddFirmDetails.gstno")}
          color={colors.palette.gray200}
          variant="outlined"
          value={gstno}
          maxLength={15}
          onChangeText={(value) => setGstno(value)}
        />
        <TextInput
          style={$input}
          label={I18n.translate("AddFirmDetails.panno")}
          color={colors.palette.gray200}
          variant="outlined"
          value={panno}
          maxLength={10}
          onChangeText={(value) => setPanno(value)}
        />
        <Text style={$bankDetailsText} tx="AddFirmDetails.bankDetails"></Text>
        <TextInput
          style={$input}
          label={I18n.translate("AddFirmDetails.bankName")}
          color={colors.palette.gray200}
          variant="outlined"
          value={bankName}
          onChangeText={(value) => setBankName(value)}
        />
        <TextInput
          style={$input}
          label={I18n.translate("AddFirmDetails.bankAccountNo")}
          color={colors.palette.gray200}
          variant="outlined"
          value={bankAccountNo}
          onChangeText={(value) => setBankAccountNo(value)}
        />
        <TextInput
          style={$input}
          label={I18n.translate("AddFirmDetails.ifscCode")}
          color={colors.palette.gray200}
          variant="outlined"
          value={ifscCode}
          maxLength={11}
          onChangeText={(value) => setIfscCode(value)}
        />
        {submitClicked && !validateInputs() && (
          <Text style={$errorMessageText}>Please fill out all required fields.</Text>
        )}
        <View style={$buttoncontainer}>
          {loading ? (
            <ActivityIndicator size="large" />
          ) : (
            <View>
              <TouchableOpacity style={$submitbutton} onPress={submitPasswordDetails}>
                <Text style={$submitbuttonText} tx="AddFirmDetails.submit"></Text>
              </TouchableOpacity>
            </View>
          )}
          <View style={$canclebuttonContainer}>
            <TouchableOpacity style={$cancelbutton} onPress={goPrevious}>
              <Text style={$cancelbuttonText} tx="AddFirmDetails.cancel"></Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </KeyboardAwareScrollView>
  )
})

const $container: ViewStyle = {
  marginHorizontal: spacing.md,
}
const $errorMessageText: TextStyle = {
  color: colors.palette.failure100,
  fontSize: fontSize.p2,
  marginBottom: spacing.smm,
}
const $detailsText: TextStyle = {
  fontSize: fontSize.p1,
  marginTop: spacing.xll,
  marginBottom: spacing.md,
}
const $bankDetailsText: TextStyle = {
  fontSize: fontSize.p1,
  marginTop: spacing.llg,
  marginBottom: spacing.md,
}

const $input: ViewStyle | ImageStyle | TextStyle = {
  marginBottom: spacing.smm,
}

const $buttoncontainer: ViewStyle = {
  flexDirection: "row",
  marginBottom: spacing.xxxl,
}
const $submitbutton: ViewStyle = {
  width: spacing.xxxxl,
  backgroundColor: colors.palette.highlight100,
  borderRadius: spacing.xxs,
}
const $submitbuttonText: TextStyle = {
  color: colors.palette.neutral100,
  fontSize: fontSize.p3,
  padding: spacing.ssm,
  textAlign: "center",
}
const $canclebuttonContainer: ViewStyle = {
  marginLeft: spacing.md,
}
const $cancelbutton: ViewStyle = {
  width: spacing.xxxxl,
  borderWidth: 1,
  borderColor: colors.palette.gray200,
  backgroundColor: colors.palette.neutral100,
  borderRadius: spacing.xxs,
}
const $cancelbuttonText: TextStyle = {
  color: colors.palette.gray300,
  fontSize: fontSize.p3,
  padding: spacing.ssm,
  textAlign: "center",
}
