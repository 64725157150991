import * as React from "react"
import { ComponentType } from "react"
import {
  Image,
  ImageStyle,
  StyleProp,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from "react-native"

export type IconTypes = keyof typeof iconRegistry

interface IconProps extends TouchableOpacityProps {
  /**
   * The name of the icon
   */
  icon: IconTypes

  /**
   * An optional tint color for the icon
   */
  color?: string

  /**
   * An optional size for the icon. If not provided, the icon will be sized to the icon's resolution.
   */
  size?: number

  /**
   * Style overrides for the icon image
   */
  style?: StyleProp<ImageStyle>

  /**
   * Style overrides for the icon container
   */
  containerStyle?: StyleProp<ViewStyle>

  /**
   * An optional function to be called when the icon is pressed
   */
  onPress?: TouchableOpacityProps["onPress"]
}

/**
 * A component to render a registered icon.
 * It is wrapped in a <TouchableOpacity /> if `onPress` is provided, otherwise a <View />.
 *
 * - [Documentation and Examples](https://github.com/infinitered/ignite/blob/master/docs/Components-Icon.md)
 */
export function Icon(props: IconProps) {
  const {
    icon,
    color,
    size,
    style: $imageStyleOverride,
    containerStyle: $containerStyleOverride,
    ...WrapperProps
  } = props

  const isPressable = !!WrapperProps.onPress
  const Wrapper: ComponentType<TouchableOpacityProps> = WrapperProps?.onPress
    ? TouchableOpacity
    : View

  return (
    <Wrapper
      accessibilityRole={isPressable ? "imagebutton" : undefined}
      {...WrapperProps}
      style={$containerStyleOverride}
    >
      <Image
        style={[
          $imageStyle,
          color && { tintColor: color },
          size && { width: size, height: size },
          $imageStyleOverride,
        ]}
        source={iconRegistry[icon]}
      />
    </Wrapper>
  )
}

export const iconRegistry = {
  bell: require("../../assets/icons/yarnx/bell.png"),
  watchlist: require("../../assets/icons/yarnx/watchlist.png"),
  orders: require("../../assets/icons/yarnx/shoppingCart.png"),
  alert: require("../../assets/icons/yarnx/settings.png"),
  account: require("../../assets/icons/yarnx/profile.png"),
  back: require("../../assets/icons/yarnx/back.png"),
  login: require("../../assets/icons/yarnx/login.png"),
  profile: require("../../assets/icons/yarnx/profile.png"),
  yourOrder: require("../../assets/icons/yarnx/yourOrder.png"),
  search: require("../../assets/icons/yarnx/search.png"),
  filter: require("../../assets/icons/yarnx/filter.png"),
  broker: require("../../assets/icons/yarnx/broker.png"),
  setting: require("../../assets/icons/yarnx/settings.png"),
  logout: require("../../assets/icons/yarnx/logout.png"),
  contact: require("../../assets/icons/yarnx/contact.png"),
  licenses: require("../../assets/icons/yarnx/licenses.png"),
  inviteFriend: require("../../assets/icons/yarnx/inviteFriend.png"),
  sort: require("../../assets/icons/yarnx/sortBy.png"),
  alphabeticallysort: require("../../assets/icons/yarnx/sortAlphabetically.png"),
  arrowForward: require("../../assets/icons/yarnx/arrowForward.png"),
  hide: require("../../assets/icons/yarnx/visibilityOff.png"),
  view: require("../../assets/icons/yarnx/view.png"),
  copyContent: require("../../assets/icons/yarnx/copyContent.png"),
  Congrats: require("../../assets/icons/yarnx/congrats.png"),
  PartialCongrats: require("../../assets/icons/yarnx/congrats.png"),
  Executed: require("../../assets/icons/yarnx/thumb.png"),
  Cancelled: require("../../assets/icons/yarnx/alert.png"),
  whatsapp: require("../../assets/icons/yarnx/whatsapp.png"),
  history: require("../../assets/icons/yarnx/history.png"),
  block:require("../../assets/icons/yarnx/block.png"),
  refresh:require("../../assets/icons/yarnx/refresh.png"),
  close: require("../../assets/icons/yarnx/close.png")
}

const $imageStyle: ImageStyle = {
  resizeMode: "contain",
}
