import React, { useEffect, useState } from "react"
import { StyleProp, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, fontSize, spacing, typography } from "app/theme"
import { Text } from "app/components/Text"
import { Icon } from "../Icon"
import { api } from "app/services/api"
import { useStores } from "app/models"

export interface FirmDetailsProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  navigation: any
}

/**
 * Describe your component here
 */
export const FirmDetails = observer(function FirmDetails(props: FirmDetailsProps) {
  const { style, navigation } = props
  const $styles = [$container, style]
  const [firmDetails, setFirmDetails] = useState([])
  const {
    authenticationStore: { authToken, id , authRefreshToken, logout},
  } = useStores()
  const apiToken = authToken
  const userid = id
  const goToAccountScreen = () => {
    navigation.navigate("Account")
  }
  const ManageDetails = () => {
    navigation.navigate("AddFirmDetails")
  }

  async function fetchFirmData() {
    try {
      const response = await api.getFirmDetails(apiToken, userid)
      if (response.kind === "ok") {
        const Data: any = response.data
        if (Data.code === 200) {
          setFirmDetails(Data.data.moreDetails.firm)
        } else {
          alert("Firm Details not Fetched")
        }
      } else if (response.kind === "Unauthorized") {
        goToAccountScreen()
        await api.postLogout(authRefreshToken)
        logout()
      }
    } catch (error) {}
  }
  useEffect(() => {
    fetchFirmData()
  }, [])

  return (
    <View style={$styles}>
      <View style={$firmContainer}>
        <View style={$rowContainer}>
          <Icon icon="back" size={24} color={colors.text} onPress={()=>navigation.goBack()}/>
          <Text style={$firmText} tx="FirmDetails.firmDetails"></Text>
        </View>
      </View>
      <View style={$infocontainer}>
        <Text style={$detailsText} tx="FirmDetails.detailsText"></Text>
        <TouchableOpacity>
          <Icon icon="copyContent" size={14} color={colors.palette.highlight100}></Icon>
        </TouchableOpacity>
      </View>
      <View style={$infocontainer}>
        <Text style={$firmtag} tx="FirmDetails.firmName"></Text>
        <Text style={$firminfo}>{firmDetails?.firmName}</Text>
      </View>
      <View style={$infocontainer}>
        <Text style={$firmtag} tx="FirmDetails.ownerName"></Text>
        <Text style={$firminfo}>{firmDetails?.ownerName}</Text>
      </View>
      <View style={$infocontainer}>
        <Text style={$firmtag} tx="FirmDetails.address"></Text>
        <Text style={$firminfo}>{firmDetails?.address}</Text>
      </View>
      <View style={$infocontainer}>
        <Text style={$firmtag} tx="FirmDetails.gstno"></Text>
        <Text style={$firminfo}>{firmDetails?.gstNo}</Text>
      </View>
      <View style={$infocontainer}>
        <Text style={$firmtag} tx="FirmDetails.panno"></Text>
        <Text style={$firminfo}>{firmDetails?.panNo}</Text>
      </View>
      <View style={$border}></View>
      <View style={$infocontainer}>
        <Text style={$detailsText} tx="FirmDetails.bankDetails"></Text>
        <TouchableOpacity>
          <Icon icon="copyContent" size={14} color={colors.palette.highlight100}></Icon>
        </TouchableOpacity>
      </View>
      <View style={$infocontainer}>
        <Text style={$firmtag} tx="FirmDetails.bankName"></Text>
        <Text style={$firminfo}>{firmDetails?.bankName}</Text>
      </View>
      <View style={$infocontainer}>
        <Text style={$firmtag} tx="FirmDetails.bankAccountNo"></Text>
        <Text style={$firminfo}>{firmDetails?.bankAccountNo}</Text>
      </View>
      <View style={$infocontainer}>
        <Text style={$firmtag} tx="FirmDetails.ifscCode"></Text>
        <Text style={$firminfo}>{firmDetails?.ifscCode}</Text>
      </View>
      <View style={$infocontainer}>
        <TouchableOpacity onPress={() => ManageDetails()}>
          <Text style={$manageText} tx="FirmDetails.manageBankDetils"></Text>
        </TouchableOpacity>
      </View>
      <View style={$border}></View>
    </View>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
}
const $firmContainer: ViewStyle = {
  flexDirection: "row",
  marginTop: spacing.xll,
}
const $rowContainer: ViewStyle = {
  flexDirection: "row",
  marginHorizontal: spacing.md,
}
const $firmText: TextStyle = {
  fontSize: fontSize.h2,
  marginLeft: spacing.md,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.bold,
  marginBottom: spacing.lg,
}
const $border: ViewStyle = {
  borderBottomWidth: 1,
  paddingLeft: spacing.lg,
  paddingRight: spacing.lg,
  marginBottom: spacing.smm,
  borderColor: colors.palette.gray100,
}
const $infocontainer: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: spacing.smm,
  marginHorizontal: spacing.md,
}
const $detailsText: TextStyle = {
  fontSize: fontSize.p3,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.semiBold,
}
const $firmtag: TextStyle = {
  fontSize: fontSize.p3,
  color: colors.palette.gray300,
}
const $firminfo: TextStyle = {
  fontSize: fontSize.p3,
  color: colors.palette.neutral700,
}
const $manageText: TextStyle = {
  fontSize: fontSize.p3,
  color: colors.palette.highlight100,
}
