import { Instance, SnapshotOut, types } from "mobx-state-tree"

export const AuthenticationStoreModel = types
  .model("AuthenticationStore")
  .props({
    authToken: types.maybe(types.string),
    authEmail: "",
    authPassword: "",
    authRefreshToken: "",
    authName: "",
    role: "",
    name: "",
    phoneNumber: "",
    whatsappNumber: "",
    approvalStatus: "",
    id: "",
    brokers: "",
  })
  .views((store) => ({
    get isAuthenticated() {
      return !!store.authToken
    },
    get validationEmail() {
      if (store.authEmail.length === 0) return "Username can't be blank"
      else if (store.authEmail.length < 6) return "Username contain at least 6 characters"
      else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(store.authEmail))
        return "must be a valid username(abc@gmail.com)"
      return ""
    },
    get validationPassword() {
      if (store.authPassword.length === 0) return "Password can't be blank"
      else if (store.authPassword.length < 6) return "Password contain at least 6 characters"
      return ""
    },
  }))
  .actions((store) => ({
    setAuthToken(value?: string) {
      store.authToken = value
    },
    setAuthName(value?: string) {
      store.authName = value
    },
    setAuthRefreshToken(value?: string) {
      store.authRefreshToken = value
    },
    setRole(value?: string) {
      store.role = value
    },
    setName(value?: string) {
      store.name = value
    },
    setphoneNumber(value?: string) {
      store.phoneNumber = value
    },
    setWhatsAppNumber(value?: string) {
      store.whatsappNumber = value
    },
    setapprovalStatus(value?: string) {
      store.approvalStatus = value
    },
    setAuthEmail(value: string) {
      store.authEmail = value.replace(/ /g, "")
    },
    setAuthPassword(value: string) {
      store.authPassword = value.replace(/ /g, "")
    },
    setId(value?: string) {
      store.id = value
    },
    setBrokers(value?: string) {
      store.brokers = value
    },

    logout() {
      store.authToken = undefined
      store.authEmail = ""
      store.role = ""
      store.authRefreshToken = undefined
      store.approvalStatus = ""
      store.authName = ""
      store.authPassword = ""
      store.phoneNumber = ""
      store.whatsappNumber = ""
      store.name = ""
      store.id = ""
    },
  }))

export interface AuthenticationStore extends Instance<typeof AuthenticationStoreModel> {}
export interface AuthenticationStoreSnapshot extends SnapshotOut<typeof AuthenticationStoreModel> {}

// @demo remove-file
