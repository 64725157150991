import React from 'react';
import { TouchableOpacity, View, StyleSheet, Platform, ColorValue } from 'react-native';

interface SwitchProps {
  isOn: boolean;
  handleToggle: () => void;
  colorOne: string;
  colorTwo: string;
}

const Switch: React.FC<SwitchProps> = ({
  isOn,
  handleToggle,
  colorOne,
  colorTwo,
}) => {
  return (
    <TouchableOpacity
      onPress={handleToggle}
      activeOpacity={0.8}
      style={styles.container}
    >
      <View style={[styles.switchLabel, { backgroundColor: isOn ? colorOne : colorTwo }]}>
        <View style={[styles.switchButton, isOn ? styles.switchButtonOn : styles.switchButtonOff]} />
      </View>
    </TouchableOpacity>
  );
};

const white: ColorValue = Platform.OS === 'ios' ? 'white' : 'rgb(255, 255, 255)';
const black: ColorValue = Platform.OS === 'ios' ? 'black' : 'rgb(0, 0, 0)';

const styles = StyleSheet.create({
  container: {
    borderRadius: 16, 
    height: 28, 
    padding: 2,
    width: 48, 
  },
  switchButton: {
    backgroundColor: white,
    borderRadius: 12, 
    elevation: 2,
    height: 20, 
    shadowColor: black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2.5,
    width: 20, 
  }, 
  switchButtonOff: {
    alignSelf: 'flex-start',
  },
  switchButtonOn: {
    alignSelf: 'flex-end',
  },
  switchLabel: {
    borderRadius: 20,
    flex: 1,
    justifyContent: 'center',
    padding: 2,
  },
});

export default Switch;