import React, { useEffect, useState, memo, useRef } from "react"
import {
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  Dimensions,
  ScrollView,
  TouchableOpacity,
  Alert,
  StatusBar,
  Platform,
  ActivityIndicator,
  Animated,
} from "react-native"
import { Text } from "../Text"
import { observer } from "mobx-react-lite"
import { colors, fontSize, spacing, typography } from "app/theme"
import RBSheet from "react-native-raw-bottom-sheet"
import { Icon } from "../Icon"
import { ActionType, CallbackResponse } from "../../type/type"
import { useStores } from "app/models"
import { api } from "app/services/api"
import { setTime, setDate } from "../../services/constants/constant.js"
import SSE from "react-native-sse"
import { API_BASE_URL } from "../../../url.js"
import { Button } from "../Button"

export interface BottomSheetData {
  buy: string
  buyOrders: string
  buyQty: string
  sell: string
  sellOrders: string
  sellQty: string
  data: {
    buyers: string
    sellers: string
  }
}

export interface BottomSheetProps {
  style?: StyleProp<ViewStyle>;
  navigation: any;
  item: {
    yarnId: number;
    name: string;
    weightPerBag?: string | null;
    TEP?: number | null;
    subTitle?: string | null;
    OOQ?: number | null;
    OOP?: number | null;
    YEP?: number | null;
    OOI?: number | null;
    qualityName: string;
    price: number;
    quantity: number;
    refValue?: number | null;
    groupNo?: number | null;
  };
  showMoreData: number;
  data: BottomSheetData[];
  showBottomSheet: string;
  setShowBottomSheet: (value: string) => void; 
  callback: (response: CallbackResponse) => void;
}

const requiredFields = [
  'yarnId',
  'name',
  'weightPerBag',
  'TEP',
  'subTitle',
  'OOQ',
  'OOP',
  'YEP',
  'OOI',
  'qualityName',
  'price',
  'quantity',
  'refValue',
  'groupNo',
];

const transformItem = (item) => {
  requiredFields.forEach((key) => {
    if (item[key] === undefined) {
      item[key] = null;
    }
  });
  return item;
};

export const BottomSheet = memo(
  observer(function BottomSheet(props: BottomSheetProps) {
    const { style, item, showBottomSheet, setShowBottomSheet, callback, navigation } = props
    transformItem(item);
    const {
      authenticationStore: { role, authToken, approvalStatus, id, setapprovalStatus },
    } = useStores()
    const [buyertableData, setBuyerTableData] = useState([])
    const [sellertableData, setSellerTableData] = useState([])
    const [visibleCount, setVisibleCount] = useState(4)
    const [lastTrade, setLastTrade] = useState()
    const [lastTradedTime, setLastTradedTime] = useState()
    const [prevClosePrice, setPrevClosePrice] = useState()
    const [showMore, setShowMore] = useState(false)
    const [similarQualities, setSimilarQualities] = useState([])
    const [loadingSimilarQualities, setLoadingSimilarQualities] = useState(true)
    const [loadingBottomSheet, setLoadingBottomSheet] = useState(false); 
    const [loadingTransition, setLoadingTransition] = useState(false)  

    const toggleShowMore = () => {
      setShowMore(!showMore)
    }
    useEffect(() => {
      if (showBottomSheet === item.yarnId.toString()) {
        setLoadingBottomSheet(true);  
        bottomSheetRef.current?.open();
        fetchGroupData();
      } else {
        bottomSheetRef.current?.close();
      }
    }, [showBottomSheet]);

    const displayedQualities = showMore
      ? similarQualities.slice(0, 12)
      : similarQualities.slice(0, 2)

    const [loading, setLoading] = useState(true)
    const apiURL = `${API_BASE_URL}/orders/market-depth`
    const apiDelete = `${API_BASE_URL}/orders`
    const apiToken = authToken

    useEffect(() => {
      if (showBottomSheet) {
        setLoadingBottomSheet(true);
        bottomSheetRef.current?.open()
      }
    }, [showBottomSheet])

    const handleSSEMessage = (event) => {
      const eventData = JSON.parse(event.data)
      setBuyerTableData(eventData?.buyers)
      setSellerTableData(eventData?.sellers)
      setLastTrade(eventData?.lastTradedQuantity)
      setLastTradedTime(eventData?.lastTradedTime)
      setPrevClosePrice(eventData?.prevClosePrice)
      setLoading(false)
    }
    useEffect(() => {
      const apiUrl = `${apiURL}/${item.yarnId}`
      const sse = new SSE(apiUrl, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      })

      sse.addEventListener("message", handleSSEMessage)

      return () => {
        sse.close()
      }
    }, [])

    async function deleteMarketDepthData() {
      try {
        await api.deleteMarketDepthData(apiDelete, apiToken, item.OOI)
      } catch (error) {}
    }

    const $styles: StyleProp<ViewStyle>[] = [style]
    const bottomSheetRef = React.useRef<RBSheet>(null)
    const bottomSheetHeight = Dimensions.get("window").height / 1.2
    const closeBottomSheet = () => {
      bottomSheetRef.current?.close()
    }

    const onCloseBottomSheet = () => {
      const response: CallbackResponse = {
        actionType: ActionType.BottomSheetClose,
      }
      callback(response)
      setLoadingBottomSheet(false);
      setLoadingTransition(false)
    }
    const handleNavigation = () => {
      navigation.navigate("SwipeToSell", { item })
      onCloseBottomSheet()
    }

    const handleNavigate = () => {
      navigation.navigate("SwipeToBuy", { item })
      onCloseBottomSheet()
    }
    const handleModify = () => {
      const screenName = role === "buyer" ? "SwipeToBuy" : "SwipeToSell"
      navigation.navigate(screenName, { item })
      onCloseBottomSheet()
    }

    const testAlert = () =>
      Alert.alert(
        "Delete Order",
        "Do you want to delete this order?",
        [
          {
            text: "Cancel",
            style: "cancel",
          },
          {
            text: "OK",
            onPress: () => {
              deleteMarketDepthData()
              setTimeout(() => {
                closeBottomSheet()
              }, 1000)
            },
          },
        ],
        { cancelable: false },
      )
    const confirmAlert = () => {
      if (confirm("Do you want to delete this order?")) {
        deleteMarketDepthData()
      }
      setTimeout(() => {
        closeBottomSheet()
      }, 1000)
    }

    const handleDelete = () => {
      if (Platform.OS === "android" || Platform.OS === "ios") {
        testAlert()
      } else if (Platform.OS === "web") {
        confirmAlert()
      }
    }

    const handleShowMore = () => {
      setVisibleCount(Math.max(buyertableData?.length, sellertableData?.length))
    }
    const handleShowLess = () => {
      setVisibleCount(4)
    }

    function isAllDataShown() {
      return visibleCount === Math.max(buyertableData?.length, sellertableData?.length)
    }
    async function fetchUpdatedData() {
      try {
        const response = await api.getProfileData(apiToken, id)
        const listData: any = response.data
        if (listData.code === 200) {
          setapprovalStatus(listData.data.approvalStatus)
        } else {
          alert("Profile Not Fetched")
        }
      } catch (error) {}
    }

    async function fetchGroupData() {
      setLoadingSimilarQualities(true)
      try {
        const response = await api.getGroupData(apiToken, item.groupNo)
        const listData = response.data
        if (Array.isArray(listData) && listData.length > 0) {
          const filteredData = listData.filter((data) => data.yarn_id !== item.yarnId)
          setSimilarQualities(filteredData)
        }
      } catch (error) {
        console.error("Error fetching group data:", error)
      } finally {
        setLoadingSimilarQualities(false)
        setLoadingBottomSheet(false);
      }
    }

    const rotation = useRef(new Animated.Value(0)).current
    const rotateIcon = () => {
      Animated.timing(rotation, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: true,
      }).start(() => {
        rotation.setValue(0)
      })
    }
    const handleQualityPress = (quality) => {
      if (quality && quality.yarn_id !== undefined) {
        setLoadingTransition(true)
        setShowBottomSheet(quality.yarn_id.toString());
        props.callback({ actionType: ActionType.UpdateYarnId, yarnId: quality.yarn_id.toString() });
      } else {
        console.error("Quality or yarnId is undefined:", quality);
      }
    };    

    return (
      <View style={$styles}>
        <StatusBar backgroundColor={colors.palette.gray100} />
        <RBSheet
          ref={bottomSheetRef}
          // onOpen={fetchTableData}
          onClose={onCloseBottomSheet}
          closeOnDragDown={true}
          closeOnPressMask={true}
          openDuration={400}
          height={bottomSheetHeight}
          customStyles={{
            container: $bottomSheetContainer,
            wrapper: $bottomSheetWrapper,
            draggableIcon: $draggableIcon,
          }}
          onOpen={() => setLoadingBottomSheet(false)} 
        >
          <View style={$row}>
            <Text style={$mainTitle}>
              {item.name}
              {item.weightPerBag !== 0 &&
                item.weightPerBag !== undefined &&
                item.weightPerBag !== null && (
                  <Text style={$mainTitle}>&nbsp;({item.weightPerBag})</Text>
                )}
            </Text>
            {item.TEP !== null && item.TEP !== undefined ? (
              <Text style={$mainRate}>₹{item.TEP}</Text>
            ) : (
              item.refValue !== 0 &&
              item.refValue !== undefined &&
              item.refValue !== null && <Text style={$mainRate}>₹{item.refValue}</Text>
            )}
          </View>
          <ScrollView>
            <View style={$row}>
              <Text style={$subTitle}>
                {item.OOQ != null && (
                  <React.Fragment>
                    <Icon
                      icon={"yourOrder"}
                      color={colors.palette.highlight100}
                      size={fontSize.p4}
                    />{" "}
                    <Text style={$subTitleText}>
                      {item.OOQ}
                      <Text style={$OpenOrderQuantity} tx="watchListScreen.box" />
                      {item.OOP}
                    </Text>
                  </React.Fragment>
                )}
              </Text>
              {/* <Text
              style={
                item.TEP - item.YEP < 0
                  ? $subTextRateNegative
                  : $subTextRatePositive
              }
            >
              {item.TEP - item.YEP}
            </Text> */}
            </View>
            <View style={$line} />
            {loadingSimilarQualities || loadingTransition ? (
              <ActivityIndicator size="large" />
            ) : similarQualities.length > 0 ? (
              <View style={$similarQualitiesContainer}>
                <Text style={$similarQualitiesTitle}>Similar Qualities</Text>
                 <ScrollView nestedScrollEnabled={true}>
              {displayedQualities.map((quality, index) => (
                <TouchableOpacity key={index} onPress={() => handleQualityPress(quality)}>
                  <View style={$similarQualityItem(index === displayedQualities.length - 1)}>
                    <Text style={$similarQualityText}>{quality.quality_name}</Text>
                    {quality.TEP !== null && quality.TEP !== undefined ? (
                      <Text style={$similarQualityPrice}>₹{quality.TEP}</Text>
                    ) : (
                      quality.ref_value !== 0 &&
                      quality.ref_value !== undefined &&
                      quality.ref_value !== null && (
                        <Text style={$similarQualityPrice}>₹{quality.ref_value}</Text>
                      )
                    )}
                  </View>
                </TouchableOpacity>
              ))}
            </ScrollView>
                <TouchableOpacity onPress={toggleShowMore}>
                  <Text style={$viewMore}>{showMore ? "View less" : "View more"}</Text>
                </TouchableOpacity>
              </View>
            ) : null}

            <View style={$content}>
              <View style={$row}>
                {item?.OOP !== null || item.OOQ !== null ? (
                  <TouchableOpacity onPress={handleModify} style={$buyButton}>
                    <Text tx="bottomSheet.buttonText" style={$buyText} />
                  </TouchableOpacity>
                ) : // buy
                role === "buyer" && approvalStatus !== "pending" ? (
                  <TouchableOpacity activeOpacity={0.8} style={$buyButton} onPress={handleNavigate}>
                    <Text style={$buyText} tx="bottomSheet.buyButtonText" />
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity disabled={true} style={$buyBlurButton} onPress={handleNavigate}>
                    <Text style={$buyText} tx="bottomSheet.buyButtonText" />
                  </TouchableOpacity>
                )}
                {item?.OOP !== null || item.OOQ !== null ? (
                  <TouchableOpacity style={$deleteButtonContainer} onPress={handleDelete}>
                    <Text style={$deleteButton} tx="bottomSheet.deleteButtonName" />
                  </TouchableOpacity>
                ) : // sell
                role === "seller" && approvalStatus !== "pending" ? (
                  <TouchableOpacity
                    activeOpacity={0.8}
                    style={$sellButton}
                    onPress={handleNavigation}
                  >
                    <Text style={$buyText} tx="bottomSheet.sellButtonText" />
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    disabled={true}
                    style={$sellBlurButton}
                    onPress={handleNavigation}
                  >
                    <Text style={$buyText} tx="bottomSheet.sellButtonText" />
                  </TouchableOpacity>
                )}
              </View>

              <View style={$rowPending}>
                {approvalStatus === "pending" && (
                  <>
                    <Text style={$pendingStatus} tx="bottomSheet.pending" />
                    <Button>
                      <Icon
                        icon={"refresh"}
                        color={colors.palette.neutral900}
                        size={fontSize.p4}
                        style={{
                          transform: [
                            {
                              rotate: rotation.interpolate({
                                inputRange: [0, 1],
                                outputRange: ["0deg", "360deg"],
                              }),
                            },
                          ],
                        }}
                        onPress={() => {
                          rotateIcon()
                          fetchUpdatedData()
                        }}
                      />
                    </Button>
                  </>
                )}
              </View>
              <View style={$row}>
                <Text style={$marketDepth} tx="bottomSheet.marketDepthText"></Text>
              </View>
              {loading ? (
                <ActivityIndicator size="large" style={$activityIndicator} />
              ) : (
                <View>
                  <View style={$tableMainContainer}>
                    <View style={$tableContainer}>
                      <View style={$table}>
                        <View style={$tableRow}>
                          <Text style={$tableData} tx="bottomSheet.tableHeader.buy" />
                          <Text style={$tableData} tx="bottomSheet.tableHeader.Orders" />
                          <Text style={$tableData} tx="bottomSheet.tableHeader.Qty" />
                        </View>
                        <View style={$tableLine} />
                        {buyertableData?.slice(0, visibleCount)?.map((item, index) => (
                          <View key={index} style={$buyColor}>
                            <Text style={$buyData}>{item.price}</Text>
                            <Text style={$buyData}>{item.numOfOrders}</Text>
                            <Text style={$buyData}>{item.quantity}</Text>
                          </View>
                        ))}
                      </View>
                      <View style={$table}>
                        <View style={$tableRow}>
                          <Text style={$tableData} tx="bottomSheet.tableHeader.sell" />
                          <Text style={$tableData} tx="bottomSheet.tableHeader.Orders" />
                          <Text style={$tableData} tx="bottomSheet.tableHeader.Qty" />
                        </View>
                        <View style={$tableLine} />
                        {sellertableData?.slice(0, visibleCount)?.map((item, index) => (
                          <View key={index} style={$sellColor}>
                            <Text style={$sellData}>{item.price}</Text>
                            <Text style={$sellData}>{item.numOfOrders}</Text>
                            <Text style={$sellData}>{item.quantity}</Text>
                          </View>
                        ))}
                      </View>
                    </View>

                    {isAllDataShown() ? (
                      buyertableData?.length > 4 || sellertableData?.length > 4 ? (
                        <TouchableOpacity onPress={handleShowLess}>
                          <Text style={$expandTable} tx="bottomSheet.expandLessTable" />
                        </TouchableOpacity>
                      ) : null
                    ) : buyertableData?.length > 4 || sellertableData?.length > 4 ? (
                      <TouchableOpacity onPress={handleShowMore}>
                        <Text style={$expandTable} tx="bottomSheet.expandTable" />
                      </TouchableOpacity>
                    ) : null}
                  </View>

                  <View style={$bottomContainer}>
                    <View style={$line} />

                    <View style={$row}>
                      <Text style={$lastTrad} tx="bottomSheet.lastTradedQuantity" />
                      <Text style={$lastTreadRate}>{lastTrade}</Text>
                    </View>
                    <View style={$row}>
                      <Text style={$lastTrad} tx="bottomSheet.lastTrad" />
                      {lastTradedTime && (
                        <Text style={$lastTreadRate}>
                          {setTime(lastTradedTime)} {setDate(lastTradedTime)}
                        </Text>
                      )}
                    </View>
                    <View style={$row}>
                      <Text style={$lastTrad} tx="bottomSheet.prevClose" />
                      <Text style={$lastTreadRate}>{prevClosePrice}</Text>
                    </View>

                    <View style={$line} />
                  </View>
                </View>
              )}
            </View>
          </ScrollView>
        </RBSheet>
      </View>
    )
  }),
)

const $similarQualitiesContainer = {
  borderWidth: 1,
  borderColor: colors.palette.gray500,
  backgroundColor: colors.palette.blue100,
  borderRadius: spacing.xss,
  textAlign: "left",
  marginTop: -spacing.ssm, 
}

const $similarQualitiesTitle = {
  fontFamily: typography.primary.semiBold,
  fontSize: fontSize.p2,
  textAlign: "left",
  fontWeight: "bold",
  padding: spacing.md,
  borderBottomWidth: 1,
  borderBottomColor: colors.palette.gray600,
}
const $similarQualityItem = (isLast) => ({
  fontFamily: typography.primary.normal,
  flexDirection: "row",
  justifyContent: "space-between",
  borderBottomWidth: isLast ? 0 : 1,
  borderBottomColor: colors.palette.gray600,
  padding: spacing.md,
  opacity: 1,
})

const $similarQualityText = {
  fontFamily: typography.primary.normal,
  fontSize: fontSize.p3,
  color: colors.palette.neutral700,
}

const $similarQualityPrice = {
  fontFamily: typography.primary.normal,
  fontSize: fontSize.p3,
  color: colors.palette.neutral700,
  textAlign: "right",
}

const $viewMore = {
  fontFamily: typography.primary.normal,
  color: colors.palette.highlight100,
  textAlign: "center",
  fontSize: fontSize.p3,
  textDecorationLine: "underline",
  paddingBottom: spacing.ssm,
}

const $rowPending: ViewStyle = {
  position: "relative",
  top: spacing.md,
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  paddingHorizontal: spacing.md,
}
const $activityIndicator: ViewStyle = {
  marginTop: "30%",
}
const $OpenOrderQuantity: TextStyle = {
  fontFamily: typography.primary.normal,
  fontSize: fontSize.p4,
  color: colors.palette.highlight100,
  marginLeft: spacing.xxxs,
  marginRight: spacing.xxxs,
}
const $pendingStatus: TextStyle = {
  fontFamily: typography.fonts.inter.normal,
  fontSize: fontSize.p3,
  color: colors.palette.failure100,
}
const $buyData: TextStyle = {
  color: colors.palette.highlight100,
  fontSize: fontSize.p4,
  marginBottom: spacing.smm,
  fontFamily: typography.fonts.inter.medium,
}
const $bottomContainer: ViewStyle = {
  position: "relative",
  bottom: spacing.lg,
}
const $buyColor: TextStyle = {
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  marginLeft: spacing.ssm,
  marginRight: spacing.ssm,
}
const $sellData: TextStyle = {
  color: colors.palette.failure100,
  fontSize: fontSize.p4,
  marginBottom: spacing.smm,
  fontFamily: typography.fonts.inter.medium,
}
const $sellColor: TextStyle = {
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  marginLeft: spacing.ssm,
  marginRight: spacing.ssm,
}
const $buyText: TextStyle = {
  color: colors.palette.neutral100,
  fontSize: fontSize.p3,
  fontFamily: typography.fonts.inter.semiBold,
}
const $tableData: TextStyle = {
  marginBottom: spacing.xs,
  fontSize: fontSize.p4,
  color: colors.palette.gray300,
  fontFamily: typography.fonts.inter.medium,
}

const $content: ViewStyle = {
  borderRadius: fontSize.p5,
}
const $bottomSheetContainer: ViewStyle = {
  backgroundColor: colors.palette.neutral100,
  borderTopLeftRadius: fontSize.p5,
  borderTopRightRadius: fontSize.p5,
}
const $bottomSheetWrapper: ViewStyle = {}
const $draggableIcon: ViewStyle = {
  backgroundColor: colors.palette.gray200,
}
const $mainTitle: TextStyle = {
  fontFamily: typography.primary.medium,
  fontSize: fontSize.h3,
}
const $mainRate: TextStyle = {
  fontFamily: typography.primary.medium,
  fontSize: fontSize.p3,
}
const $line: ViewStyle = {
  width: "100%",
  backgroundColor: colors.palette.gray200,
  height: 0.5,
  marginTop: spacing.ssm,
  marginBottom:spacing.ssm,
}
const $tableLine: ViewStyle = {
  width: "80%",
  marginBottom: spacing.xs,
  marginHorizontal: spacing.mdd,
  backgroundColor: colors.palette.gray200,
  height: 0.5,
}
const $row: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: spacing.xxs,
  marginHorizontal: spacing.mdd,
}
const $tableRow: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "center",
}
const $table: ViewStyle = {
  width: "48%",
  marginTop: spacing.ssm,
  height: "95%",
}
const $tableMainContainer: ViewStyle = {
  flex: 1,
}
const $tableContainer: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "center",
  marginBottom: spacing.mmd + spacing.ssm + spacing.ssm,
}
const $buyButton: TextStyle = {
  backgroundColor: colors.palette.highlight100,
  color: colors.palette.neutral100,
  height: spacing.xxl + spacing.xxxs,
  width: "45%",
  margin: spacing.xxs + spacing.xxxs,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: spacing.xxs,
}
const $buyBlurButton: TextStyle = {
  backgroundColor: colors.palette.highlight100,
  color: colors.palette.neutral100,
  height: spacing.xxl + spacing.xxxs,
  width: "45%",
  opacity: 0.25,
  margin: spacing.xxs + spacing.xxxs,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: spacing.xxs,
}
const $sellButton: TextStyle = {
  backgroundColor: colors.palette.failure200,
  color: colors.palette.neutral100,
  fontFamily: typography.fonts.inter.semiBold,
  height: spacing.xxl + spacing.xxxs,
  width: "45%",
  margin: spacing.xxs + spacing.xxxs,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: spacing.xxs,
}
const $sellBlurButton: TextStyle = {
  backgroundColor: colors.palette.failure200,
  color: colors.palette.neutral100,
  fontFamily: typography.fonts.inter.semiBold,
  height: spacing.xxl + spacing.xxxs,
  width: "45%",
  margin: spacing.xxs + spacing.xxxs,
  opacity: 0.25,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: spacing.xxs,
}
const $deleteButtonContainer: TextStyle = {
  backgroundColor: colors.palette.failure200,
  color: colors.palette.neutral100,
  fontFamily: typography.fonts.inter.semiBold,
  height: spacing.xxl + spacing.xxxs,
  width: "45%",
  margin: spacing.xxs + spacing.xxxs,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: spacing.xxs,
}
const $deleteButton: TextStyle = {
  color: colors.palette.neutral100,
  fontFamily: typography.fonts.inter.semiBold,
}
const $marketDepth: TextStyle = {
  fontFamily: typography.fonts.inter.bold,
  marginTop: spacing.xll,
  fontSize: fontSize.p3,
}
const $subTitle: TextStyle = {
  fontFamily: typography.primary.normal,
  fontSize: fontSize.p4,
  color: colors.palette.highlight100,
}
const $subTitleText: TextStyle = {
  fontFamily: typography.primary.normal,
  fontSize: fontSize.p4,
  color: colors.palette.highlight100,
  position: "relative",
  bottom: spacing.xxxs,
}
// const $subTextRateNegative: TextStyle = {
//   color: colors.palette.failure100,
//   fontFamily: typography.primary.medium,
//   fontSize: fontSize.p4,
// }
// const $subTextRatePositive: TextStyle = {
//   color: colors.palette.success100,
//   fontFamily: typography.primary.medium,
//   fontSize: fontSize.p4,
// }
const $expandTable: TextStyle = {
  color: colors.palette.highlight100,
  fontSize: fontSize.p3,
  fontFamily: typography.fonts.inter.medium,
  textAlign: "center",
  position: "relative",
  bottom: spacing.mmd + spacing.mmd,
}
const $lastTrad: TextStyle = {
  fontSize: fontSize.p2,
  color: colors.palette.gray200,
  marginBottom: spacing.mmd,
  fontFamily: typography.fonts.inter.medium,
}
const $lastTreadRate: TextStyle = {
  fontSize: fontSize.p2,
  color: colors.palette.neutral700,
  fontFamily: typography.fonts.inter.medium,
  marginBottom: spacing.mmd,
}