import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle } from "react-native"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { AppStackScreenProps } from "app/navigators"
import { ManageProfile, Screen } from "app/components"
// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "app/models"

interface ManageAccountScreenProps
  extends NativeStackScreenProps<AppStackScreenProps<"ManageAccount">> {}

export const ManageAccountScreen: FC<ManageAccountScreenProps> = observer(
  function ManageAccountScreen(_proops) {
    // Pull in one of our MST stores
    // const { someStore, anotherStore } = useStores()

    // Pull in navigation via hook
    // const navigation = useNavigation()
    const { navigation } = _proops
    return (
      <Screen style={$root} preset="scroll" safeAreaEdges={["top"]}>
        <ManageProfile navigation={navigation}></ManageProfile>
      </Screen>
    )
  },
)

const $root: ViewStyle = {
  flex: 1,
}
